import styled from 'styled-components/macro';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const MainInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px 12px;
  padding: 5px;
  width: 33%;

  @media (max-width: 576px) {
    width: 100%;
    margin: 2px 0px;
  }
`;

export const MainInfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 2px;
`;

export const MainInfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 2px;
  padding-left: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  width: 100%;
`;

export const PatientInfoBar = styled.div`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  border-left: 5px solid ${({ theme }) => theme.CardHeader};
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${({ theme }) => theme.CardHeader};
    border-left: 5px solid ${({ theme }) => theme.CardHeader};
  }
`;

export const PatientInfoContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const PatientInfoHeader = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 5px 0px 5px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  font-weight: bold;

  @media (max-width: 1000px) {
    display: ${(props) => props.label === 'PREFFEREDNAME' && 'none'};
  }

  @media (max-width: 800px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
  }

  @media (max-width: 600px) {
    display: ${(props) => props.label === 'BIRTHDATE' && 'none'};
  }

  @media (max-width: 460px) {
    display: ${(props) => (props.label === 'IMAGE' || props.label === 'SEX') && 'none'};
  }
`;

export const PatientInfoLabel = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 8px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }
  @media (max-width: 1000px) {
    display: ${(props) => props.label === 'PREFFEREDNAME' && 'none'};
  }

  @media (max-width: 800px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
  }

  @media (max-width: 600px) {
    display: ${(props) => props.label === 'BIRTHDATE' && 'none'};
  }

  @media (max-width: 460px) {
    display: ${(props) => props.label === 'SEX' && 'none'};
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 10px;

  @media (max-width: 576px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const PatientAvatar = styled.img`
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px 3px 0px 5px;
`;

export const PatientAvatarContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  width: 40px;

  @media (max-width: 460px) {
    display: none;
  }
`;

export const PatientIconContainer = styled.div`
  margin: 5px 0px 0px 5px;
`;

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 1rem;
  padding: 10px 10px 0px 10px;
  min-height: 35px;
`;

export const HideOnMobile = styled.div`
  display: block;
  padding-top: 5px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ShowOnMobile = styled.div`
  display: none;
  margin-top: 5px;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const LocalMainBar = styled.div`
  padding: 6px 5px 6px 15px;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 6px 15px 6px 15px;
  }
`;

export const LoadingModule = styled.div`
  position: fixed;
  top: 120px;
  display: flex;
  justify-content: center;
  background-color: red;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectDoctorContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
  border: none;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const SelectDoctorNav = styled.nav`
  margin-top: 150px;
  display: block;
  width: 300px;
  position: absolute;
  background-color: white;
  z-index: 10;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px;
  box-shadow: 2px 2px 10px #888888;
`;

export const SelectDoctorNavButtons = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 8px 15px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const SelectDoctorCancel = styled.div`
  display: flex;
  width: 35px;
`;

export const SelectDoctorCancelText = styled.div`
  padding: 5px 0px 3px 10px;
`;

export const PatientPaginatedBar = styled.div`
  margin-top: 30px;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
`;

export const PaginationTextContainer = styled.div`
  display: flex;
`;

export const PaginationText = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  padding-right: 10px;
  font-size: 0.8rem;
`;

export const PaginationClickableText = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? 'orange' : props.theme.CardFontLight)};
  padding-right: 10px;
  font-size: 0.8rem;

  &:hover {
    color: orange;
  }
`;

export const PaginationLimitContainer = styled.div`
  display: flex;
`;

export const PaginationSelectLimit = styled.select`
  width: 50px;
`;
