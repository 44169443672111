import React from 'react';

import { SelectCardContainer, SelectCardNav, SelectCardNavButtons, SelectCardCancel, SelectCardCancelText } from '../../../ViewChart.styled';
import { CancelIconWhite } from '../../../../../../../../Reusables/Icons';

function SelectBillingItem({ setOpenSelectBillingItem, setNewBillingItem, addBillingCardData }) {
  const billingItemTypes = [
    { name: 'Procedure', id: 'procedure' },
    { name: 'Medicine', id: 'medicine' },
    { name: 'Intraocular Lenses', id: 'ioLenses' },
    { name: 'Frames', id: 'frames' },
    { name: 'Consumables', id: 'consumables' },
    { name: 'Fees', id: 'fees' },
    { name: 'Others', id: 'others'}
  ];

  return (
    <SelectCardContainer>
      <SelectCardNav>
        <SelectCardNavButtons title="cancel" onClick={() => setOpenSelectBillingItem(false)}>
          <SelectCardCancel>
            <CancelIconWhite />
            <SelectCardCancelText>Cancel</SelectCardCancelText>
          </SelectCardCancel>
        </SelectCardNavButtons>
        {billingItemTypes.map((card, index) => {
          return (
            <div key={index}>
              <SelectCardNavButtons
                onClick={() => {
                  setOpenSelectBillingItem(false);
                  card.id === 'others' ? addBillingCardData('', 0, 'others', 'others') : setNewBillingItem(card.id);
                }}
              >
                {card.name}
              </SelectCardNavButtons>
            </div>
          );
        })}
      </SelectCardNav>
    </SelectCardContainer>
  );
}

export default SelectBillingItem;
