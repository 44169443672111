import styled from 'styled-components/macro';

export const UserContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const UserInfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 10px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};

  @media (max-width: 1000px) {
    display: ${(props) => (props.label === 'PTRNUMBER' || props.label === 'PRCLICENSE') && 'none'};
  }

  @media (max-width: 850px) {
    display: ${(props) => props.label === 'EMAILADDRESS' && 'none'};
  }

  @media (max-width: 700px) {
    display: ${(props) => props.label === 'CELLPHONE' && 'none'};
  }
`;

export const UserBar = styled.div`
  padding: 3px 5px 3px 15px;
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  height: 42px;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  display: flex;
  justify-content: space-between;
`;

export const TitlesContainer = styled.div`
  display: flex;
  padding-left: 9px;
`;

export const UserHeaderTitle = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  width: ${(props) => props.width};
  font-size: 0.8rem;
  padding-top: 7px;
  padding-left: 10px;

  @media (max-width: 1000px) {
    display: ${(props) => (props.label === 'PTRNUMBER' || props.label === 'PRCLICENSE') && 'none'};
  }

  @media (max-width: 850px) {
    display: ${(props) => props.label === 'EMAILADDRESS' && 'none'};
  }

  @media (max-width: 700px) {
    display: ${(props) => props.label === 'CELLPHONE' && 'none'};
  }

  @media (max-width: 400px) {
    width: 110px;
  }
`;

export const UserBarIcons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AdminContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1200px) {
    margin: 0px;
  }
`;

export const SideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.MainNav};
  width: ${(props) => props.width};

  @media (max-width: 1200px) {
    width: 50px !important;
  }
`;

export const SideBarHeader = styled.div`
  text-align: left;
  padding: 10px 0px 8px 13px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.CardFontLight};
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
`;

export const SideBarHamburgerIcon = styled.div`
  cursor: pointer;
`;

export const SideBarNav = styled.div`
  display: flex;
  cursor: pointer;
  text-align: left;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const SidebarIcon = styled.div`
  padding: 7px 5px 2px 6px;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 5px solid ${(props) => (props.active ? props.active : props.theme.MainNav)};
`;

export const SidebarIconText = styled.div`
  padding: 12px 5px 2px 5px;
  font-size: 1rem;
  color: ${({ theme }) => theme.CardFontLight};

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const MainPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.MainBackground};
  min-height: 80vh;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const UserInfoBar = styled.div`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  border-left: 5px solid ${(props) => props.accessColor};
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${(props) => props.accessColor};
    border-left: 5px solid ${(props) => props.accessColor};
  }
`;

export const IconActions = styled.div`
  cursor: pointer;
  display: flex;
  padding: 0px 3px;
  transition: all 0.2s ease-in-out;
  border-radius: 1px;
  &:hover {
    color: ${({ theme }) => theme.MainBackground};
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const ActionText = styled.div`
  padding: 10px 5px 0px 0px;
  display: inline;
  font-size: 0.7rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const UserBarIconActions = styled.div`
  cursor: pointer;
  display: flex;
  color: ${({ theme }) => theme.CardFontLight};
  padding: 0px 3px 0px 3px;
  transition: all 0.2s ease-in-out;
  border-radius: 1px;
  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const UserActionText = styled.div`
  padding: 10px 5px 0px 0px;
  display: inline;
  font-size: 0.8rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Icons = styled.div`
  font-size: 1.2rem;
  padding: 5px;
  padding-top: 8px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 3px;
  padding-left: 6px;
  font-size: 0.9rem;
  width: 100%;
`;

export const InfoInputSelect = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 2px;
  font-size: 0.9rem;
  width: 100%;
`;

export const infoInputOption = styled.option`
  font-size: 0.9rem;
  color: red;
`;

export const CardBody = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  width: 100%;
`;

export const CardBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px 5px 0px 0px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
`;

export const CardBarName = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
export const MainInfoContainer = styled.div`
  padding: 2px;
  width: 33%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 5px;
  margin: 1px;
  width: 33%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InfoContainerCheckBox = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 5px;
  margin: 1px;
  width: 33%;
  display: flex;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InfoLabelCheckBox = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 1rem;
  font-weight: bold;
  padding: 2px;
  margin-right: 8px;
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 2px;
`;

export const CardContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  height: auto;
  width: 100%;
  margin-bottom: 5px;
`;

export const LeftContainer = styled.div`
  height: auto;
  width: 35%;
  margin-top: 5px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ProfilePicture = styled.img`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-top: none;
  padding: 5px;
  width: 100%;
`;

export const RightContainer = styled.div`
  margin-left: 5px;
  height: auto;
  width: 65%;
  margin-top: 5px;

  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
