import React from 'react';

import { Row, InfoContainer, InfoLabel, InfoData, CardSubBar, CardSubBarName, InfoInput } from '../ViewProfile.styled';

function Address({ address, isEdit, newAddress, setNewAddress, SubBarLabel }) {
  const addressDataList = [
    { name: 'houseNumberAndStreet', Label: 'House number and street:', type: 'text', placeholder: '' },
    { name: 'subdivision', Label: 'Subdivision:', type: 'text', placeholder: '' },
    { name: 'barangay', Label: 'Barangay:', type: 'text', placeholder: '' },
    { name: 'cityOrMunicipality', Label: 'City or Municipality:', type: 'text', placeholder: '' },
    { name: 'province', Label: 'Province:', type: 'text', placeholder: '' },
    { name: 'zip', Label: 'Zip Code:', type: 'text', placeholder: '' },
  ];

  const handleChange = (name, value) => {
    setNewAddress({ ...newAddress, [name]: value });
  };

  return (
    <>
      <CardSubBar>
        <CardSubBarName>{SubBarLabel}</CardSubBarName>
      </CardSubBar>
      <Row>
        {addressDataList.map((item, index) => {
          return (
            <InfoContainer key={index}>
              <InfoLabel>{item.Label}</InfoLabel>
              {isEdit ? (
                <InfoInput type={item.type} placeholder={item.placeholder} value={newAddress && newAddress[item.name] ? newAddress[item.name] : ''} name={item.name} onChange={(e) => handleChange(e.target.name, e.target.value)} />
              ) : (
                <InfoData>{address && address[item.name] ? address[item.name] : ''}</InfoData>
              )}
            </InfoContainer>
          );
        })}
      </Row>
    </>
  );
}

export default Address;
