import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFeesList } from '../../../../../../../../../config/redux/inventory';

import { ButtonDanger, ButtonPrimary } from '../../../../../../../../Reusables/Buttons';
import { SearchResultContainer } from '../../../ViewChart.styled';
import { AddProcedureContainer, AddProcedureHeaderContainer, AddProcedureHeader, SearchBoxContainer, MedicineSearchResultHeader, MedicineHeader, MedicineSearchResult, MedicineSearchResultItem } from '../BillingCard.styled';

function AddFees({ setNewBillingItem, addBillingCardData }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const feesList = useSelector((state) => state.inventory && state.inventory.feesList);
  const [added, setAdded] = useState(0);
  const feesHeader = [
    { name: 'Name', id: 'name', width: '70%', align: 'left' },
    { name: 'Price', id: 'price', width: '30%', align: 'left' },
  ];

  useEffect(() => {
    dispatch(getFeesList({ method: 'get', url: `fees`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, []);

  function handleAddFee(fee) {
    addBillingCardData(`${fee.name}`, fee.price, 'fee', fee._id);
    setAdded(added + 1);
  }

  return (
    <AddProcedureContainer>
      <AddProcedureHeaderContainer>
        <AddProcedureHeader width={'100%'}>Add Fees</AddProcedureHeader>
      </AddProcedureHeaderContainer>
      <SearchBoxContainer alignRight={true}>{added !== 0 ? <ButtonPrimary onClick={() => setNewBillingItem('')}>Done</ButtonPrimary> : <ButtonDanger onClick={() => setNewBillingItem('')}>Cancel</ButtonDanger>}</SearchBoxContainer>
      <SearchResultContainer>
        <MedicineSearchResultHeader>
          {feesHeader.map((item, index) => {
            return (
              <MedicineHeader key={index} width={item.width}>
                {item.name}
              </MedicineHeader>
            );
          })}
        </MedicineSearchResultHeader>
        {feesList &&
          feesList.map((fee, index) => {
            return (
              <MedicineSearchResult key={index} onClick={() => handleAddFee(fee)}>
                {feesHeader.map((item, i) => {
                  return (
                    <MedicineSearchResultItem key={i} width={item.width} align={item.align}>
                      {fee[item.id]}
                    </MedicineSearchResultItem>
                  );
                })}
              </MedicineSearchResult>
            );
          })}
      </SearchResultContainer>
    </AddProcedureContainer>
  );
}

export default AddFees;
