import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchFrame } from '../../../../../../../../../config/redux/inventory';

import { ButtonDanger, ButtonPrimary } from '../../../../../../../../Reusables/Buttons';
import { SearchResultContainer } from '../../../ViewChart.styled';
import { AddProcedureContainer, AddProcedureHeaderContainer, AddProcedureHeader, SearchInputField, SearchBoxContainer, MedicineSearchResultHeader, MedicineHeader, MedicineSearchResult, MedicineSearchResultItem } from '../BillingCard.styled';

function AddFrames({ setNewBillingItem, addBillingCardData }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const framesList = useSelector((state) => state.inventory && state.inventory.framesList);
  const [itemNumber, setItemNumber] = useState('');
  const [added, setAdded] = useState(0);
  const framesHeader = [
    { name: 'Item Number', id: 'itemNumber', width: '40%', align: 'left' },
    { name: 'Brand', id: 'brand', width: '40%', align: 'left' },
    { name: 'Price', id: 'price', width: '10%', align: 'center' },
    { name: 'Status', id: 'status', width: '10%', align: 'center' },
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(searchFrame({ method: 'get', url: `frames/search?itemNumber=${itemNumber}`, token: currentUser.userToken }));
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [itemNumber]);

  function handleAddFrame(frame) {
    setItemNumber('');
    addBillingCardData(`${frame.itemNumber} - ${frame.brand} (frame)`, frame.price, 'frame', frame._id);
    setAdded(added + 1);
  }

  return (
    <AddProcedureContainer>
      <AddProcedureHeaderContainer>
        <AddProcedureHeader width={'100%'}>Add Frames</AddProcedureHeader>
      </AddProcedureHeaderContainer>
      <SearchBoxContainer>
        <SearchInputField value={itemNumber} onChange={(e) => setItemNumber(e.target.value)} placeholder={'Search by item number'} />
        {added !== 0 ? <ButtonPrimary onClick={() => setNewBillingItem('')}>Done</ButtonPrimary> : <ButtonDanger onClick={() => setNewBillingItem('')}>Cancel</ButtonDanger>}
      </SearchBoxContainer>
      <SearchResultContainer>
        <MedicineSearchResultHeader>
          {framesHeader.map((item, index) => {
            return (
              <MedicineHeader key={index} width={item.width}>
                {item.name}
              </MedicineHeader>
            );
          })}
        </MedicineSearchResultHeader>
        {framesList &&
          framesList.map((frame, index) => {
            return (
              <MedicineSearchResult key={index} onClick={() => (frame.status === 'sold' ? null : handleAddFrame(frame))} sold={frame.status === 'sold'}>
                {framesHeader.map((item, i) => {
                  return (
                    <MedicineSearchResultItem key={i} width={item.width} align={item.align} shortage={item.id === 'status' && frame.status === 'sold'}>
                      {frame[item.id]}
                    </MedicineSearchResultItem>
                  );
                })}
              </MedicineSearchResult>
            );
          })}
      </SearchResultContainer>
    </AddProcedureContainer>
  );
}

export default AddFrames;
