import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchMedicine } from '../../../../../../../../../config/redux/inventory';

import { ButtonDanger, ButtonPrimary } from '../../../../../../../../Reusables/Buttons';
import { SearchResultContainer } from '../../../ViewChart.styled';
import { AddProcedureContainer, AddProcedureHeaderContainer, AddProcedureHeader, SearchInputField, SearchBoxContainer, MedicineSearchResultHeader, MedicineHeader, MedicineSearchResult, MedicineSearchResultItem } from '../BillingCard.styled';

function AddMedicines({ setNewBillingItem, addBillingCardData }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const medicineList = useSelector((state) => state.inventory && state.inventory.medicineList);
  const [name, setName] = useState([]);
  const [generic, setGeneric] = useState([]);
  const [added, setAdded] = useState(0);
  const medicineHeader = [
    { name: 'Dosage', id: 'dosage', width: '10%', align: 'center' },
    { name: 'Prep', id: 'preparation', width: '10%', align: 'center' },
    { name: 'Medicine Name', id: 'name', width: '30%', align: 'left' },
    { name: 'Generic Name', id: 'generic', width: '30%', align: 'left' },
    { name: 'On Hand', id: 'onHand', width: '10%', align: 'center' },
    { name: 'Price', id: 'price', width: '10%', align: 'center' },
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(searchMedicine({ method: 'get', url: `medicine/search?name=${name}&generic=${generic}`, token: currentUser.userToken }));
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [name, generic]);

  function handleAddMedicine(medicine) {
    setName('');
    setGeneric('');
    addBillingCardData(`${medicine.name} (${medicine.generic}) - ${medicine.dosage} ${medicine.preparation}`, medicine.price, 'medicine', medicine._id, medicine.onHand);
    setAdded(added + 1);
  }

  return (
    <AddProcedureContainer>
      <AddProcedureHeaderContainer>
        <AddProcedureHeader width={'100%'}>Add Medicines</AddProcedureHeader>
      </AddProcedureHeaderContainer>
      <SearchBoxContainer>
        <SearchInputField value={name} onChange={(e) => setName(e.target.value)} placeholder={'Search Medicine Name'} />
        <SearchInputField value={generic} onChange={(e) => setGeneric(e.target.value)} placeholder={'Search Generic Name'} />
        {added !== 0 ? <ButtonPrimary onClick={() => setNewBillingItem('')}>Done</ButtonPrimary> : <ButtonDanger onClick={() => setNewBillingItem('')}>Cancel</ButtonDanger>}
      </SearchBoxContainer>
      <SearchResultContainer>
        <MedicineSearchResultHeader>
          {medicineHeader.map((item, index) => {
            return (
              <MedicineHeader key={index} width={item.width}>
                {item.name}
              </MedicineHeader>
            );
          })}
        </MedicineSearchResultHeader>
        {medicineList &&
          medicineList.map((medicine, index) => {
            return (
              <MedicineSearchResult key={index} onClick={() => handleAddMedicine(medicine)}>
                {medicineHeader.map((item, i) => {
                  return (
                    <MedicineSearchResultItem key={i} width={item.width} align={item.align} shortage={item.id === 'onHand' && medicine.onHand < medicine.minQty}>
                      {medicine[item.id]}
                    </MedicineSearchResultItem>
                  );
                })}
              </MedicineSearchResult>
            );
          })}
      </SearchResultContainer>
    </AddProcedureContainer>
  );
}

export default AddMedicines;
