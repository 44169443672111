import styled from 'styled-components/macro';

export const UserHeaderTitle = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? 'orange' : ({ theme }) => theme.CardFontLight)};
  ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 0.8rem;
  padding: 6px 10px 1px 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: ${(props) => (props.active ? 'orange' : ({ theme }) => theme.CardFontLight)};
  }

  @media (max-width: 992px) {
    display: none;
    background: red;
  }
`;

export const HamburgerNavContainer = styled.nav`
  display: block;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: 92px;
  left: 2px;
  max-height: ${(props) => props.maxHeight || '300px'};
  transition: max-height 0.5s ease-in-out;
`;

export const HamburgerIcon = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`;

export const HamburgerNav = styled.div`
  text-align: left;
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 8px 15px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

export const DisplayBar = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin: 5px;
  border: 1px solid ${({ theme }) => theme.CardBorder};

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const DisplayBarText = styled.div`
  font-size: 0.8rem;
  padding: 5px 20px 0px 10px;
  min-width: 200px;
  margin-bottom: 5px;
`;

export const DisplayBarInput = styled.input`
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  padding: 5px 50px 5px 10px;
  min-width: 200px;
  margin-left: 10px;
`;

export const ProcedureInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DisplayBarLabel = styled.label`
  font-size: 0.8rem;
  width: 100px;
  margin: 8px 0px 2px 10px;
`;

export const DisplayBarInputTextArea = styled.textarea`
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  padding: 5px 50px 5px 10px;
  font-size: 0.8rem;
  margin-left: 10px;
`;

export const DisplayBarMultiText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DisplayBarMultiTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const DisplayBarInputSet = styled.div`
  display: flex;
  padding: 5px;
`;

export const ColorContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const ColorInput = styled.input`
  padding: 0px;
  margin: 0px;
  width: 25px;
  margin-left: 10px;
`;

export const DisplayBarSelect = styled.select`
  display: flex;
  padding: 5px;
  margin-left: 10px;
`;
