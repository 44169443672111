import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';

export const getAllCharts = createAsyncThunk('chart/getAllCharts', async (action) => {
  return await apiRequest(action);
});

export const createNewChart = createAsyncThunk('chart/createNewChart', async (action) => {
  return await apiRequest(action);
});

export const updateChartStatus = createAsyncThunk('chart/updateChartStatus', async (action) => {
  return await apiRequest(action);
});

export const getChartsByPatientId = createAsyncThunk('chart/getChartsByPatientId', async (action) => {
  return await apiRequest(action);
});

export const reassignChart = createAsyncThunk('chart/reassignChart', async (action) => {
  return await apiRequest(action);
});

export const rescheduleChart = createAsyncThunk('chart/rescheduleChart', async (action) => {
  return await apiRequest(action);
});

export const addCardtoChart = createAsyncThunk('chart/addCardtoChart', async (action) => {
  return await apiRequest(action);
});

export const updateCardInChart = createAsyncThunk('chart/updateCardInChart', async (action) => {
  return await apiRequest(action);
});

export const editCardInChart = createAsyncThunk('chart/editCardInChart', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  allChartsData: [],
  chartData: {},
  patientsChartList: [],
  triggerPatientChartList: 'idle',
  status: 'idle',
  localLoadingStatus: 'idle',
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // get all charts
      .addCase(getAllCharts.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllCharts.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.allChartsData = action.payload.data;
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(getAllCharts.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // get all charts by patient id
      .addCase(getChartsByPatientId.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.patientsChartList = action.payload.data;
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          state.localLoadingStatus = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(getChartsByPatientId.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // create new chart
      .addCase(createNewChart.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createNewChart.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.chartData = action.payload.data;
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(createNewChart.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // update chart status
      .addCase(updateChartStatus.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateChartStatus.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.chartData = action.payload.data;
          state.triggerPatientChartList = new Date();
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updateChartStatus.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // reassign chart
      .addCase(reassignChart.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(reassignChart.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.chartData = action.payload.data;
          state.triggerPatientChartList = new Date();
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(reassignChart.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // reschedule chart
      .addCase(rescheduleChart.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(rescheduleChart.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.chartData = action.payload.data;
          state.triggerPatientChartList = new Date();
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(rescheduleChart.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // add Card to Chart
      .addCase(addCardtoChart.pending, (state, action) => {
        state.localLoadingStatus = 'loading';
      })
      .addCase(addCardtoChart.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.triggerPatientChartList = new Date();
          state.localLoadingStatus = 'success';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(addCardtoChart.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // update Card in Chart
      .addCase(updateCardInChart.pending, (state, action) => {
        state.localLoadingStatus = 'loading';
      })
      .addCase(updateCardInChart.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.triggerPatientChartList = new Date();
          state.localLoadingStatus = 'success';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updateCardInChart.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // edit Card in Chart
      .addCase(editCardInChart.pending, (state, action) => {
        state.localLoadingStatus = 'loading';
      })
      .addCase(editCardInChart.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.triggerPatientChartList = new Date();
          state.localLoadingStatus = 'success';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(editCardInChart.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      });
  },
});

export const { setStatus } = chartSlice.actions;
export default chartSlice.reducer;
