import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';

import {
  ChartCardInputContainer,
  ChartCardTitle,
  ChartCardBar,
  ChartCardBody,
  ChartCardBodyAccordion,
  CertifyText,
  MedCertDataHeader,
  MedCertDataContent,
  DisplayContainer,
  ChartSeparator,
  LabRequestViewContainer,
  DiagnosisDataContainer,
  InlineInputContainer,
  InlineInput,
  InlineMultiLineInput,
  InlineSelect,
  DiagnosisAddDataContainer,
  AddOthersContainer,
  InfoLabel,
  InfoInput,
  DiagnosisContainer,
  SelectInputDiagnosis,
  DiagnosisButtonsContainer,
} from '../ViewChart.styled';
import { IconsContainer, MaximizeIcon, MinimizeIcon, PrintIcon, EditIcon, SaveIcon, CancelIcon, DeleteIcon } from '../../../../../../Reusables/Icons';
import { ButtonDanger, ButtonPrimary, ButtonSuccess, ButtonWarning } from '../../../../../../Reusables/Buttons';
import { PrintPageContainer, PrintBody, PrintTitle } from '../../../../../../Reusables/PrintTemplates/PrintTemplate.styled';

import PrintHeader from '../../../../../../Reusables/PrintTemplates/PrintHeader';
import PrintPatientsInfo from '../../../../../../Reusables/PrintTemplates/PatientsInfo';
import PhysicianDetails from '../../../../../../Reusables/PrintTemplates/PhysicianDetails';
import PrintFooter from '../../../../../../Reusables/PrintTemplates/PrintFooter';
import RichTextView from './richText/RichTextView';
import RichTextEditor from './richText/RichTextEditor';

function MedCertCard({
  doctorId,
  patientInfo,
  date,
  remarks,
  diagnosis,
  setHideAddCardButton,
  setAddNewCard,
  isNewCard,
  dataList,
  cardId,
  cardIndex,
  disableRefresh,
  options,
}) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const assignedDoctor = doctorList.filter((doctor, index) => doctor._id === doctorId)[0];
  const name = patientInfo.middleName
    ? (patientInfo.firstName + ' ' + patientInfo.middleName + ' ' + patientInfo.lastName).toUpperCase()
    : (patientInfo.firstName + ' ' + patientInfo.lastName).toUpperCase();

  const [isMinimized, setIsMinimized] = useState(false);
  const [dateAndTime, setDateAndTime] = useState(moment());
  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [cardData, setCardData] = useState(isNewCard ? { diagnosis, remarks } : dataList);
  const [content, setContent] = useState(
    '{"blocks":[{"key":"dlpl6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
  );
  const [isPrint, setIsPrint] = useState(false);
  const [lineIsEdit, setLineIsEdit] = useState(false);
  const [lineEditIndex, setLineEditIndex] = useState(null);
  const [lineIsEditIcd10, setLineIsEditIcd10] = useState('');
  const [lineIsEditDiagnosis, setLineIsEditDiagnosis] = useState('');
  const [lineIsEditEyes, setLineIsEditEyes] = useState('');

  const [addOthers, setAddOthers] = useState(false);
  const [otherDiagnosis, setOtherDiagnosis] = useState('');
  const [otherIcd10, setOtherIcd10] = useState('');
  const [diagnosisList, setDiagnosisList] = useState(cardIsEdit ? dataList.diagnosis : diagnosis);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('');

  useEffect(() => {
    isNewCard ? setCardData({ diagnosis, remarks }) : setCardData(dataList);
    isNewCard && setContent(remarks);
    cardIsEdit && setDiagnosisList(dataList.diagnosis);
  }, [dataList, diagnosis, remarks, cardIsEdit, isNewCard]);

  const handleCancel = () => {
    setCardData(isNewCard ? { diagnosis, remarks } : dataList);
    setCardIsEdit(false);
    setAddNewCard('');
    setHideAddCardButton(false);
  };

  const handleEdit = () => {
    setCardData(dataList);
    setCardIsEdit(true);
    setHideAddCardButton(true);
    disableRefresh(true);
    setContent(dataList.remarks);
  };

  const handleAddCard = () => {
    let newData = { diagnosis: diagnosisList, remarks: content };
    setHideAddCardButton(false);
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(
      addCardtoChart({
        method: 'post',
        url: `chart/addCard/${cardId}`,
        token: currentUser.userToken,
        data: {
          cardType: 'med_cert_card',
          cardData: newData,
          addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}`,
        },
      })
    );
    disableRefresh(false);
  };

  const handleUpdateCard = () => {
    let newData = { diagnosis: diagnosisList, remarks: content };
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(
      updateCardInChart({ method: 'post', url: `chart/updateCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardData: newData } })
    );
    disableRefresh(false);
  };

  function handleBeforeGetContent() {
    setDateAndTime(moment());
    setIsPrint(true);
    return Promise.resolve();
  }

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `${patientInfo && patientInfo.lastName}_${patientInfo && patientInfo.firstName}_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getText = (str) => {
    if (str && isJsonString(str)) {
      return str;
    } else {
      let parsableText = `{"blocks":[{"key":"jv6k","text":"${str}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
      return parsableText;
    }
  };

  const handleSaveLine = (index) => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    let newData =
      diagnosisList &&
      diagnosisList.map((data, i) => {
        if (i === index) {
          return { diagnosis: lineIsEditDiagnosis, icd10: lineIsEditIcd10, eye: lineIsEditEyes };
        } else {
          return data;
        }
      });
    setDiagnosisList(newData);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  const handleCancelLine = () => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };
  const handleEditLine = (index) => {
    setLineIsEdit(true);
    setLineEditIndex(index);
    let data = diagnosisList;
    setLineIsEditIcd10(data[index].icd10);
    setLineIsEditDiagnosis(data[index].diagnosis);
    setLineIsEditEyes(data[index].eye);
  };

  const handleDeleteLine = (index) => {
    setDiagnosisList((prevVal) => prevVal.filter((diagnosis, i) => index !== i));
  };

  const handleAddDiagnosis = (option, eye) => {
    setDiagnosisList([...diagnosisList, { icd10: option.icd10, diagnosis: option.diagnosis, eye }]);
    setSelectedDiagnosis([]);
    setOtherDiagnosis('');
    setOtherIcd10('');
  };

  useEffect(() => {
    selectedDiagnosis === 'others' ? setAddOthers(true) : setAddOthers(false);
    // eslint-disable-next-line
  }, [selectedDiagnosis]);

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>Medical Certificate Card</ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['med_cert_card'] && roles[userRole]['med_cert_card']['update'] && (
                <>
                  <div title="print" onClick={handlePrint}>
                    <PrintIcon />
                  </div>
                  <div title={'edit'} onClick={handleEdit}>
                    <EditIcon />
                  </div>
                </>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          <ChartCardInputContainer width={'100%'}>
            {isPrint ? (
              <PrintPageContainer ref={componentRef}>
                <PrintHeader />
                <PrintTitle>MEDICAL CERTIFICATE</PrintTitle>
                <PrintPatientsInfo patientInfo={patientInfo} date={date} />
                <PrintBody height={'510px'}>
                  <CertifyText>
                    This is to certify that <b>{name}</b> was examined on <b>{moment(dateAndTime).format('MMM DD, yyyy')}</b> with the following findings and
                    diagnosis.
                  </CertifyText>
                  <MedCertDataHeader>Diagnosis:</MedCertDataHeader>
                  <DisplayContainer>
                    {cardData &&
                      cardData.diagnosis &&
                      cardData.diagnosis.map((diag, index) => {
                        return (
                          <MedCertDataContent key={index}>{`${index + 1}) ${diag.icd10 ? diag.icd10 + ': ' : ''} ${diag.diagnosis} - ${
                            diag.eye
                          } `}</MedCertDataContent>
                        );
                      })}
                  </DisplayContainer>
                  <MedCertDataHeader>Remarks:</MedCertDataHeader>
                  <DisplayContainer>{cardData.remarks && <RichTextView storedState={JSON.parse(cardData.remarks)} readOnly={true} />}</DisplayContainer>
                </PrintBody>
                <PhysicianDetails
                  name={`${assignedDoctor.personalInfo.namePrefix ? assignedDoctor.personalInfo.namePrefix : ''} ${
                    assignedDoctor.personalInfo.firstName
                  } ${assignedDoctor.personalInfo.middleName.charAt(0)}. ${assignedDoctor.personalInfo.lastName} ${
                    assignedDoctor.personalInfo.nameSuffix ? assignedDoctor.personalInfo.nameSuffix : ''
                  }`}
                  prc={assignedDoctor.employmentRecord.prcNumber}
                  ptr={assignedDoctor.employmentRecord.ptrNumber}
                />
                <PrintFooter dateAndTime={dateAndTime} />
              </PrintPageContainer>
            ) : (
              <>
                {isNewCard || cardIsEdit ? (
                  <LabRequestViewContainer>
                    <MedCertDataHeader>Diagnosis:</MedCertDataHeader>
                    {diagnosisList &&
                      diagnosisList.map((diag, index) => {
                        return (
                          <Fragment key={index}>
                            {lineIsEdit && lineEditIndex === index ? (
                              <DiagnosisDataContainer>
                                <InlineInputContainer>
                                  <InlineInput value={lineIsEditIcd10} onChange={(e) => setLineIsEditIcd10(e.target.value)} />
                                  <InlineMultiLineInput value={lineIsEditDiagnosis} onChange={(e) => setLineIsEditDiagnosis(e.target.value)} rows={4} />
                                  <InlineSelect value={lineIsEditEyes} onChange={(e) => setLineIsEditEyes(e.target.value)}>
                                    <option>Left Eye</option>
                                    <option>Right Eye</option>
                                    <option>Both Eyes</option>
                                  </InlineSelect>
                                </InlineInputContainer>
                                <IconsContainer>
                                  {lineIsEdit && lineEditIndex === index && (
                                    <>
                                      <div onClick={() => handleSaveLine(index)}>
                                        <SaveIcon />
                                      </div>
                                      <div onClick={() => handleCancelLine()}>
                                        <CancelIcon />
                                      </div>
                                    </>
                                  )}
                                </IconsContainer>
                              </DiagnosisDataContainer>
                            ) : (
                              <DiagnosisDataContainer>
                                <MedCertDataContent>{`${index + 1}) ${diag.icd10 ? diag.icd10 + ': ' : ''} ${diag.diagnosis} - ${
                                  diag.eye
                                } `}</MedCertDataContent>
                                <IconsContainer>
                                  <>
                                    <div onClick={() => handleEditLine(index)}>
                                      <EditIcon />
                                    </div>
                                    <div onClick={() => handleDeleteLine(index)}>
                                      <DeleteIcon />
                                    </div>
                                  </>
                                </IconsContainer>
                              </DiagnosisDataContainer>
                            )}
                          </Fragment>
                        );
                      })}

                    {(isNewCard || cardIsEdit) && (
                      <DiagnosisAddDataContainer>
                        {addOthers ? (
                          <AddOthersContainer>
                            <ChartCardInputContainer display={'flex'} width={'90%'}>
                              <InfoLabel width={'90px'} padding={'5px 10px 0px 0px'}>
                                ICD 10:{' '}
                              </InfoLabel>
                              <InfoInput width={'80%'} value={otherIcd10} onChange={(e) => setOtherIcd10(e.target.value)} />
                            </ChartCardInputContainer>
                            <ChartCardInputContainer display={'flex'} width={'90%'}>
                              <InfoLabel width={'90px'} padding={'5px 10px 0px 0px'}>
                                Diagnosis:{' '}
                              </InfoLabel>
                              <InfoInput width={'80%'} rows={'3'} value={otherDiagnosis} onChange={(e) => setOtherDiagnosis(e.target.value)} />
                            </ChartCardInputContainer>
                            <div>
                              <div onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Left Eye')}>
                                <ButtonSuccess>Left</ButtonSuccess>
                              </div>
                              <div onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Right Eye')}>
                                <ButtonSuccess>Right</ButtonSuccess>
                              </div>
                              <div onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Both Eyes')}>
                                <ButtonSuccess>Both</ButtonSuccess>
                              </div>
                              <div onClick={() => setSelectedDiagnosis('')}>
                                <ButtonWarning>Cancel</ButtonWarning>
                              </div>
                            </div>
                          </AddOthersContainer>
                        ) : (
                          <DiagnosisContainer>
                            <SelectInputDiagnosis value={selectedDiagnosis} onChange={(e) => setSelectedDiagnosis(e.target.value)}>
                              <option disabled value="">
                                -- select diagnosis --
                              </option>
                              {options &&
                                options.map((data, index) => {
                                  return (
                                    <option key={index} value={index}>
                                      {data.diagnosis}
                                    </option>
                                  );
                                })}
                              <option value="others">-- others --</option>
                            </SelectInputDiagnosis>
                            <DiagnosisButtonsContainer>
                              <div onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Left Eye')}>
                                <ButtonSuccess>Left</ButtonSuccess>
                              </div>
                              <div onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Right Eye')}>
                                <ButtonSuccess>Right</ButtonSuccess>
                              </div>
                              <div onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Both Eyes')}>
                                <ButtonSuccess>Both</ButtonSuccess>
                              </div>
                            </DiagnosisButtonsContainer>
                          </DiagnosisContainer>
                        )}
                      </DiagnosisAddDataContainer>
                    )}
                    <MedCertDataHeader>Remarks:</MedCertDataHeader>
                    <RichTextEditor storedState={JSON.parse(getText(cardData.remarks))} setData={(data) => setContent(data)} />
                  </LabRequestViewContainer>
                ) : (
                  <LabRequestViewContainer>
                    <MedCertDataHeader>Diagnosis:</MedCertDataHeader>
                    {cardData &&
                      cardData.diagnosis &&
                      cardData.diagnosis.map((diag, index) => {
                        return (
                          <MedCertDataContent key={index}>{`${index + 1}) ${diag.icd10 ? diag.icd10 + ': ' : ''} ${diag.diagnosis} - ${
                            diag.eye
                          } `}</MedCertDataContent>
                        );
                      })}
                    <MedCertDataHeader>Remarks:</MedCertDataHeader>
                    <DisplayContainer>{cardData.remarks && <RichTextView storedState={JSON.parse(cardData.remarks)} readOnly={true} />}</DisplayContainer>
                  </LabRequestViewContainer>
                )}
              </>
            )}
          </ChartCardInputContainer>
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>{isNewCard ? 'Save' : 'Update'}</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default MedCertCard;
