import React from 'react';
import { useSelector } from 'react-redux';

import { SelectDoctorContainer, SelectDoctorNav, SelectDoctorNavButtons, SelectDoctorCancel, SelectDoctorCancelText } from '../Patients.styled';
import { CancelIconWhite } from '../../../../Reusables/Icons';

function SelectDoctor({ setOpenSelectDoctor, patientId, handleCreateNewChart }) {
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);

  return (
    <SelectDoctorContainer>
      <SelectDoctorNav>
        <SelectDoctorNavButtons title="cancel" onClick={() => setOpenSelectDoctor(false)}>
          <SelectDoctorCancel>
            <CancelIconWhite />
            <SelectDoctorCancelText>Cancel</SelectDoctorCancelText>
          </SelectDoctorCancel>
        </SelectDoctorNavButtons>
        {doctorList &&
          doctorList.map((doctor, index) => {
            return (
              <SelectDoctorNavButtons
                key={index}
                value={doctor._id}
                onClick={() => {
                  setOpenSelectDoctor(false);
                  handleCreateNewChart(patientId, doctor._id);
                }}
              >
                Dr. {doctor && doctor.personalInfo && doctor.personalInfo.firstName} {doctor && doctor.personalInfo && doctor.personalInfo.lastName}
              </SelectDoctorNavButtons>
            );
          })}
      </SelectDoctorNav>
    </SelectDoctorContainer>
  );
}

export default SelectDoctor;
