import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveSettings } from '../../../../../config/redux/settings';
import { uploadPhoto } from '../../../../../config/redux/files';

import { CardContainer, CardBody, CardBar } from '../../../../Reusables/BodyAndBars';
import { ButtonPrimary } from '../../../../Reusables/Buttons';
import { IconsContainer, UploadIcon, EditIcon, SaveIcon, CancelIcon, DayIcon, NightIcon, DeleteIcon } from '../../../../Reusables/Icons';
import { Row, InfoContainer, InfoLabel, InfoInput, InfoInputSelect } from '../../../../Reusables/ViewProfile/ViewProfile.styled';
import {
  CompanySettingsContainer,
  CompanySettingsName,
  CompanySettingsSubBar,
  HeaderLogo,
  Logo,
  Background,
  UploadImage,
  Reminder,
  DisplayCompanyNameContainer,
  DisplayCompanyName,
  DisplayCompanyText,
  EditCompanyNameContainer,
  ColorInput,
  DisplayGoogleFontList,
  ButtonContainer,
  GFontDisplayContainer,
} from '../Settings.styled';

function CompanySettings() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const companySettings = useSelector((state) => state.settings && state.settings.companySettings);
  const [isEditHeaderLogo, setIsEditHeaderLogo] = useState(false);
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [isEditBackground, setIsEditBackground] = useState(false);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [mainLogo, setMainLogo] = useState(null);
  const [background, setBackground] = useState(null);
  const [isEditCompanyNameAndText, setIsEditCompanyNameAndText] = useState(false);
  const [companyNameAndText, setCompanyNameAndText] = useState(companySettings ? companySettings : {});
  const [isNight, setIsNight] = useState(false);
  const [newFont, setNewFont] = useState('');
  const [newFontUrl, setNewFontUrl] = useState('');
  const [fontList, setFontList] = useState(companySettings ? companySettings.fontList : []);

  const handleSaveImage = (type) => {
    let imageFile = (type === 'headerLogo' && headerLogo) || (type === 'mainLogo' && mainLogo) || (type === 'background' && background);
    let blob = type === 'headerLogo' || (type === 'mainLogo' && mainLogo) ? imageFile.slice(0, imageFile.size, 'image/png') : imageFile.slice(0, imageFile.size, 'image/jpeg');
    let imageName = (type === 'headerLogo' && 'headerLogo.png') || (type === 'mainLogo' && 'mainLogo.png') || (type === 'background' && 'background.jpg');
    let imageType = (type === 'headerLogo' && 'image/png') || (type === 'mainLogo' && 'image/png') || (type === 'background' && 'image/jpeg');
    let newFile = new File([blob], imageName, { type: imageType });
    let formdata = new FormData();
    formdata.append('imageFile', newFile);
    dispatch(uploadPhoto({ method: 'post', url: `upload`, token: currentUser.userToken, data: formdata }));
    type === 'headerLogo' && setIsEditHeaderLogo(false);
    type === 'headerLogo' && setHeaderLogo(null);
    type === 'mainLogo' && setIsEditLogo(false);
    type === 'mainLogo' && setMainLogo(null);
    type === 'background' && setIsEditBackground(false);
    type === 'background' && setBackground(null);
  };

  useEffect(() => {
    setCompanyNameAndText(companySettings);
    setFontList(companySettings.fontList);
    // eslint-disable-next-line
  }, [companySettings]);

  const handleChange = (e) => {
    let id = e.target.id;
    let name = e.target.name;
    let value = e.target.value;
    let checked = e.target.checked;
    if (name === 'backgroundColor') {
      setCompanyNameAndText({ ...companyNameAndText, backgroundColor: value });
    } else if (name === 'font') {
      let url = fontList.filter((font) => font.fontName === value)[0].fontUrl;
      name === 'showOnLogin' ? setCompanyNameAndText({ ...companyNameAndText, [id]: { ...companyNameAndText[id], [name]: checked, url } }) : setCompanyNameAndText({ ...companyNameAndText, [id]: { ...companyNameAndText[id], [name]: value, url } });
    } else {
      name === 'showOnLogin' ? setCompanyNameAndText({ ...companyNameAndText, [id]: { ...companyNameAndText[id], [name]: checked } }) : setCompanyNameAndText({ ...companyNameAndText, [id]: { ...companyNameAndText[id], [name]: value } });
    }
  };

  const handleSave = () => {
    setIsEditCompanyNameAndText(false);
    dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { companySettings: companyNameAndText } }));
  };

  const handleAddFont = () => {
    if (newFont === '' && newFontUrl === '') {
      window.alert('Google Font name and url is required!');
    } else {
      setFontList([...fontList, { fontName: newFont, fontUrl: newFontUrl }]);
      setCompanyNameAndText({ ...companyNameAndText, fontList: [...fontList, { fontName: newFont, fontUrl: newFontUrl }] });
      setNewFont('');
      setNewFontUrl('');
    }
  };

  const handleDeleteGoogleFont = (name) => {
    setFontList(fontList.filter((font) => font.fontName !== name));
  };

  return (
    <CardContainer width={'80%'}>
      <CardBar>Company Settings</CardBar>
      <CardBody>
        <CompanySettingsContainer>
          <CompanySettingsSubBar>
            <CompanySettingsName>Company Name and Text</CompanySettingsName>
            <IconsContainer>
              {isNight ? (
                <div onClick={() => setIsNight(false)}>
                  <DayIcon />
                </div>
              ) : (
                <div onClick={() => setIsNight(true)}>
                  <NightIcon />
                </div>
              )}
              {isEditCompanyNameAndText ? (
                <>
                  <div onClick={() => handleSave()}>
                    <SaveIcon />
                  </div>
                  <div onClick={() => setIsEditCompanyNameAndText(false)}>
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <div onClick={() => setIsEditCompanyNameAndText(true)}>
                  <EditIcon />
                </div>
              )}
            </IconsContainer>
          </CompanySettingsSubBar>
          <DisplayCompanyNameContainer night={isNight}>
            <DisplayCompanyName
              fontUrl={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.url}
              fontStyle={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.font}
              fontColor={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.color}
            >
              {companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.name}
            </DisplayCompanyName>
            <DisplayCompanyText
              fontUrl={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.url}
              fontStyle={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.font}
              fontColor={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.color}
            >
              {companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.text}
            </DisplayCompanyText>
          </DisplayCompanyNameContainer>
          <EditCompanyNameContainer maxHeight={isEditCompanyNameAndText && '400px'}>
            <Row>
              <InfoContainer width={'98.9%'}>
                <InfoLabel>Background Color:</InfoLabel>
                <ColorInput type="color" value={companyNameAndText && companyNameAndText.backgroundColor} id={'backgroundColor'} name={'backgroundColor'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
            </Row>
            <Row>
              <InfoContainer width={'74.4%'}>
                <InfoLabel>Company Name:</InfoLabel>
                <InfoInput value={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.name} id={'companyName'} name={'name'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
              <InfoContainer width={'24%'}>
                <InfoLabel>Show on Log in Screen:</InfoLabel>
                <input type="checkbox" checked={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.showOnLogin} id={'companyName'} name={'showOnLogin'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
            </Row>
            <Row>
              <InfoContainer width={'50%'}>
                <InfoLabel>Font Style</InfoLabel>
                <InfoInputSelect value={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.font} id={'companyName'} name={'font'} onChange={(e) => handleChange(e)}>
                  {fontList &&
                    fontList.map((item, index) => {
                      return (
                        <option key={index} value={item.fontName}>
                          {item.fontName}
                        </option>
                      );
                    })}
                </InfoInputSelect>
              </InfoContainer>
              <InfoContainer width={'24%'}>
                <InfoLabel>Font Weight:</InfoLabel>
                <InfoInputSelect value={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.fontWeight} id={'companyName'} name={'fontWeight'} onChange={(e) => handleChange(e)}>
                  <option value={'normal'}>Normal</option>
                  <option value={'bold'}>Bold</option>
                </InfoInputSelect>
              </InfoContainer>
              <InfoContainer width={'24%'}>
                <InfoLabel>Font Color:</InfoLabel>
                <ColorInput type="color" value={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyName.color} id={'companyName'} name={'color'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
            </Row>

            <Row>
              <InfoContainer width={'74.4%'}>
                <InfoLabel>Company Text:</InfoLabel>
                <InfoInput value={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.text} id={'companyText'} name={'text'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
              <InfoContainer width={'24%'}>
                <InfoLabel>Show on Log in Screen:</InfoLabel>
                <input type="checkbox" checked={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.showOnLogin} id={'companyText'} name={'showOnLogin'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
            </Row>
            <Row>
              <InfoContainer width={'50%'}>
                <InfoLabel>Text Style</InfoLabel>
                <InfoInputSelect value={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.font} id={'companyText'} name={'font'} onChange={(e) => handleChange(e)}>
                  {fontList.map((item, index) => {
                    return (
                      <option key={index} value={item.fontName}>
                        {item.fontName}
                      </option>
                    );
                  })}
                </InfoInputSelect>
              </InfoContainer>
              <InfoContainer width={'24%'}>
                <InfoLabel>Font Weight:</InfoLabel>
                <InfoInputSelect value={companyNameAndText && companyNameAndText.companyName && companyNameAndText.companyText.fontWeight} id={'companyText'} name={'fontWeight'} onChange={(e) => handleChange(e)}>
                  <option value={'normal'}>Normal</option>
                  <option value={'bold'}>Bold</option>
                </InfoInputSelect>
              </InfoContainer>
              <InfoContainer width={'24%'}>
                <InfoLabel>Text Color:</InfoLabel>
                <ColorInput type="color" value={companyNameAndText && companyNameAndText.companyText && companyNameAndText.companyText.color} id={'companyText'} name={'color'} onChange={(e) => handleChange(e)} />
              </InfoContainer>
            </Row>
          </EditCompanyNameContainer>
          <DisplayGoogleFontList maxHeight={isEditCompanyNameAndText && '2000px'}>
            {fontList &&
              fontList.map((font, index) => {
                return (
                  <div key={index}>
                    {font.fontUrl && (
                      <GFontDisplayContainer>
                        <InfoContainer width={'25%'}>
                          <InfoLabel>
                            <b>Name:</b> {font.fontName}
                          </InfoLabel>
                        </InfoContainer>
                        <InfoContainer width={'70%'}>
                          <InfoLabel>
                            <b>URL:</b> {font.fontUrl}
                          </InfoLabel>
                        </InfoContainer>
                        <IconsContainer marginLeft={'8px'}>
                          <div onClick={() => handleDeleteGoogleFont(font.fontName)}>
                            <DeleteIcon />
                          </div>
                        </IconsContainer>
                      </GFontDisplayContainer>
                    )}
                  </div>
                );
              })}
            <Row>
              <InfoContainer width={'30%'}>
                <InfoLabel>Name:</InfoLabel>
                <InfoInput value={newFont} onChange={(e) => setNewFont(e.target.value)} />
              </InfoContainer>
              <InfoContainer width={'69.1%'}>
                <InfoLabel>URL:</InfoLabel>
                <InfoInput value={newFontUrl} onChange={(e) => setNewFontUrl(e.target.value)} />
              </InfoContainer>
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonPrimary onClick={() => handleAddFont()}>Add Font</ButtonPrimary>
              </ButtonContainer>
            </Row>
          </DisplayGoogleFontList>
        </CompanySettingsContainer>
        <CompanySettingsContainer>
          <CompanySettingsSubBar>
            <CompanySettingsName>Header Logo</CompanySettingsName>
            <IconsContainer>
              {isEditHeaderLogo ? (
                <>
                  {headerLogo ? (
                    <div onClick={() => handleSaveImage('headerLogo')}>
                      <SaveIcon />
                    </div>
                  ) : (
                    <>
                      <UploadImage type={'file'} accept="image/png" id="headerLogo" onChange={(e) => setHeaderLogo(e.target.files[0])} />
                      <label htmlFor="headerLogo">
                        <UploadIcon />
                      </label>
                    </>
                  )}
                  <div
                    onClick={() => {
                      setIsEditHeaderLogo(false);
                      setHeaderLogo(null);
                    }}
                  >
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <div onClick={() => setIsEditHeaderLogo(true)}>
                  <EditIcon />
                </div>
              )}
            </IconsContainer>
          </CompanySettingsSubBar>
          <Reminder maxHeight={isEditHeaderLogo && '200px'}>
            <b>IMPORTANT:</b> Header mainLogo should be 50px in height should be a .png file.
          </Reminder>
          {headerLogo ? <HeaderLogo src={URL.createObjectURL(headerLogo)} alt="headerLogo" /> : <HeaderLogo src={`${process.env.REACT_APP_FILES_URL}headerLogo.png`} alt="headerLogo" />}
        </CompanySettingsContainer>
        <CompanySettingsContainer>
          <CompanySettingsSubBar>
            <CompanySettingsName>Login Logo</CompanySettingsName>
            <IconsContainer>
              {isEditLogo ? (
                <>
                  {mainLogo ? (
                    <div onClick={() => handleSaveImage('mainLogo')}>
                      <SaveIcon />
                    </div>
                  ) : (
                    <>
                      <UploadImage type={'file'} accept="image/png" id="mainLogo" onChange={(e) => setMainLogo(e.target.files[0])} />
                      <label htmlFor="mainLogo">
                        <UploadIcon />
                      </label>
                    </>
                  )}
                  <div
                    onClick={() => {
                      setIsEditLogo(false);
                      setMainLogo();
                    }}
                  >
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <div onClick={() => setIsEditLogo(true)}>
                  <EditIcon />
                </div>
              )}
            </IconsContainer>
          </CompanySettingsSubBar>
          <Reminder maxHeight={isEditLogo && '200px'}>
            <b>IMPORTANT:</b> Logo must have a height of 100px and must be a .png file.
          </Reminder>
          {mainLogo ? <Logo src={URL.createObjectURL(mainLogo)} alt="mainLogo" /> : <Logo src={`${process.env.REACT_APP_FILES_URL}mainLogo.png`} alt="mainLogo" />}
        </CompanySettingsContainer>
        <CompanySettingsContainer>
          <CompanySettingsSubBar>
            <CompanySettingsName>Login Background Image</CompanySettingsName>
            <IconsContainer>
              {isEditBackground ? (
                <>
                  {background ? (
                    <div onClick={() => handleSaveImage('background')}>
                      <SaveIcon />
                    </div>
                  ) : (
                    <>
                      <UploadImage type={'file'} accept="image/jpg" id="background" onChange={(e) => setBackground(e.target.files[0])} />
                      <label htmlFor="background">
                        <UploadIcon />
                      </label>
                    </>
                  )}
                  <div
                    onClick={() => {
                      setIsEditBackground(false);
                      setBackground();
                    }}
                  >
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <div onClick={() => setIsEditBackground(true)}>
                  <EditIcon />
                </div>
              )}
            </IconsContainer>
          </CompanySettingsSubBar>
          <Reminder maxHeight={isEditBackground && '200px'}>
            <b>IMPORTANT:</b> Login background image must be 1920px by 1080px and should be a .jpg file.
          </Reminder>
          {background ? <Background src={URL.createObjectURL(background)} alt="Background Image" /> : <Background src={`${process.env.REACT_APP_FILES_URL}background.jpg`} alt="Background Image" />}
        </CompanySettingsContainer>
      </CardBody>
    </CardContainer>
  );
}

export default CompanySettings;
