import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';

import { ChartCardInputContainer, ChartCardTitle, InfoLabel, InfoData, ChartCardBar, ChartCardBody, InfoInput, ChartSeparator, ChartCardBodyAccordion, Owner } from '../ViewChart.styled';
import { IconsContainer, EditIcon, MaximizeIcon, MinimizeIcon } from '../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger } from '../../../../../../Reusables/Buttons';

function NurseOptoCard({ dataList, isNewCard, setAddNewCard, title, cardId, cardType, setHideAddCardButton, cardIndex, owner, addedDate }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const nurseCardData = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.nurseCardData);
  const optoCardData = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.optoCardData);
  const refractionCard = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.refractionCard);
  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    setCardData(dataList);
  }, [dataList]);

  const handleChange = (id, value) => {
    setCardData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, value: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleAddCard = () => {
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(addCardtoChart({ method: 'post', url: `chart/addCard/${cardId}`, token: currentUser.userToken, data: { cardType, cardData, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` } }));
  };

  const handleUpdateCard = () => {
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(updateCardInChart({ method: 'post', url: `chart/updateCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardData } }));
  };

  const handleCancel = () => {
    setCardData(dataList);
    setCardIsEdit(false);
    setAddNewCard('');
    setHideAddCardButton(false);
  };

  const handleEdit = () => {
    setCardData(dataList);
    setCardIsEdit(true);
    setHideAddCardButton(true);
  };

  const getWidth = (id) => {
    let widthSource;
    if (cardType === 'nurse_card') widthSource = nurseCardData;
    if (cardType === 'opto_card') widthSource = optoCardData;
    if (cardType === 'refrac_card') widthSource = refractionCard;

    widthSource = widthSource.filter((data) => data.id === id)[0];
    if (widthSource) {
      if (widthSource.width) {
        return widthSource.width;
      } else {
        return '24%';
      }
    }
  };

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>
          {title}{' '}
          {!isNewCard && (
            <Owner>
              Added by: <b>{owner}</b> on {moment(addedDate).format('yyy-MM-DD')}
            </Owner>
          )}
        </ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole][cardType] && roles[userRole][cardType]['update'] && (
                <div title={'edit'} onClick={handleEdit}>
                  <EditIcon />
                </div>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          {cardData &&
            cardData.map((data, index) => {
              return (
                <ChartCardInputContainer key={index} width={getWidth(data.id)}>
                  <InfoLabel>{data.label}</InfoLabel>
                  {cardIsEdit || isNewCard ? <InfoInput type="text" value={data.value} onChange={(e) => handleChange(data.id, e.target.value)} /> : <InfoData>{data.value}</InfoData>}
                </ChartCardInputContainer>
              );
            })}
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default NurseOptoCard;
