import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AddProcedureContainer, AddProcedureHeaderContainer, AddProcedureHeader, AddProcedureSearchContainer, SearchInputField } from '../BillingCard.styled';
import { SearchResultContainer, SearchResult, SearchResultButtonContainer } from '../../../ViewChart.styled';
import { ButtonSuccess, ButtonDanger, ButtonPrimary } from '../../../../../../../../Reusables/Buttons';

function AddProcedure({ addBillingCardData, setNewBillingItem }) {
  const ProcedureList = useSelector((state) => state.dataList && state.dataList.dataList.procedureList);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [added, setAdded] = useState(0);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTextValue.length >= 3) {
        setSearchResult(ProcedureList.filter((procedure) => procedure.Description.toLowerCase().includes(searchTextValue.toLowerCase())));
      } else {
        setSearchResult([]);
      }
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [searchTextValue]);

  const handleAddDiagnosis = (data, eye) => {
    let price = eye === 'Both Eyes' ? data.Price * 2 : data.Price;
    setSearchResult([]);
    setSearchTextValue('');
    addBillingCardData(`[ ${data.RVScode} ] ${data.Description} - ${eye}`, price, 'procedure', data.RVScode);
    setAdded(added + 1);
  };

  return (
    <AddProcedureContainer>
      <AddProcedureHeaderContainer>
        <AddProcedureHeader width={'100%'}>Add Procedures</AddProcedureHeader>
      </AddProcedureHeaderContainer>
      <AddProcedureSearchContainer>
        <SearchInputField value={searchTextValue} onChange={(e) => setSearchTextValue(e.target.value)} placeholder={'Search'} />
        {added !== 0 ? <ButtonPrimary onClick={() => setNewBillingItem('')}>Done</ButtonPrimary> : <ButtonDanger onClick={() => setNewBillingItem('')}>Cancel</ButtonDanger>}
      </AddProcedureSearchContainer>
      <SearchResultContainer>
        {searchResult &&
          searchResult.map((result, index) => {
            return (
              <SearchResult key={index}>
                {result.Description}
                <SearchResultButtonContainer>
                  <div onClick={() => handleAddDiagnosis(result, 'Left Eye')}>
                    <ButtonSuccess>Left</ButtonSuccess>
                  </div>
                  <div onClick={() => handleAddDiagnosis(result, 'Right Eye')}>
                    <ButtonSuccess>Right</ButtonSuccess>
                  </div>
                  <div onClick={() => handleAddDiagnosis(result, 'Both Eyes')}>
                    <ButtonSuccess>Both</ButtonSuccess>
                  </div>
                </SearchResultButtonContainer>
              </SearchResult>
            );
          })}
      </SearchResultContainer>
    </AddProcedureContainer>
  );
}

export default AddProcedure;
