import React from 'react';
import { useSelector } from 'react-redux';

import { SelectCardContainer, SelectCardNav, SelectCardNavButtons, SelectCardCancel, SelectCardCancelText } from '../ViewChart.styled';
import { CancelIconWhite } from '../../../../../../Reusables/Icons';

function SelectCard({ setOpenSelectCard, setAddNewCard }) {
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const cardTypes = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.cardTypes);

  const sortArray = (unsortedArray) => {
    let tempArray = [];
    let indexArray = [];
    let sortedArray = [];
    unsortedArray.forEach((type, index) => {
      tempArray.push(type.name + '*' + index);
    });
    tempArray = tempArray.sort();
    tempArray.forEach((type) => {
      let array = type.split('*');
      indexArray.push(array[1]);
    });
    indexArray.forEach((num) => {
      sortedArray.push(unsortedArray[num]);
    });
    return sortedArray;
  };

  return (
    <SelectCardContainer>
      <SelectCardNav>
        <SelectCardNavButtons title="cancel" onClick={() => setOpenSelectCard(false)}>
          <SelectCardCancel>
            <CancelIconWhite />
            <SelectCardCancelText>Cancel</SelectCardCancelText>
          </SelectCardCancel>
        </SelectCardNavButtons>
        {sortArray(cardTypes).map((card, index) => {
          return (
            <div key={index}>
              {roles && roles[userRole] && roles[userRole][card.id] && roles[userRole][card.id]['create'] && (
                <SelectCardNavButtons
                  onClick={() => {
                    setOpenSelectCard(false);
                    setAddNewCard(card.id);
                  }}
                >
                  {card.name}
                </SelectCardNavButtons>
              )}
            </div>
          );
        })}
      </SelectCardNav>
    </SelectCardContainer>
  );
}

export default SelectCard;
