import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDataList, updateDataList } from '../../../../config/redux/dataList';
import { IconsContainer, HamburgerIconWhite } from '../../../Reusables/Icons';
import { ButtonDangerSlim, ButtonPrimarySlim } from '../../../Reusables/Buttons';
import { MainPageContainer, PageContainer, MainBar, TitlesContainer, HamburgerNavContainer, HamburgerNav } from '../../../Reusables/BodyAndBars';

import { UserHeaderTitle, HamburgerIcon } from './DataList.styled';

import Template from './component/Template';

function DataList() {
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state.dataList && state.dataList.dataList);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const [isEdit, setIsEdit] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [updatedDataList, setUpdatedDataList] = useState([]);
  const [headerName, setHeaderName] = useState('Users Roles');
  const [pageName, setPageName] = useState('userRoles');
  const dataListList = [
    { headerName: 'Users Roles', pageName: 'userRoles' },
    { headerName: 'Users Department', pageName: 'userDepartment' },
    { headerName: 'Users Position', pageName: 'userPosition' },
    { headerName: 'Nurse Card ', pageName: 'nurseCardData' },
    { headerName: 'Optomitrist Card', pageName: 'optoCardData' },
    { headerName: 'Refraction Card', pageName: 'refractionCard' },
    { headerName: 'Diagnosis', pageName: 'diagnosisList' },
    { headerName: 'Procedure', pageName: 'procedureList' },
    { headerName: 'Chart Status', pageName: 'chartStatus' },
    { headerName: 'Clinic Site', pageName: 'clinicSite' },
  ];

  const setDataList = (name, data) => {
    setUpdatedDataList({ ...dataList, [name]: data });
  };
  const refresh = () => {
    setIsEdit(false);
    dispatch(getDataList({ method: 'get', url: 'datalist/all', token: currentUser.userToken }));
  };

  function handleSave() {
    setIsEdit(false);
    dispatch(updateDataList({ method: 'post', url: 'datalist/update', token: currentUser.userToken, data: updatedDataList }));
  }

  function handlCancel() {
    setIsEdit(false);
    setUpdatedDataList([]);
  }

  return (
    <MainPageContainer>
      <MainBar noColumn={true}>
        <TitlesContainer>
          {dataListList.map((data, index) => {
            return (
              <UserHeaderTitle
                key={index}
                onClick={() => {
                  setHeaderName(data.headerName);
                  setPageName(data.pageName);
                }}
                active={pageName === data.pageName}
              >
                {data.headerName}
              </UserHeaderTitle>
            );
          })}
          <HamburgerIcon
            onClick={() => {
              setIsHide(!isHide);
            }}
          >
            <HamburgerIconWhite />
          </HamburgerIcon>

          <HamburgerNavContainer maxHeight={isHide ? '0px' : '300px'}>
            {dataListList.map((data, index) => {
              return (
                <HamburgerNav
                  key={index}
                  onClick={() => {
                    setHeaderName(data.headerName);
                    setPageName(data.pageName);
                    setIsHide(true);
                  }}
                  active={pageName === data.pageName}
                >
                  {data.headerName}
                </HamburgerNav>
              );
            })}
          </HamburgerNavContainer>
        </TitlesContainer>
        <IconsContainer>
          {isEdit ? (
            <>
              <ButtonDangerSlim blinking={true} onClick={() => handleSave()}>
                Save
              </ButtonDangerSlim>
              <ButtonPrimarySlim onClick={() => handlCancel()}>Cancel</ButtonPrimarySlim>
            </>
          ) : (
            <ButtonPrimarySlim onClick={() => refresh()}>Refresh</ButtonPrimarySlim>
          )}
        </IconsContainer>
      </MainBar>
      <PageContainer>
        <Template setIsEdit={setIsEdit} dataList={dataList[pageName] ? dataList[pageName] : []} name={pageName} headerName={headerName} setDataList={setDataList} />
      </PageContainer>
    </MainPageContainer>
  );
}

export default DataList;
