import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';

export const userLogIn = createAsyncThunk('users/loginStatus', async (action) => {
  return await apiRequest(action);
});

export const userLogInByToken = createAsyncThunk('users/loginStatusByToken', async (action) => {
  return await apiRequest(action);
});

export const getUserById = createAsyncThunk('users/getUserById', async (action) => {
  return await apiRequest(action);
});

export const userLogOut = createAsyncThunk('users/logoutStatus', async (action) => {
  return await apiRequest(action);
});

export const updateUserProfile = createAsyncThunk('users/updateUserProfile', async (action) => {
  return await apiRequest(action);
});

export const updateUserPassword = createAsyncThunk('users/updateUserPassword', async (action) => {
  return await apiRequest(action);
});

export const updateOwnProfile = createAsyncThunk('users/updateOwnProfile', async (action) => {
  return await apiRequest(action);
});

export const getAllUsers = createAsyncThunk('users/getAllUsers', async (action) => {
  return await apiRequest(action);
});

export const deleteUserById = createAsyncThunk('users/deleteUserById', async (action) => {
  return await apiRequest(action);
});

export const createNewUser = createAsyncThunk('users/createNewUser', async (action) => {
  return await apiRequest(action);
});

export const forgotPassword = createAsyncThunk('users/forgotPassword', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  allUserData: [],
  userData: [],
  currentUser: {},
  passwordUpdate: false,
  status: 'idle',
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reLogin: (state, action) => {
      state.isLoggedIn = false;
      state.currentUser = {};
      state.allUserData = [];
      state.userData = [];
      localStorage.removeItem('clearVisionUserId');
      localStorage.removeItem('clearVisionUserToken');
      localStorage.removeItem('clearVisionClinicSite');
      state.passwordUpdate = false;
      toast.success('Please log in using your new password.');
    },
  },
  extraReducers(builder) {
    builder
      // User log in
      .addCase(userLogIn.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(userLogIn.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.isLoggedIn = true;
          state.currentUser = action.payload.data;
          localStorage.setItem('clearVisionUserId', action.payload.data.userId);
          localStorage.setItem('clearVisionUserToken', action.payload.data.userToken);
        } else {
          toast.error(action.payload.message);
          state.currentUser = {};
        }
      })
      .addCase(userLogIn.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.currentUser = {};
        state.status = 'failed';
      })

      // Login user by token
      .addCase(userLogInByToken.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(userLogInByToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.status === 'Success') {
          state.isLoggedIn = true;
          state.currentUser = action.payload.data;
          localStorage.setItem('clearVisionUserId', action.payload.data.userId);
          localStorage.setItem('clearVisionUserToken', action.payload.data.userToken);
        } else {
          toast.error(action.payload.message);
          state.currentUser = {};
          localStorage.removeItem('clearVisionUserId');
          localStorage.removeItem('clearVisionUserToken');
        }
      })
      .addCase(userLogInByToken.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        state.currentUser = {};
        localStorage.removeItem('clearVisionUserId');
        localStorage.removeItem('clearVisionUserToken');
      })

      // Get user by id
      .addCase(getUserById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userData = action.payload.data;
      })
      .addCase(getUserById.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      })

      // User log out
      .addCase(userLogOut.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(userLogOut.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
          state.isLoggedIn = false;
          state.currentUser = {};
          state.allUserData = [];
          state.userData = [];
          localStorage.removeItem('clearVisionUserId');
          localStorage.removeItem('clearVisionUserToken');
          localStorage.removeItem('clearVisionClinicSite');
        } else {
          toast.error(action.payload.message);
          state.currentUser = {};
          state.allUserData = [];
          state.userData = [];
          localStorage.removeItem('clearVisionUserId');
          localStorage.removeItem('clearVisionUserToken');
          localStorage.removeItem('clearVisionClinicSite');
        }
      })
      .addCase(userLogOut.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        state.currentUser = {};
        state.allUserData = [];
        state.userData = [];
      })

      // Update user data
      .addCase(updateUserProfile.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
          state.userData = action.payload.data;
        } else {
          toast.error(action.payload.response.request.statusText);
          state.status = 'failed';
        }
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      })

      // Update own data
      .addCase(updateOwnProfile.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateOwnProfile.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
          state.currentUser.userAlias = action.payload.data.personalInfo.preferredName;
          state.currentUser.userAvatar = action.payload.data.personalInfo.image;
          state.userData = action.payload.data;
        } else {
          toast.error(action.payload.response.request.statusText);
          state.status = 'failed';
        }
      })
      .addCase(updateOwnProfile.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      })

      // Update own password
      .addCase(updateUserPassword.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
          state.passwordUpdate = true;
        } else {
          toast.success(action.payload.message);
          state.status = 'failed';
        }
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        toast.error(action.payload.message);
        state.status = 'failed';
      })

      // Get all user (not silently)

      .addCase(getAllUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allUserData = action.payload.data;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      })

      // delete user by id
      .addCase(deleteUserById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteUserById.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
          state.allUserData = action.payload.data;
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
        }
      })
      .addCase(deleteUserById.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      })

      // create new user
      .addCase(createNewUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
        }
      })
      .addCase(createNewUser.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      })

      // create new user
      .addCase(forgotPassword.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
        }
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
      });
  },
});

export const { reLogin } = userSlice.actions;
export default userSlice.reducer;
