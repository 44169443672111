import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPatientPage, setCurrentPage, setChartPage, setPatientId } from '../../../../../config/redux/settings';

import { getPatientById, updatePatientById, updateSearchStatus } from '../../../../../config/redux/patients';
import { PageContainer, MainBar, TitlesContainer } from '../../../../Reusables/BodyAndBars';
import { ButtonPrimary, ButtonSuccess, ButtonDanger, ButtonContainer } from '../../../../Reusables/Buttons';
import { BottomButtonContainer, HeaderTitle } from '../Patients.styled';

import ViewProfile from '../../../../Reusables/ViewProfile/ViewProfile';
import SelectDoctor from './SelectDoctor';
import PageLoading from '../../../../Loading';

function ViewPatient({ patientId, handleCreateNewChart }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const patientData = useSelector((state) => state.patient && state.patient.patientData);
  const searchStatus = useSelector((state) => state.patient && state.patient.searchStatus);
  const refreshRate = useSelector((state) => state.settings && state.settings.refreshRate);
  const [updatedPatientData, setUpdatedPatientData] = useState({});
  const [refreshCount, setRefreshCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [openSelectDoctor, setOpenSelectDoctor] = useState(false);

  useEffect(() => {
    dispatch(updateSearchStatus('loading'));
    dispatch(getPatientById({ method: 'get', url: `patient/${patientId}`, token: currentUser.userToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isEdit && dispatch(getPatientById({ method: 'get', url: `patient/${patientId}`, token: currentUser.userToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount(refreshCount + 1);
    }, refreshRate);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [refreshCount]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(updatePatientById({ method: 'post', url: `patient/update/${patientId}`, token: currentUser.userToken, data: updatedPatientData }));
    setIsEdit(false);
  };

  return (
    <>
      {searchStatus === 'loading' && <PageLoading />}
      <MainBar>
        <TitlesContainer>
          <HeaderTitle>Patient Details</HeaderTitle>
        </TitlesContainer>
        <ButtonContainer>
          {openSelectDoctor && <SelectDoctor setOpenSelectDoctor={setOpenSelectDoctor} patientId={patientId} handleCreateNewChart={handleCreateNewChart} />}
          {!isEdit && (
            <>
              <ButtonSuccess
                onClick={() => {
                  dispatch(setChartPage('ViewChart'));
                  dispatch(setCurrentPage('Charts'));
                  dispatch(setPatientId(patientData._id));
                }}
              >
                View Chart History
              </ButtonSuccess>
              <ButtonPrimary onClick={() => dispatch(setPatientPage('PatientList'))}>Back to List</ButtonPrimary>
              <ButtonDanger onClick={() => setIsEdit(true)}>Edit Patient</ButtonDanger>
              <ButtonSuccess
                onClick={() => {
                  setOpenSelectDoctor(true);
                }}
              >
                Create Chart
              </ButtonSuccess>
            </>
          )}
        </ButtonContainer>
      </MainBar>
      <form onSubmit={(e) => handleSave(e)}>
        <PageContainer>
          <ViewProfile isEdit={isEdit} setUpdatedUserData={setUpdatedPatientData} userData={patientData} type={'patient'} />
        </PageContainer>
        {isEdit && (
          <BottomButtonContainer>
            <ButtonDanger onClick={() => setIsEdit(false)}>Cancel</ButtonDanger>
            <ButtonSuccess type={'submit'}>Save</ButtonSuccess>
          </BottomButtonContainer>
        )}
      </form>
    </>
  );
}

export default ViewPatient;
