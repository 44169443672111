import styled from 'styled-components/macro';

export const Container = styled.div`
  padding-top: 48px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.CardHeader};
  background-color: ${({ theme }) => theme.MainBackground};
  margin-left: auto;
  margin-right: auto;
  min-height: 80vh;
  border-radius: 2px 2px 0px 0px;

  @media (max-width: 1200px) {
    width: 100%;
    min-height: 80vh;
  }
`;
