import React from 'react';
import moment from 'moment/moment';

import { PrintFooterContainer, PoweredByContainer, DatePrintedContaier} from './PrintTemplate.styled';

function PrintFooter({dateAndTime}) {
  return (
    <PrintFooterContainer>
      <PoweredByContainer>Powered By: ClinicApp.online </PoweredByContainer>
      <DatePrintedContaier>{`Date and time printed: ${moment(dateAndTime).format('MMM DD, yyyy h:mm:ss a')}`}</DatePrintedContaier>
    </PrintFooterContainer>
  );
}

export default PrintFooter;
