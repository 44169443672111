import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from './MainBody.styled';

import Charts from './components/Charts/Charts';
import Profile from './components/Profile/Profile';
import DataList from './components/DataList/DataList';
import Settings from './components/Settings/Settings';
import Users from './components/Users/Users';
import Patients from './components/Patients/Patients';
import Inventory from './components/Inventory/Inventory';

function MainBody() {
  const currentUser = useSelector((state) => state.user && state.user.currentUser);

  const currentPageTemp = useSelector((state) => state.settings.curentPage);

  const currentPage = currentUser.userFirstLogin ? 'Profile' : currentPageTemp;

  return (
    <Container>
      {currentPage === 'Charts' && <Charts />}
      {currentPage === 'Profile' && <Profile />}
      {currentPage === 'Settings' && <Settings />}
      {currentPage === 'DataList' && <DataList />}
      {currentPage === 'Users' && <Users />}
      {currentPage === 'Patients' && <Patients />}
      {currentPage === 'Inventory' && <Inventory />}
    </Container>
  );
}

export default MainBody;
