import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { setDateFrom, setDateTo, setStatusList, setDoctorFilter } from '../../../../../config/redux/settings';

import { FilterContainer, FilterBox, FilterRow, FilterGroup, FilterGroup2, LabelContainer, FilterLabel } from '../Charts.styled';

function FilterModule({ hideFilter, handleToday, setShowDoctor, showDoctor }) {
  const dispatch = useDispatch();
  const chartStatus = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.chartStatus);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const disableDate = useSelector((state) => state.settings && state.settings.disableDate);
  const dateFrom = useSelector((state) => state.settings && state.settings.dateFrom);
  const dateTo = useSelector((state) => state.settings && state.settings.dateTo);
  const statusList = useSelector((state) => state.settings && state.settings.statusList);
  const doctorFilter = useSelector((state) => state.settings && state.settings.doctorFilter);

  useEffect(() => {
    let statusList = [];
    chartStatus &&
      chartStatus.forEach((status) => {
        statusList.push(status.status);
      });
    setStatusList(statusList);
    // eslint-disable-next-line
  }, [chartStatus]);

  const handleStatus = (name, bol) => {
    if (bol) {
      dispatch(setStatusList([...statusList, name]));
    } else {
      dispatch(setStatusList(statusList.filter((status) => status !== name)));
    }
  };

  return (
    <FilterContainer hide={hideFilter}>
      <FilterBox>
        <FilterRow>
          <FilterGroup>
            <LabelContainer>
              <FilterLabel htmlFor="today"> Today:</FilterLabel>
            </LabelContainer>
            <input type="checkbox" id="today" name="today" checked={disableDate} onChange={(e) => handleToday(e.target.checked)} />
          </FilterGroup>
          <FilterGroup>
            <LabelContainer>
              <FilterLabel htmlFor="dateFrom">Date From:</FilterLabel>
            </LabelContainer>
            <input type="date" id="dateFrom" name="dateFrom" disabled={disableDate} value={moment(dateFrom).format('yyyy-MM-DD')} onChange={(e) => dispatch(setDateFrom(moment(e.target.value).startOf('day')))} max={dateTo} />
          </FilterGroup>
          <FilterGroup>
            <LabelContainer>
              <FilterLabel htmlFor="dateTo">Date To: </FilterLabel>
            </LabelContainer>
            <input type="date" id="dateTo" name="dateTo" disabled={disableDate} value={moment(dateTo).format('yyyy-MM-DD')} onChange={(e) => dispatch(setDateTo(moment(e.target.value).endOf('day')))} min={dateFrom} />
          </FilterGroup>
          <FilterGroup>
            <LabelContainer>
              <input type="checkbox" onChange={(e) => setShowDoctor(e.target.checked)} checked={showDoctor} />
              <FilterLabel htmlFor="doctor">Doctor:</FilterLabel>
            </LabelContainer>
            <select type="text" id="doctor" name="doctor" onChange={(e) => dispatch(setDoctorFilter(e.target.value))} value={doctorFilter}>
              <option value="all">All doctors</option>
              {doctorList &&
                doctorList.map((doctor, index) => {
                  return (
                    <option key={index} value={doctor._id}>
                      {doctor && doctor.personalInfo && doctor.personalInfo.firstName} {doctor && doctor.personalInfo && doctor.personalInfo.lastName}
                    </option>
                  );
                })}
            </select>
          </FilterGroup>
        </FilterRow>
        <FilterRow>
          {chartStatus &&
            chartStatus.map((status, index) => {
              return (
                <FilterGroup2 key={index}>
                  <input type="checkbox" id={status.status} name={status.status} onChange={(e) => handleStatus(e.target.name, e.target.checked)} checked={statusList.includes(status.status)} />
                  <FilterLabel htmlFor={status.status}>{status.status}</FilterLabel>
                </FilterGroup2>
              );
            })}
        </FilterRow>
      </FilterBox>
    </FilterContainer>
  );
}

export default FilterModule;
