import React, { useEffect, useState } from 'react';

import { CardContainer, CardBar, CardBody, TitlesContainer } from '../BodyAndBars';
import { ProfilePicture, CardSubBar, CardSubBarName } from './ViewProfile.styled';

import PersonalInfo from './components/PersonalInfo';
import ContactInfo from './components/ContactInfo';
import EmploymentRecord from './components/EmploymentRecord';
import EmergencyContact from './components/EmergencyContact';
import OtherInfo from './components/OtherInformation';

function ViewProfile({ userData, isEdit, setUpdatedUserData, type, currentUserAccess, HeaderTitle }) {
  const [personalInfo, setPersonalInfo] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [employmentRecord, setEmploymentRecord] = useState({});
  const [emergencyContact, setEmergencyContact] = useState({});
  const [otherInfo, setOtherInfo] = useState({});
  const [access, setAccess] = useState('');
  const [role, setRole] = useState('');
  const [isActive, setIsActive] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
    setAccess(userData?.access);
    setRole(userData?.role);
    setIsActive(userData?.isActive);
  }, [userData]);

  useEffect(() => {
    !isEdit && setAccess(userData?.access);
    !isEdit && setRole(userData?.role);
    !isEdit && setIsActive(userData?.isActive);
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    let newData = {};
    if (type === 'patient') {
      newData = { personalInfo, contactInfo, emergencyContact, otherInfo };
    } else {
      newData = { personalInfo, contactInfo, employmentRecord, emergencyContact, access, role, isActive };
    }
    setUpdatedUserData(newData);
    // eslint-disable-next-line
  }, [personalInfo, contactInfo, employmentRecord, emergencyContact, otherInfo, access, role, isActive]);

  return (
    <CardContainer width={'75%'}>
      <CardBar>
        <TitlesContainer>{HeaderTitle}</TitlesContainer>
      </CardBar>
      <CardBody>
        <ProfilePicture src={image ? URL.createObjectURL(image) : userData && userData.personalInfo && userData.personalInfo.image} />
        <PersonalInfo personalInfo={userData && userData.personalInfo} isEdit={isEdit} setPersonalInfo={setPersonalInfo} type={type} setImage={setImage} image={image} />
        <CardSubBar>
          <CardSubBarName>Contact Information</CardSubBarName>
        </CardSubBar>
        <ContactInfo userId={userData && userData._id} contactInfo={userData && userData.contactInfo} emailAddress={userData && userData.emailAddress} isEdit={isEdit} setContactInfo={setContactInfo} type={type} />
        {type === 'user' && (
          <>
            <CardSubBar>
              <CardSubBarName>Employment Record</CardSubBarName>
            </CardSubBar>
            <EmploymentRecord
              employmentRecord={userData && userData.employmentRecord}
              setEmploymentRecord={setEmploymentRecord}
              currentUserAccess={currentUserAccess}
              access={access}
              setAccess={setAccess}
              role={role}
              setRole={setRole}
              isActive={isActive}
              setIsActive={setIsActive}
              isEdit={isEdit}
            />
          </>
        )}

        <CardSubBar>
          <CardSubBarName>Emergency Contact</CardSubBarName>
        </CardSubBar>
        <EmergencyContact emergencyContact={userData ? userData.emergencyContact : {}} isEdit={isEdit} setEmergencyContact={setEmergencyContact} />
        {type === 'patient' && (
          <>
            <CardSubBar>
              <CardSubBarName>Other Information</CardSubBarName>
            </CardSubBar>
            <OtherInfo otherInfo={userData && userData.otherInfo} isEdit={isEdit} setOtherInfo={setOtherInfo} />
          </>
        )}
      </CardBody>
    </CardContainer>
  );
}

export default ViewProfile;
