import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';

import { ButtonSuccess, ButtonWarning } from '../../../../../../Reusables/Buttons';

import RichTextEditor from './richText/RichTextEditor';
import RichTextView from './richText/RichTextView';
// import RichTextNew from './richText/RichTextNew';

import {
  ChartCardTitle,
  InfoData,
  ChartCardBar,
  ChartCardBody,
  ChartSeparator,
  ChartCardSubContainer,
  ChartCardSubtitle,
  InfoInputTextArea,
  TwoColumnCardContainer,
  TwoColumnCard,
  DiagnosisAddDataContainer,
  SelectInputDiagnosis,
  DiagnosisDataContainer,
  ChartCardInputContainer,
  InfoLabel,
  InfoInput,
  ChartCardBodyAccordion,
  DiagnosisContainer,
  DiagnosisButtonsContainer,
  Owner,
  AddOthersContainer,
  InlineInputContainer,
  InlineInput,
  InlineSelect,
} from '../ViewChart.styled';
import { IconsContainer, EditIcon, DeleteIcon, MaximizeIcon, MinimizeIcon, SaveIcon, CancelIcon } from '../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger } from '../../../../../../Reusables/Buttons';

function OphthalmologyCard({ isNewCard, options, setAddNewCard, setHideAddCardButton, cardId, dataList, cardIndex, owner, addedDate }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('');
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [otherDiagnosis, setOtherDiagnosis] = useState('');
  const [otherIcd10, setOtherIcd10] = useState('');
  const [addOthers, setAddOthers] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [plan, setPlan] = useState('{"blocks":[{"key":"dlpl6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}');
  const [lineIsEdit, setLineIsEdit] = useState(false);
  const [lineEditIndex, setLineEditIndex] = useState(null);
  const [lineIsEditIcd10, setLineIsEditIcd10] = useState('');
  const [lineIsEditDiagnosis, setLineIsEditDiagnosis] = useState('');
  const [lineIsEditEyes, setLineIsEditEyes] = useState('');

  useEffect(() => {
    !isNewCard && !cardIsEdit && setDiagnosisList(dataList.diagnosisList);
    !isNewCard && !cardIsEdit && setCardData(dataList);
    isNewCard &&
      !cardIsEdit &&
      setCardData({
        eom_left: '',
        eom_right: '',
        fundus_left: '',
        fundus_right: '',
        hpi: '',
        iop_left: '',
        iop_right: '',
        oa_left: '',
        oa_right: '',
        pmh: '',
        plan: plan,
      });
    // eslint-disable-next-line
  }, [dataList]);

  useEffect(() => {
    selectedDiagnosis === 'others' ? setAddOthers(true) : setAddOthers(false);
    // eslint-disable-next-line
  }, [selectedDiagnosis]);

  const handleEdit = () => {
    setCardIsEdit(true);
    setHideAddCardButton(true);
  };

  const handleAddDiagnosis = (option, eye) => {
    setDiagnosisList([...diagnosisList, { icd10: option.icd10, diagnosis: option.diagnosis, eye }]);
    setSelectedDiagnosis([]);
    setOtherDiagnosis('');
    setOtherIcd10('');
  };

  const handleChange = (name, data) => {
    setCardData({ ...cardData, [name]: data });
  };

  const handleCancel = () => {
    setCardIsEdit(false);
    setAddNewCard('');
    setHideAddCardButton(false);
  };

  const handleDeleteDiagnosis = (diagnosis) => {
    setDiagnosisList(diagnosisList.filter((item) => item.diagnosis !== diagnosis));
  };

  const handleUpdateCard = () => {
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(
      updateCardInChart({
        method: 'post',
        url: `chart/updateCard/${cardId}?index=${cardIndex}`,
        token: currentUser.userToken,
        data: { cardData: { ...cardData, diagnosisList, plan } },
        addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}`,
      })
    );
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  const handleAddCard = () => {
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(
      addCardtoChart({
        method: 'post',
        url: `chart/addCard/${cardId}`,
        token: currentUser.userToken,
        data: { cardType: 'ophtha_card', cardData: { ...cardData, diagnosisList, plan }, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` },
      })
    );
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getText = (str) => {
    if (isJsonString(str)) {
      return str;
    } else {
      let parsableText = `{"blocks":[{"key":"jv6k","text":"${str}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
      return parsableText;
    }
  };

  const handleEditLine = (index) => {
    setLineIsEdit(true);
    setLineEditIndex(index);
    setLineIsEditIcd10(diagnosisList[index].icd10);
    setLineIsEditDiagnosis(diagnosisList[index].diagnosis);
    setLineIsEditEyes(diagnosisList[index].eye);
  };

  const handleSaveLine = (index) => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    let newData = diagnosisList.map((data, i) => {
      if (i === index) {
        return { diagnosis: lineIsEditDiagnosis, icd10: lineIsEditIcd10, eye: lineIsEditEyes };
      } else {
        return data;
      }
    });
    setDiagnosisList(newData);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  const handleCancelLine = () => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditIcd10('');
    setLineIsEditDiagnosis('');
    setLineIsEditEyes('');
  };

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>
          Ophthalmoligist Card{' '}
          {!isNewCard && (
            <Owner>
              Added by: <b>{owner}</b> on {moment(addedDate).format('yyy-MM-DD')}
            </Owner>
          )}
        </ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['ophtha_card'] && roles[userRole]['ophtha_card']['update'] && (
                <div title={'edit'} onClick={handleEdit}>
                  <EditIcon />
                </div>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          <ChartCardSubContainer>
            <ChartCardSubtitle bottomBorder={'none'}>History of Present Illness (HPI)</ChartCardSubtitle>
            {isNewCard || cardIsEdit ? (
              <InfoInputTextArea rows={'3'} value={cardData.hpi} name={'hpi'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
            ) : (
              <InfoData>{dataList.hpi}</InfoData>
            )}
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle bottomBorder={'none'}>Past Medical History (PMH)</ChartCardSubtitle>
            {isNewCard || cardIsEdit ? (
              <InfoInputTextArea rows={'3'} value={cardData.pmh} name={'pmh'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
            ) : (
              <InfoData>{dataList.pmh}</InfoData>
            )}
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle>Intraocular Pressure (IOP)</ChartCardSubtitle>
            <TwoColumnCardContainer>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Right Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.iop_right} name={'iop_right'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.iop_right}</InfoData>
                )}
              </TwoColumnCard>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Left Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.iop_left} name={'iop_left'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.iop_left}</InfoData>
                )}
              </TwoColumnCard>
            </TwoColumnCardContainer>
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle>Extraocular Muscle (EOM)</ChartCardSubtitle>
            <TwoColumnCardContainer>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Right Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.eom_right} name={'eom_right'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.eom_right}</InfoData>
                )}
              </TwoColumnCard>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Left Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.eom_left} name={'eom_left'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.eom_left}</InfoData>
                )}
              </TwoColumnCard>
            </TwoColumnCardContainer>
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle>Biomicroscopy Examination</ChartCardSubtitle>
            <TwoColumnCardContainer>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Right Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.oa_right} name={'oa_right'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.oa_right}</InfoData>
                )}
              </TwoColumnCard>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Left Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.oa_left} name={'oa_left'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.oa_left}</InfoData>
                )}
              </TwoColumnCard>
            </TwoColumnCardContainer>
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle>Funduscopy</ChartCardSubtitle>
            <TwoColumnCardContainer>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Right Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.fundus_right} name={'fundus_right'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.fundus_right}</InfoData>
                )}
              </TwoColumnCard>
              <TwoColumnCard>
                <ChartCardSubtitle bottomBorder={'none'}>Left Eye</ChartCardSubtitle>
                {isNewCard || cardIsEdit ? (
                  <InfoInputTextArea rows={'3'} value={cardData.fundus_left} name={'fundus_left'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                ) : (
                  <InfoData>{dataList.fundus_left}</InfoData>
                )}
              </TwoColumnCard>
            </TwoColumnCardContainer>
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle bottomBorder={'none'}>Assessment and Diagnosis</ChartCardSubtitle>
            {diagnosisList &&
              diagnosisList.map((data, index) => {
                return (
                  <DiagnosisDataContainer key={index}>
                    {lineIsEdit && lineEditIndex === index ? (
                      <InlineInputContainer>
                        <InlineInput value={lineIsEditIcd10} onChange={(e) => setLineIsEditIcd10(e.target.value)} />
                        <InlineInput value={lineIsEditDiagnosis} onChange={(e) => setLineIsEditDiagnosis(e.target.value)} width={'70%'} />
                        <InlineSelect value={lineIsEditEyes} onChange={(e) => setLineIsEditEyes(e.target.value)}>
                          <option>Left Eye</option>
                          <option>Right Eye</option>
                          <option>Both Eyes</option>
                        </InlineSelect>
                      </InlineInputContainer>
                    ) : (
                      <InfoData border={'none'}>
                        {`[ ${data.icd10} ] ${data.diagnosis} -`} <b>{data.eye}</b>
                      </InfoData>
                    )}

                    <IconsContainer>
                      {lineIsEdit && lineEditIndex === index ? (
                        <>
                          <div onClick={() => handleSaveLine(index)}>
                            <SaveIcon />
                          </div>
                          <div onClick={() => handleCancelLine()}>
                            <CancelIcon />
                          </div>
                        </>
                      ) : (
                        <>
                          {(isNewCard || cardIsEdit) && (
                            <>
                              <div onClick={() => handleEditLine(index)}>
                                <EditIcon />
                              </div>
                              <div onClick={() => handleDeleteDiagnosis(data.diagnosis)}>
                                <DeleteIcon />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </IconsContainer>
                  </DiagnosisDataContainer>
                );
              })}
            {(isNewCard || cardIsEdit) && (
              <DiagnosisAddDataContainer>
                {addOthers ? (
                  <AddOthersContainer>
                    <ChartCardInputContainer display={'flex'} width={'90%'}>
                      <InfoLabel width={'90px'} padding={'5px 10px 0px 0px'}>
                        ICD 10:{' '}
                      </InfoLabel>
                      <InfoInput width={'80%'} value={otherIcd10} onChange={(e) => setOtherIcd10(e.target.value)} />
                    </ChartCardInputContainer>
                    <ChartCardInputContainer display={'flex'} width={'90%'}>
                      <InfoLabel width={'90px'} padding={'5px 10px 0px 0px'}>
                        Diagnosis:{' '}
                      </InfoLabel>
                      <InfoInput width={'80%'} rows={'3'} value={otherDiagnosis} onChange={(e) => setOtherDiagnosis(e.target.value)} />
                    </ChartCardInputContainer>
                    <div>
                      <div onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Left Eye')}>
                        <ButtonSuccess>Left</ButtonSuccess>
                      </div>
                      <div onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Right Eye')}>
                        <ButtonSuccess>Right</ButtonSuccess>
                      </div>
                      <div onClick={() => handleAddDiagnosis({ icd10: otherIcd10, diagnosis: otherDiagnosis }, 'Both Eyes')}>
                        <ButtonSuccess>Both</ButtonSuccess>
                      </div>
                      <div onClick={() => setSelectedDiagnosis('')}>
                        <ButtonWarning>Cancel</ButtonWarning>
                      </div>
                    </div>
                  </AddOthersContainer>
                ) : (
                  <DiagnosisContainer>
                    <SelectInputDiagnosis value={selectedDiagnosis} onChange={(e) => setSelectedDiagnosis(e.target.value)}>
                      <option disabled value="">
                        -- select diagnosis --
                      </option>
                      {options &&
                        options.map((data, index) => {
                          return (
                            <option key={index} value={index}>
                              {data.diagnosis}
                            </option>
                          );
                        })}
                      <option value="others">-- others --</option>
                    </SelectInputDiagnosis>
                    <DiagnosisButtonsContainer>
                      <div onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Left Eye')}>
                        <ButtonSuccess>Left</ButtonSuccess>
                      </div>
                      <div onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Right Eye')}>
                        <ButtonSuccess>Right</ButtonSuccess>
                      </div>
                      <div onClick={() => handleAddDiagnosis(options[selectedDiagnosis], 'Both Eyes')}>
                        <ButtonSuccess>Both</ButtonSuccess>
                      </div>
                    </DiagnosisButtonsContainer>
                  </DiagnosisContainer>
                )}
              </DiagnosisAddDataContainer>
            )}
          </ChartCardSubContainer>
          <ChartCardSubContainer>
            <ChartCardSubtitle bottomBorder={'none'}>Management and Plan</ChartCardSubtitle>
            {cardIsEdit ? (
              <RichTextEditor storedState={dataList.plan && JSON.parse(getText(dataList.plan))} setData={(data) => setPlan(data)} />
            ) : (
              <>
                {isNewCard ? (
                  <RichTextEditor
                    storedState={{ blocks: [{ key: 'dlpl6', text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }], entityMap: {} }}
                    setData={(data) => setPlan(data)}
                  />
                ) : (
                  <>
                    {dataList.plan && isJsonString(dataList.plan) ? (
                      <InfoData>
                        <RichTextView storedState={JSON.parse(dataList.plan)} readOnly={true} />
                      </InfoData>
                    ) : (
                      <InfoData>{dataList.plan}</InfoData>
                    )}
                  </>
                )}
              </>
            )}
          </ChartCardSubContainer>
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default OphthalmologyCard;
