import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadPhoto, clearPhotoURL } from '../../../../config/redux/files';
import moment from 'moment';

import { MainInfoData, Row, InfoContainer, InfoLabel, InfoData, InfoInput, InfoInputSelect, CameraModalContainer, CameraModal, StartCamera } from '../ViewProfile.styled';

import TakePhoto from './TakePhoto';

function PersonalInfo({ personalInfo, isEdit, setPersonalInfo, setImage, image, type }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const fileURL = useSelector((state) => state.files && state.files.fileURL);
  const [personalInfoData, setPersonalInfoData] = useState({});
  const [cameraModal, setCameraModal] = useState(false);
  const personalInfoDataList = [
    { name: 'firstName', label: 'First Name:*', type: 'text', placeholder: '', required: true },
    { name: 'middleName', label: 'Middle Name:*', type: 'text', placeholder: '', required: true },
    { name: 'lastName', label: 'Last Name:*', type: 'text', placeholder: '', required: true },
    { name: 'namePrefix', label: 'Name Prefix:', type: 'text', placeholder: '', required: false },
    { name: 'nameSuffix', label: 'Name Suffix:', type: 'text', placeholder: '', required: false },
    { name: 'preferredName', label: 'Nickname:', type: 'text', placeholder: '', required: false },
    { name: 'dateOfBirth', label: 'Date of Birth:*', type: 'date', placeholder: '', required: true },
    { name: 'birthPlace', label: 'Place of Birth:', type: 'text', placeholder: '', required: false },
    { name: 'sex', label: 'Sex:*', type: 'select', placeholder: '', required: true },
    { name: 'civilStatus', label: 'Civil Status:', type: 'select', placeholder: '', required: false },
    { name: 'nationality', label: 'Nationality', type: 'text', placeholder: '', required: false },
    { name: 'bloodType', label: 'Blood Type', type: 'select', placeholder: '', required: false },
    { name: 'image', label: 'image', type: 'file' },
  ];

  useEffect(() => {
    personalInfo && setPersonalInfoData(personalInfo);
  }, [personalInfo]);

  useEffect(() => {
    setPersonalInfo(personalInfoData);
    // eslint-disable-next-line
  }, [personalInfoData]);

  useEffect(() => {
    fileURL && setPersonalInfoData({ ...personalInfoData, image: fileURL });
    // eslint-disable-next-line
  }, [fileURL]);

  useEffect(() => {
    !isEdit && setPersonalInfoData(personalInfo);
    // eslint-disable-next-line
  }, [isEdit]);

  function handleChange(name, value) {
    setPersonalInfoData({ ...personalInfoData, [name]: value });
  }

  const getRanHex = (size) => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
  };

  function handleUploadPhoto(image) {
    dispatch(clearPhotoURL());
    let blob = image.slice(0, image.size, 'image/png');
    let imageName = `${getRanHex(64)}_${Date.now()}.jpg`;
    let newFile = new File([blob], imageName, { type: 'image/jpeg' });

    let formdata = new FormData();
    formdata.append('imageFile', newFile);
    dispatch(uploadPhoto({ method: 'post', url: `upload`, token: currentUser.userToken, data: formdata }));
  }

  async function sendFile(file) {
    var newFile = new File([file], `${Date.now()}.png`);
    setImage(newFile);
  }

  return (
    <>
      <Row>
        {cameraModal && (
          <CameraModalContainer>
            <CameraModal>
              <TakePhoto sendFile={sendFile} setCameraModal={setCameraModal} handleUploadPhoto={() => handleUploadPhoto(image)} image={image} setImage={setImage} />
            </CameraModal>
          </CameraModalContainer>
        )}
        {personalInfoDataList.map((item, index) => {
          return (
            <InfoContainer key={index} none={item.name === 'image' && !isEdit}>
              <InfoLabel>{item.label}</InfoLabel>
              {isEdit ? (
                <>
                  {item.type === 'file' && (
                    <InfoInput
                      type={item.type}
                      accept="image/jpeg, image/png"
                      name={item.name}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        handleUploadPhoto(e.target.files[0]);
                      }}
                    />
                  )}
                  {item.type === 'date' && (
                    <InfoInput
                      type={item.type}
                      placeholder={item.placeholder}
                      required={item.required}
                      value={moment(personalInfoData && personalInfoData[item.name] ? personalInfoData[item.name] : '1970-01-01').format('yyyy-MM-DD')}
                      name={item.name}
                      onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                  )}
                  {item.type === 'text' && (
                    <InfoInput
                      type={item.type}
                      placeholder={item.placeholder}
                      required={item.required}
                      value={personalInfoData && personalInfoData[item.name] ? personalInfoData[item.name] : ''}
                      name={item.name}
                      onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                  )}
                  {item.type === 'select' && (
                    <InfoInputSelect required={item.required} value={personalInfoData && personalInfoData[item.name] ? personalInfoData[item.name] : ''} name={item.name} onChange={(e) => handleChange(e.target.name, e.target.value)}>
                      {item.name === 'sex' && (
                        <>
                          <option value="" disabled>
                            -- Please select --
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </>
                      )}
                      {item.name === 'civilStatus' && (
                        <>
                          <option value="" disabled>
                            -- Please select --
                          </option>
                          <option value="Single">Single</option>
                          <option value="Maried">Maried</option>
                          <option value="Widowed">Widowed</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Separated">Separated</option>
                        </>
                      )}
                      {item.name === 'bloodType' && (
                        <>
                          <option value="" disabled>
                            -- Please select --
                          </option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                        </>
                      )}
                    </InfoInputSelect>
                  )}
                </>
              ) : (
                <>
                  {item.type === 'date' ? (
                    <InfoData>{moment(personalInfo?.dateOfBirth).format('MMM DD, YYYY')}</InfoData>
                  ) : (
                    <>
                      {item.name === 'firstName' || item.name === 'middleName' || item.name === 'lastName' ? (
                        <MainInfoData>{personalInfo ? personalInfo[item.name] : ''}</MainInfoData>
                      ) : (
                        <InfoData>{personalInfo ? personalInfo[item.name] : ''}</InfoData>
                      )}
                    </>
                  )}
                </>
              )}
            </InfoContainer>
          );
        })}
        {isEdit && (
          <InfoContainer>
            <InfoLabel>image</InfoLabel>
            <StartCamera
              onClick={() => {
                setCameraModal(true);
                setImage(false);
              }}
            >
              Take Picture
            </StartCamera>
          </InfoContainer>
        )}
      </Row>
    </>
  );
}

export default PersonalInfo;
