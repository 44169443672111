import React from 'react';
import { IconsContainer, DeleteIcon, ViewIcon } from '../../../../Reusables/Icons';
import { MainBar } from '../../../../Reusables/BodyAndBars';
import { ButtonSuccessSlim, ButtonPrimarySlim } from '../../../../Reusables/Buttons';
import { UserContainer, UserInfoLabel, TitlesContainer, UserHeaderTitle, UserInfoBar } from '../Users.styled';

function UserList({ refreshCount, setRefreshCount, handleCreate, handleDelete, handleView, allUserData }) {
  return (
    <>
      <MainBar noColumn={true}>
        <TitlesContainer>
          <UserHeaderTitle width={'180px'}> Full Name</UserHeaderTitle>
          <UserHeaderTitle width={'250px'} label={'EMAILADDRESS'}>
            Email Address
          </UserHeaderTitle>
          <UserHeaderTitle width={'120px'} label={'CELLPHONE'}>
            Cellphone
          </UserHeaderTitle>
          <UserHeaderTitle width={'120px'} label={'PRCLICENSE'}>
            PRC License
          </UserHeaderTitle>
          <UserHeaderTitle width={'120px'} label={'PTRNUMBER'}>
            PTR Number
          </UserHeaderTitle>
        </TitlesContainer>

        <IconsContainer>
          <ButtonSuccessSlim onClick={() => handleCreate()}>Create New User</ButtonSuccessSlim>
          <ButtonPrimarySlim onClick={() => setRefreshCount(refreshCount + 1)}>Refresh List</ButtonPrimarySlim>
        </IconsContainer>
      </MainBar>
      {allUserData &&
        allUserData.map((user, index) => (
          <UserInfoBar key={index} accessColor={(user.access === 'admin' && 'red') || (user.access === 'supervisor' && 'orange') || (user.access === 'user' && 'green')}>
            <UserContainer>
              <UserInfoLabel width={'180px'}>
                {user.personalInfo.firstName} {user.personalInfo.lastName}
              </UserInfoLabel>
              <UserInfoLabel width={'250px'} label={'EMAILADDRESS'}>
                {user.emailAddress}
              </UserInfoLabel>
              <UserInfoLabel width={'120px'} label={'CELLPHONE'}>
                {user.contactInfo.mobileNumber}
              </UserInfoLabel>
              <UserInfoLabel width={'120px'} label={'PRCLICENSE'}>
                {user.employmentRecord.prcNumber}
              </UserInfoLabel>
              <UserInfoLabel width={'120px'} label={'PTRNUMBER'}>
                {user.employmentRecord.ptrNumber}
              </UserInfoLabel>
            </UserContainer>
            <IconsContainer>
              <div title="delete" onClick={() => handleDelete(user._id)}>
                <DeleteIcon />
              </div>
              <div title="view" onClick={() => handleView(user._id)}>
                <ViewIcon />
              </div>
            </IconsContainer>
          </UserInfoBar>
        ))}
    </>
  );
}

export default UserList;
