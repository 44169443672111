import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { addCardtoChart, updateCardInChart, editCardInChart } from '../../../../../../../../config/redux/chart';
import { RiDeleteBin4Line } from 'react-icons/ri';
import moment from 'moment';

import { ChartCardInputContainer, ChartCardTitle, ChartCardBar, ChartCardBody, ChartSeparator, ChartCardBodyAccordion } from '../../ViewChart.styled';
import { PrintPageContainer, PrintBody, PrintTitle } from '../../../../../../../Reusables/PrintTemplates/PrintTemplate.styled';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  CellInput,
  CellSelect,
  CellTextArea,
  TableFoot,
  TableFooter,
  TableFooterTotal,
  BillingCardHeader,
  BillingCardHeaderItem,
  BillingCardItemRow,
  BillingCardRowItem,
  PrintBodyBillingCardItemContainer,
  BillingCardFooterTotalContainer,
  PrintBodyBillingContainer,
  BillingCardFooterTotal,
  TableRowDeleteRowIconContainer,
  BillingViewContainer,
  BillingCardItemTotal,
} from './BillingCard.styled';
import { IconsContainer, EditIcon, MaximizeIcon, MinimizeIcon, PrintIcon } from '../../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger, ButtonSuccess } from '../../../../../../../Reusables/Buttons';

import SelectBillingItem from './components/SelectBillingItem';
import AddFrames from './components/Frames';
import AddIoLenses from './components/IntraocularLenses';
import AddMedicines from './components/Medicine';
import AddProcedure from './components/Procedure';
import AddConsumables from './components/Consumables';
import AddFees from './components/Fees';

import PrintHeader from '../../../../../../../Reusables/PrintTemplates/PrintHeader';
import PrintPatientsInfo from '../../../../../../../Reusables/PrintTemplates/PatientsInfo';
import PrintFooter from '../../../../../../../Reusables/PrintTemplates/PrintFooter';

function BillingCard({ cardId, cardData, isNewCard, setAddNewCard, setHideAddCardButton, cardIndex, patientInfo, date }) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);

  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [openSelectBillingItem, setOpenSelectBillingItem] = useState(false);
  const [newBillingItem, setNewBillingItem] = useState('');
  const [billingItemList, setBillingItemList] = useState([]);
  const [total, setTotal] = useState(0);
  const [dateAndTime, setDateAndTime] = useState(moment());
  const [isPrint, setIsPrint] = useState(false);
  const unitList = ['', 'bottle', 'capsule', 'pc', 'sachet', 'softgel', 'tablet', 'tube', 'vial'];

  useEffect(() => {
    let tempTotal = 0;
    billingItemList.forEach((item) => {
      tempTotal = tempTotal + parseFloat(item.amount);
    });
    setTotal(tempTotal ? tempTotal : parseFloat(0).toFixed(2));
    // eslint-disable-next-line
  }, [billingItemList]);

  function handleAddCard() {
    setHideAddCardButton(false);
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(addCardtoChart({ method: 'post', url: `chart/addBillingCard/${cardId}`, token: currentUser.userToken, data: { cardType: 'billing_card', cardData: billingItemList } }));
  }

  function handleUpdateCard() {
    setHideAddCardButton(false);
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(updateCardInChart({ method: 'post', url: `chart/updateBillingCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardData: billingItemList } }));
  }

  function handleCancel() {
    setCardIsEdit(false);
    setAddNewCard('');
    setNewBillingItem('');
    setHideAddCardButton(false);
  }

  function handleEdit() {
    if (window.confirm('Are you sure you want to EDIT the billing statement?') === true) {
      dispatch(editCardInChart({ method: 'post', url: `chart/editBillingCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardData: billingItemList } }));
      setCardIsEdit(true);
      setHideAddCardButton(true);
      setBillingItemList(cardData);
    }
  }

  function handleDelete(index) {
    setBillingItemList(billingItemList.filter((item, i) => i !== index));
  }

  function handleBeforeGetContent() {
    setDateAndTime(moment());
    setIsPrint(true);
    return Promise.resolve();
  }

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `${patientInfo && patientInfo.lastName}_${patientInfo && patientInfo.firstName}_billing_statement`,
    onAfterPrint: () => setIsPrint(false),
  });

  function addBillingCardData(description, price, type, id, onHand) {
    billingItemList === []
      ? setBillingItemList([{ qty: 1, unit: '', description, price: price, amount: price, type, id, onHand }])
      : setBillingItemList([...billingItemList, { qty: 1, unit: '', description, price: parseFloat(price), amount: parseFloat(price), type, id, onHand }]);
  }

  function handleChange(index, name, value) {
    setBillingItemList(billingItemList.map((item, i) => (index === i ? { ...item, [name]: value } : item)));
  }

  function handleUpdateQty(index, qty, price) {
    let newUnitValue;
    let oldUnitValue = billingItemList[index].unit;
    let plural = oldUnitValue.substring(oldUnitValue.length - 1, oldUnitValue.length) === 's';
    qty === 1 || qty === '1' ? (plural ? (newUnitValue = oldUnitValue.substring(0, oldUnitValue.length - 1)) : (newUnitValue = oldUnitValue)) : plural ? (newUnitValue = oldUnitValue) : (newUnitValue = `${oldUnitValue}s`);
    setBillingItemList(billingItemList.map((item, i) => (index === i ? { ...item, qty: qty, unit: newUnitValue, amount: price * qty } : item)));
  }

  function handleUpdatePrice(index, qty, price) {
    setBillingItemList(billingItemList.map((item, i) => (index === i ? { ...item, amount: parseFloat(qty) * parseFloat(price), price: parseFloat(price) } : item)));
  }

  function getTotal(data) {
    let total = 0.0;
    data.forEach((item) => {
      total = total + parseFloat(item.amount);
    });
    return total;
  }

  return (
    <>
      {openSelectBillingItem && <SelectBillingItem setOpenSelectBillingItem={setOpenSelectBillingItem} setNewBillingItem={setNewBillingItem} addBillingCardData={addBillingCardData} />}
      <ChartCardBar>
        <ChartCardTitle>Billing Card</ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['prescription_card'] && roles[userRole]['prescription_card']['update'] && (
                <>
                  <div title="print" onClick={handlePrint}>
                    <PrintIcon />
                  </div>
                  <div title={'edit'} onClick={handleEdit}>
                    <EditIcon />
                  </div>
                </>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          {cardIsEdit || isNewCard ? (
            <TableContainer>
              {billingItemList && billingItemList.length !== 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader width={'5%'}>
                        <RiDeleteBin4Line />
                      </TableHeader>
                      <TableHeader width={'8%'}>Qty</TableHeader>
                      <TableHeader width={'12%'}>Unit</TableHeader>
                      <TableHeader width={'55%'}>Description</TableHeader>
                      <TableHeader width={'10%'}>Price</TableHeader>
                      <TableHeader width={'10%'}>Amount</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {billingItemList.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <TableRowDeleteRowIconContainer onClick={() => handleDelete(index)}>
                              <RiDeleteBin4Line />
                            </TableRowDeleteRowIconContainer>
                          </TableCell>
                          <TableCell>
                            <CellInput
                              value={item.qty}
                              type="number"
                              min="1"
                              max={item.type === 'medicine' || item.type === 'iolense' || item.type === 'consumable' ? item.onHand : item.type === 'others' ? null : 1}
                              name={'qty'}
                              onChange={(e) => {
                                handleUpdateQty(index, e.target.value, item.price);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <CellSelect
                              value={item.unit}
                              name={'unit'}
                              onChange={(e) => {
                                item.qty === '1' || item.qty === 1 ? handleChange(index, e.target.name, e.target.value) : handleChange(index, e.target.name, `${e.target.value}s`);
                              }}
                            >
                              {unitList.map((unit, index) => {
                                return (
                                  <option value={unit} key={index}>
                                    {unit}
                                  </option>
                                );
                              })}
                              {unitList.map((unit, index) => {
                                return (
                                  <option value={unit === '' ? '' : `${unit}s`} key={index} hidden={true}>
                                    {unit === '' ? '' : `${unit}s`}
                                  </option>
                                );
                              })}
                            </CellSelect>
                          </TableCell>
                          <TableCell>
                            <CellTextArea value={item.description} name={'description'} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                          </TableCell>
                          <TableCell>
                            <CellInput type="number" value={item.price} name={'price'} onChange={(e) => handleUpdatePrice(index, item.qty, e.target.value)} />
                          </TableCell>
                          <TableCell>
                            <CellInput
                              value={
                                item.amount
                                  ? parseFloat(item.amount)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : parseFloat(0).toFixed(2)
                              }
                              name={'amount'}
                              onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFoot>
                    <TableRow>
                      <TableFooter colSpan="4">Total</TableFooter>
                      <TableFooterTotal colSpan="2">
                        {'₱ '}
                        {parseFloat(total)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableFooterTotal>
                    </TableRow>
                  </TableFoot>
                </Table>
              )}
            </TableContainer>
          ) : (
            <ChartCardInputContainer width={'100%'}>
              {isPrint ? (
                <PrintPageContainer ref={componentRef}>
                  <PrintHeader />
                  <PrintTitle>BILLING STATEMENT</PrintTitle>
                  <PrintPatientsInfo patientInfo={patientInfo} date={date} />
                  <PrintBody height={'590px'}>
                    <BillingCardHeader>
                      <BillingCardHeaderItem width={'8%'}>Qty</BillingCardHeaderItem>
                      <BillingCardHeaderItem width={'12%'}>Unit</BillingCardHeaderItem>
                      <BillingCardHeaderItem width={'50%'}>Description</BillingCardHeaderItem>
                      <BillingCardHeaderItem width={'15%'}>Price</BillingCardHeaderItem>
                      <BillingCardHeaderItem width={'15%'}>Amount</BillingCardHeaderItem>
                    </BillingCardHeader>
                    <PrintBodyBillingContainer>
                      <PrintBodyBillingCardItemContainer>
                        {cardData &&
                          cardData.map((item, index) => {
                            return (
                              <BillingCardItemRow key={index}>
                                <BillingCardRowItem width={'8%'}>{item.qty}</BillingCardRowItem>
                                <BillingCardRowItem width={'12%'}>{item.unit}</BillingCardRowItem>
                                <BillingCardRowItem width={'50%'}>{item.description}</BillingCardRowItem>
                                <BillingCardRowItem width={'15%'}>
                                  {parseFloat(item.price)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </BillingCardRowItem>
                                <BillingCardRowItem width={'15%'}>
                                  {parseFloat(item.amount)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </BillingCardRowItem>
                              </BillingCardItemRow>
                            );
                          })}
                      </PrintBodyBillingCardItemContainer>
                      <BillingCardFooterTotalContainer>
                        <BillingCardFooterTotal width={'70%'}> TOTAL</BillingCardFooterTotal>
                        <BillingCardFooterTotal width={'30%'}>
                          {'₱ '}
                          {getTotal(cardData)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </BillingCardFooterTotal>
                      </BillingCardFooterTotalContainer>
                    </PrintBodyBillingContainer>
                  </PrintBody>
                  <PrintFooter dateAndTime={dateAndTime} />
                </PrintPageContainer>
              ) : (
                <BillingViewContainer>
                  <BillingCardHeader>
                    <BillingCardHeaderItem width={'8%'}>Qty</BillingCardHeaderItem>
                    <BillingCardHeaderItem width={'12%'}>Unit</BillingCardHeaderItem>
                    <BillingCardHeaderItem width={'50%'}>Description</BillingCardHeaderItem>
                    <BillingCardHeaderItem width={'15%'}>Price</BillingCardHeaderItem>
                    <BillingCardHeaderItem width={'15%'}>Amount</BillingCardHeaderItem>
                  </BillingCardHeader>
                  {cardData &&
                    cardData.map((item, index) => {
                      return (
                        <BillingCardItemRow key={index}>
                          <BillingCardRowItem width={'8%'}>{item.qty}</BillingCardRowItem>
                          <BillingCardRowItem width={'12%'}>{item.unit}</BillingCardRowItem>
                          <BillingCardRowItem width={'50%'}>{item.description}</BillingCardRowItem>
                          <BillingCardRowItem width={'15%'}>
                            {parseFloat(item.price)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </BillingCardRowItem>
                          <BillingCardRowItem width={'15%'}>
                            {parseFloat(item.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </BillingCardRowItem>
                        </BillingCardItemRow>
                      );
                    })}
                  <BillingCardItemTotal>
                    <span>Total</span>
                    <span>
                      {'₱ '}
                      {getTotal(cardData)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </BillingCardItemTotal>
                </BillingViewContainer>
              )}
            </ChartCardInputContainer>
          )}
          {newBillingItem === 'frames' && <AddFrames addBillingCardData={addBillingCardData} setNewBillingItem={setNewBillingItem} />}
          {newBillingItem === 'ioLenses' && <AddIoLenses addBillingCardData={addBillingCardData} setNewBillingItem={setNewBillingItem} />}
          {newBillingItem === 'medicine' && <AddMedicines addBillingCardData={addBillingCardData} setNewBillingItem={setNewBillingItem} />}
          {newBillingItem === 'procedure' && <AddProcedure addBillingCardData={addBillingCardData} setNewBillingItem={setNewBillingItem} />}
          {newBillingItem === 'consumables' && <AddConsumables addBillingCardData={addBillingCardData} setNewBillingItem={setNewBillingItem} />}
          {newBillingItem === 'fees' && <AddFees addBillingCardData={addBillingCardData} setNewBillingItem={setNewBillingItem} />}
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonSuccess onClick={() => setOpenSelectBillingItem(true)}>Add Billing Item</ButtonSuccess>
          {!cardIsEdit && <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>}
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default BillingCard;
