import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchConsumable } from '../../../../../../../../../config/redux/inventory';

import { ButtonDanger, ButtonPrimary } from '../../../../../../../../Reusables/Buttons';
import { SearchResultContainer } from '../../../ViewChart.styled';
import { AddProcedureContainer, AddProcedureHeaderContainer, AddProcedureHeader, SearchInputField, SearchBoxContainer, MedicineSearchResultHeader, MedicineHeader, MedicineSearchResult, MedicineSearchResultItem } from '../BillingCard.styled';

function AddConsumables({ setNewBillingItem, addBillingCardData }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const consumableList = useSelector((state) => state.inventory && state.inventory.consumableList);
  const [itemName, setItemName] = useState('');
  const [added, setAdded] = useState(0);
  const consumablesHeader = [
    { name: 'Item Name', id: 'itemName', width: '40%', align: 'left' },
    { name: 'On Hand', id: 'onHand', width: '40%', align: 'left' },
    { name: 'Price', id: 'price', width: '20%', align: 'left' },
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(searchConsumable({ method: 'get', url: `consumables/search?itemName=${itemName}`, token: currentUser.userToken }));
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [itemName]);

  function handleAddConsumable(consumable) {
    setItemName('');
    addBillingCardData(`${consumable.itemName}`, consumable.price, 'consumable', consumable._id, consumable.onHand);
    setAdded(added + 1);
  }

  return (
    <AddProcedureContainer>
      <AddProcedureHeaderContainer>
        <AddProcedureHeader width={'100%'}>Add Frames</AddProcedureHeader>
      </AddProcedureHeaderContainer>
      <SearchBoxContainer>
        <SearchInputField value={itemName} onChange={(e) => setItemName(e.target.value)} placeholder={'Search by item number'} />
        {added !== 0 ? <ButtonPrimary onClick={() => setNewBillingItem('')}>Done</ButtonPrimary> : <ButtonDanger onClick={() => setNewBillingItem('')}>Cancel</ButtonDanger>}
      </SearchBoxContainer>
      <SearchResultContainer>
        <MedicineSearchResultHeader>
          {consumablesHeader.map((item, index) => {
            return (
              <MedicineHeader key={index} width={item.width}>
                {item.name}
              </MedicineHeader>
            );
          })}
        </MedicineSearchResultHeader>
        {consumableList &&
          consumableList.map((consumable, index) => {
            return (
              <MedicineSearchResult key={index} onClick={() => handleAddConsumable(consumable)}>
                {consumablesHeader.map((item, i) => {
                  return (
                    <MedicineSearchResultItem key={i} width={item.width} align={item.align} shortage={item.id === 'onHand' && consumable.onHand < consumable.minQty}>
                      {consumable[item.id]}
                    </MedicineSearchResultItem>
                  );
                })}
              </MedicineSearchResult>
            );
          })}
      </SearchResultContainer>
    </AddProcedureContainer>
  );
}

export default AddConsumables;
