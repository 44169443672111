import styled from 'styled-components/macro';

export const FooterContainer = styled.footer`
  text-align: center;
  font-size: 0.8rem;
  color: #777777;
  padding-bottom: 10px;
`;

export const FooterText = styled.p`
  margin: 15px;
`;
