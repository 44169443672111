import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers, deleteUserById } from '../../../../config/redux/user';
import { setUserPage, setUserId } from '../../../../config/redux/settings';

import { MainPageContainer } from '../../../Reusables/BodyAndBars';

import UserList from './components/UserList';
import CreateUser from './components/CreateUser';
import UserProfile from './components/UserProfile';

function Users() {
  const dispatch = useDispatch();
  const allUserData = useSelector((state) => state.user.userData && state.user.allUserData);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userPage = useSelector((state) => state.settings && state.settings.userPage);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    dispatch(getAllUsers({ method: 'get', url: 'user/all', token: currentUser.userToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to DELETE this user?') === true) {
      dispatch(deleteUserById({ method: 'delete', url: `user/${id}`, token: currentUser.userToken }));
    } else {
      console.log('User delete cancelled.');
    }
  };

  const handleView = (id) => {
    dispatch(setUserPage('userProfilePage'));
    dispatch(setUserId(id));
  };
  return (
    <MainPageContainer>
      {userPage === 'userListPage' && (
        <UserList handleCreate={() => dispatch(setUserPage('createUserPage'))} handleView={handleView} handleDelete={handleDelete} setRefreshCount={setRefreshCount} allUserData={allUserData} refreshCount={refreshCount} />
      )}
      {userPage === 'createUserPage' && (
        <CreateUser
          handleCancel={() => dispatch(setUserPage('userListPage'))}
          back={() => {
            dispatch(setUserPage('userListPage'));
          }}
          setRefreshCount={setRefreshCount}
          refreshCount={refreshCount}
        />
      )}
      {userPage === 'userProfilePage' && <UserProfile handleBack={() => dispatch(setUserPage('userListPage'))} />}
    </MainPageContainer>
  );
}

export default Users;
