import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserById, updateUserProfile } from '../../../../../config/redux/user';

import { UserHeaderTitle, BottomButtonContainer } from '../Users.styled';
import { MainBar, TitlesContainer, PageContainer } from '../../../../Reusables/BodyAndBars';
import { RefreshIconWhite, EditIconWhite, BackIconWhite, IconsContainer } from '../../../../Reusables/Icons';
import { ButtonSuccess, ButtonDanger } from '../../../../Reusables/Buttons';

import ViewProfile from '../../../../Reusables/ViewProfile/ViewProfile';

function UserProfile({ handleBack }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userData = useSelector((state) => state.user.userData && state.user.userData);
  const userId = useSelector((state) => state.settings && state.settings.userId);
  const [refreshCount, setRefreshCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [displayName, setDisplayName] = useState('User Profile');
  const [updatedUserData, setUpdatedUserData] = useState({});

  useEffect(() => {
    dispatch(getUserById({ method: 'get', url: `user/${userId}`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [refreshCount]);

  useEffect(() => {
    let first = userData && userData.personalInfo && userData.personalInfo.firstName;
    let last = userData && userData.personalInfo && userData.personalInfo.lastName;
    let completeName = first + ' ' + last;
    setDisplayName(completeName);
  }, [userData]);

  function handleSave(e) {
    e.preventDefault();
    setIsEdit(false);
    dispatch(
      updateUserProfile({
        method: 'post',
        url: `user/update/${userData._id}`,
        token: currentUser.userToken,
        data: updatedUserData,
      })
    );
  }

  return (
    <>
      <MainBar noColumn={true}>
        <TitlesContainer>
          <UserHeaderTitle>{displayName}</UserHeaderTitle>
        </TitlesContainer>
        <IconsContainer>
          {!isEdit && (
            <>
              <div
                title="back"
                onClick={() => {
                  setDisplayName('User Profile');
                  handleBack();
                }}
              >
                <BackIconWhite />
              </div>
              <div title="refresh" onClick={() => setRefreshCount(refreshCount + 1)}>
                <RefreshIconWhite />
              </div>
              <div title="edit" onClick={() => setIsEdit(true)}>
                <EditIconWhite />
              </div>
            </>
          )}
        </IconsContainer>
      </MainBar>
      <form onSubmit={(e) => handleSave(e)}>
        <PageContainer>
          <ViewProfile userData={userData} isEdit={isEdit} setUpdatedUserData={setUpdatedUserData} type={'user'} currentUserAccess={currentUser.userAccess} />
        </PageContainer>
        {isEdit && (
          <BottomButtonContainer>
            <ButtonDanger onClick={() => setIsEdit(false)}>Cancel</ButtonDanger>
            <ButtonSuccess type={'submit'}>Save</ButtonSuccess>
          </BottomButtonContainer>
        )}
      </form>
    </>
  );
}

export default UserProfile;
