import React from 'react';
import moment from 'moment';

import { IconsContainer, SaveIcon, CancelIcon, AddFolderIcon, AddOneFile } from '../../../../../Reusables/Icons';
import { RiDeleteBin4Line, RiEdit2Line, RiSaveLine } from 'react-icons/ri';
import { MdOutlineCancel } from 'react-icons/md';
import { CardBar, TitlesContainer } from '../../../../../Reusables/BodyAndBars';
import { ButtonDangerSlim } from '../../../../../Reusables/Buttons';
import { InventoryCardBody, InventoryHeaderList, ProductListContainer, ItemInput, InlineIconContainer, HeaderLabelContainer, HeaderLabel, ItemNameContainer, ItemName } from '../../Inventory.styled';

function Table({
  initialState,
  headerName,
  headerList,
  tableWidth,
  itemList,
  updatedItemList,
  setCsvFile,
  handleCancel,
  handleOnChange,
  handleOnChangeAddNewList,
  handleDeleteNewList,
  handleSave,
  handleUpload,
  setIsUpload,
  isUpload,
  editData,
  setEditData,
  isAddList,
  setIsAddList,
  addNewList,
  setAddNewList,
  deleteItem,
  updateItem,
}) {
  return (
    <>
      <CardBar>
        <TitlesContainer padding={'5px 0px 0px 0px'}>{headerName}</TitlesContainer>
        <IconsContainer>
          {isUpload ? (
            <>
              <ButtonDangerSlim onClick={() => handleUpload()}>Upload</ButtonDangerSlim>
              <div title={'cancel'} onClick={() => handleCancel()}>
                <CancelIcon />
              </div>
            </>
          ) : (
            <>
              {isAddList ? (
                <>
                  <div title={'save'} onClick={() => handleSave()}>
                    <SaveIcon />
                  </div>
                  <div title={'cancel'} onClick={() => handleCancel()}>
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <>
                  {headerName !== 'Other Fees' && (
                    <label htmlFor="fileSelect" title={'add bulk data'}>
                      <AddFolderIcon />
                    <input id="fileSelect" type="file" accept=".csv" hidden onChange={(e) => setCsvFile(e.target.files[0])} />
                  </label>
                  )}
                </>
                
              )}
              <div
                title={'add one entry'}
                onClick={() => {
                  isAddList ? setAddNewList([...addNewList, initialState]) : setAddNewList([initialState]);
                  setIsAddList(true);
                  setIsUpload(false);
                }}
              >
                <AddOneFile />
              </div>
            </>
          )}
        </IconsContainer>
      </CardBar>
      <InventoryCardBody overflow={'auto'}>
        <InventoryHeaderList minWidth={tableWidth}>
        {headerName === 'Medicine' && (
          <HeaderLabelContainer width={'90px'}>
          <HeaderLabel>Actions</HeaderLabel>
        </HeaderLabelContainer>
        )}
          
          {headerList.map((item, index) => {
            return (
              <HeaderLabelContainer key={index} width={item.width} first={item.first}>
                <HeaderLabel>{item.id}</HeaderLabel>
              </HeaderLabelContainer>
            );
          })}
          <HeaderLabelContainer width={'90px'}>
            <HeaderLabel>Actions</HeaderLabel>
          </HeaderLabelContainer>
        </InventoryHeaderList>
        {isAddList && (
          <>
            {addNewList.map((itemRow, index) => {
              return (
                <ProductListContainer key={index} isEdit={true} minWidth={tableWidth}>
                  {headerName === 'Medicine' && (
                    <ItemNameContainer width={'90px'}>
                      <InlineIconContainer title="delete item from list" onClick={() => handleDeleteNewList(index)}>
                        <RiDeleteBin4Line />
                      </InlineIconContainer>
                  </ItemNameContainer>
                  )}
                  
                  {headerList.map((item, i) => {
                    return (
                      <ItemNameContainer key={i} width={item.width} justifyCenter={item.type !== 'text'} isEdit={true}>
                        <ItemInput
                          type={item.type}
                          width={item.width}
                          justifyCenter={item.type !== 'text'}
                          last={item.type !== 'number'}
                          name={item.id}
                          value={item.type === 'date' ? moment(itemRow[item.id]).format('yyyy-MM-DD') : itemRow[item.id]}
                          onChange={(e) => handleOnChangeAddNewList(index, e.target.name, e.target.value)}
                        />
                      </ItemNameContainer>
                    );
                  })}
                  <ItemNameContainer width={'90px'}>
                    <InlineIconContainer title="delete item from list" onClick={() => handleDeleteNewList(index)}>
                      <RiDeleteBin4Line />
                    </InlineIconContainer>
                  </ItemNameContainer>
                </ProductListContainer>
              );
            })}
          </>
        )}

        <>
          {updatedItemList &&
            updatedItemList.map((itemRow, index) => {
              return (
                <ProductListContainer key={index} minWidth={tableWidth} notAdded={true}>
                  {headerName === 'Medicine' && <ItemNameContainer width={'90px'}></ItemNameContainer>}
                  {headerList.map((item, i) => {
                    return (
                      <ItemNameContainer key={i} width={item.width} justifyCenter={item.type !== 'text'}>
                        {item.type === 'date' ? <ItemName>{moment(itemRow[item.id]).format('MMM yyyy')}</ItemName> : <ItemName>{itemRow[item.id]}</ItemName>}
                      </ItemNameContainer>
                    );
                  })}
                </ProductListContainer>
              );
            })}
          {itemList &&
            itemList.map((itemRow, index) => {
              return (
                <ProductListContainer key={index} minWidth={tableWidth} isEdit={editData._id === itemRow._id}>
                  {headerName === 'Medicine' && (
                    <ItemNameContainer width={'90px'}>
                    {editData._id === itemRow._id ? (
                      <>
                        <InlineIconContainer title="cancel" onClick={() => setEditData({})}>
                          <MdOutlineCancel />
                        </InlineIconContainer>
                        <InlineIconContainer title="delete" onClick={() => deleteItem(editData._id)}>
                          <RiDeleteBin4Line />
                        </InlineIconContainer>
                        <InlineIconContainer title="save" onClick={() => updateItem()}>
                          <RiSaveLine />
                        </InlineIconContainer>
                      </>
                    ) : (
                      <InlineIconContainer title="edit" onClick={() => setEditData(itemRow)}>
                        <RiEdit2Line />
                      </InlineIconContainer>
                    )}
                  </ItemNameContainer>
                  )}
                  
                  {headerList.map((item, i) => {
                    return (
                      <ItemNameContainer key={i} width={item.width} justifyCenter={item.type !== 'text'} isEdit={editData._id === itemRow._id}>
                        {editData._id === itemRow._id ? (
                          <ItemInput
                            type={item.type}
                            width={item.width}
                            justifyCenter={item.type !== 'text'}
                            last={item.type !== 'number'}
                            name={item.id}
                            value={editData.type === 'date' ? moment(editData[item.id]).format('yyyy-MM-DD') : editData[item.id]}
                            onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                          />
                        ) : (
                          <>{item.type === 'date' ? <ItemName>{moment(itemRow[item.id]).format('MMM yyyy')}</ItemName> : <ItemName>{i === 0 ? `${index + 1}. ${itemRow[item.id]}` : itemRow[item.id]}</ItemName>}</>
                        )}
                      </ItemNameContainer>
                    );
                  })}
                  <ItemNameContainer width={'90px'}>
                    {editData._id === itemRow._id ? (
                      <>
                        <InlineIconContainer title="cancel" onClick={() => setEditData({})}>
                          <MdOutlineCancel />
                        </InlineIconContainer>
                        <InlineIconContainer title="delete" onClick={() => deleteItem(editData._id)}>
                          <RiDeleteBin4Line />
                        </InlineIconContainer>
                        <InlineIconContainer title="save" onClick={() => updateItem()}>
                          <RiSaveLine />
                        </InlineIconContainer>
                      </>
                    ) : (
                      <InlineIconContainer title="edit" onClick={() => setEditData(itemRow)}>
                        <RiEdit2Line />
                      </InlineIconContainer>
                    )}
                  </ItemNameContainer>
                </ProductListContainer>
              );
            })}
        </>
      </InventoryCardBody>
    </>
  );
}

export default Table;
