import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDataList } from '../config/redux/dataList';
import { setDoctorFilter, getSettings } from '../config/redux/settings';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import MainBody from './Body/MainBody';
import PageLoading from './Loading';

function LoggedIn() {
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user && state.user.status);
  const dataListStatus = useSelector((state) => state.dataList && state.dataList.status);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const patientStatus = useSelector((state) => state.patient && state.patient.status);
  const settingsStatus = useSelector((state) => state.settings && state.settings.status);
  const inventoryStatus = useSelector((state) => state.inventory && state.inventory.status);
  const doctorFilter = useSelector((state) => state.settings && state.settings.doctorFilter);

  useEffect(() => {
    dispatch(getDataList({ method: 'get', url: 'datalist/all', token: currentUser.userToken }));
    doctorFilter === 'not set' && dispatch(setDoctorFilter(currentUser.isDoctor ? currentUser.userId : ''));
    dispatch(getSettings({ method: 'get', url: 'settings', token: currentUser.userToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {userStatus === 'loading' && <PageLoading />}
      {dataListStatus === 'loading' && <PageLoading />}
      {patientStatus === 'loading' && <PageLoading />}
      {settingsStatus === 'loading' && <PageLoading />}
      {inventoryStatus === 'loading' && <PageLoading />}
      <Header />
      <MainBody />
      <Footer />
    </div>
  );
}

export default LoggedIn;
