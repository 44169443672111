import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';

export const getDataList = createAsyncThunk('users/getDataList', async (action) => {
  return await apiRequest(action);
});

export const getClinicSiteList = createAsyncThunk('users/getClinicSiteList', async (action) => {
  return await apiRequest(action);
});

export const updateDataList = createAsyncThunk('users/updateDataList', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  dataList: [],
  clinicSiteList: [],
  status: 'idle',
  error: null,
};

export const dataListSlice = createSlice({
  name: 'dataList',
  initialState,
  reducers: {
    clearDataList: (state, action) => {
      state.dataList = [];
    },
  },
  extraReducers(builder) {
    builder

      // Get data list
      .addCase(getDataList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dataList = action.payload.data[0];
      })
      .addCase(getDataList.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // Get clinic site list
      .addCase(getClinicSiteList.fulfilled, (state, action) => {
        state.clinicSiteList = action.payload.data;
      })

      // update data list
      .addCase(updateDataList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateDataList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log(action.payload.data);
        state.dataList = action.payload.data;
      })
      .addCase(updateDataList.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        console.log(action.payload.data);
        state.status = 'failed';
        console.log(action.payload);
      });
  },
});

export const { clearDataList } = dataListSlice.actions;
export default dataListSlice.reducer;
