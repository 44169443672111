import React, { useState } from 'react';

import { IconsContainer, HamburgerIconWhite } from '../../../Reusables/Icons';
import { MainPageContainer, PageContainer, MainBar, TitlesContainer, SubHeaderTitleNav, HamburgerNavContainer, HamburgerIcon, HamburgerNav } from '../../../Reusables/BodyAndBars';

import Medicines from './components/Medicines';
import IntraOcularLenses from './components/IntraOcularLenses';
import Consumables from './components/Consumables';
import Frames from './components/Frames';
import Fees from './components/Fees';

function Inventory() {
  const [currentPage, setCurrentPage] = useState('medicines');
  const [isHide, setIsHide] = useState(true);
  const inventoryPageList = [
    { headerName: 'Medicines', currentPage: 'medicines' },
    { headerName: 'Intraocular Lenses', currentPage: 'iol' },
    { headerName: 'Consumables', currentPage: 'consumables' },
    { headerName: 'Frames', currentPage: 'frames' },
    { headerName: 'Fees', currentPage: 'fees' },
  ];

  return (
    <MainPageContainer>
      <MainBar>
        <TitlesContainer>
          {inventoryPageList.map((data, index) => {
            return (
              <SubHeaderTitleNav key={index} onClick={() => setCurrentPage(data.currentPage)} active={currentPage === data.currentPage}>
                {data.headerName}
              </SubHeaderTitleNav>
            );
          })}
          <HamburgerIcon
            onClick={() => {
              setIsHide(!isHide);
            }}
          >
            <HamburgerIconWhite />
          </HamburgerIcon>

          <HamburgerNavContainer maxHeight={isHide ? '0px' : '300px'}>
            {inventoryPageList.map((data, index) => {
              return (
                <HamburgerNav
                  key={index}
                  onClick={() => {
                    setCurrentPage(data.currentPage);
                    setIsHide(true);
                  }}
                  active={currentPage === data.currentPage}
                >
                  {data.headerName}
                </HamburgerNav>
              );
            })}
          </HamburgerNavContainer>
        </TitlesContainer>
        <IconsContainer></IconsContainer>
      </MainBar>
      <PageContainer>
        {currentPage === 'medicines' && <Medicines />}
        {currentPage === 'iol' && <IntraOcularLenses />}
        {currentPage === 'consumables' && <Consumables />}
        {currentPage === 'frames' && <Frames />}
        {currentPage === 'fees' && <Fees />}
      </PageContainer>
    </MainPageContainer>
  );
}

export default Inventory;
