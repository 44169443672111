import React from 'react';
import moment from 'moment/moment';

import { PatientInfoContainer, PatientInfoRow, PatientDetails } from './PrintTemplate.styled';
import { getAge } from '../../Body/components/Charts/components/ChartList';

function PatientsInfo({ patientInfo, date }) {
  let name = patientInfo.middleName ? (patientInfo.firstName + ' ' + patientInfo.middleName + ' ' + patientInfo.lastName).toUpperCase() : (patientInfo.firstName + ' ' + patientInfo.lastName).toUpperCase();
  return (
    <PatientInfoContainer>
      <PatientInfoRow>
        <PatientDetails>
          {`Name: `} <b>{name}</b>
        </PatientDetails>
        <PatientDetails>{`Date: ${moment(date).format('MMM DD, yyyy')}`}</PatientDetails>
      </PatientInfoRow>
      <PatientInfoRow>
        <PatientDetails>{`Date of Birth: ${moment(patientInfo.dateOfBirth).format('MMM DD, yyyy')}`}</PatientDetails>
        <PatientDetails>{`Age: ${getAge(patientInfo.dateOfBirth)}`}</PatientDetails>
        <PatientDetails>{`Sex: ${patientInfo.sex}`}</PatientDetails>
      </PatientInfoRow>
    </PatientInfoContainer>
  );
}

export default PatientsInfo;
