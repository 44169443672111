import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserById } from '../../../../config/redux/user';
import { setCurrentPage } from '../../../../config/redux/settings';
import Address from './Address';

import { Row, InfoContainer, InfoLabel, InfoData, InfoInput, BtnContainer } from '../ViewProfile.styled';
import { ButtonDangerSlim } from '../../Buttons';

function ContactInfo({ userId, contactInfo, isEdit, emailAddress, setContactInfo, type }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const [contactInfoData, setContactInfoData] = useState({});
  const [currentAddress, setCurrentAddress] = useState({});
  const [provincialAddress, setProvincialAddress] = useState({});

  useEffect(() => {
    isEdit && setContactInfoData(contactInfo ? contactInfo : {});
    isEdit && setCurrentAddress(contactInfo && contactInfo.currentAddress ? contactInfo.currentAddress : {});
    isEdit && setProvincialAddress(contactInfo && contactInfo.provincialAddress ? contactInfo.provincialAddress : {});
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setContactInfo({ ...contactInfoData, currentAddress, provincialAddress });
    // eslint-disable-next-line
  }, [contactInfoData, currentAddress, provincialAddress]);

  const handleChange = (name, value) => {
    setContactInfoData({ ...contactInfoData, [name]: value });
  };

  const HandleResetPassword = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure to want to create a temporary password?') === true) {
      let newPassword = prompt('Enter Temporary Password');
      newPassword && dispatch(getUserById({ method: 'post', url: `user/forgotPassword/${userId}`, token: currentUser.userToken, data: { newPassword } }));
      dispatch(setCurrentPage('Charts'));
    }
  };

  return (
    <>
      <Row>
        <InfoContainer width={'98.5%'}>
          <InfoLabel>Email Address:</InfoLabel>
          {(type === 'profile' || type === 'user') && <InfoData>{emailAddress}</InfoData>}
          {type === 'patient' && !isEdit && <InfoData>{contactInfo && contactInfo.emailAddress ? contactInfo.emailAddress : ''}</InfoData>}
          {type === 'patient' && isEdit && (
            <InfoInput type={'email'} value={contactInfoData && contactInfoData.emailAddress ? contactInfoData.emailAddress : ''} name={'emailAddress'} onChange={(e) => handleChange(e.target.name, e.target.value)}></InfoInput>
          )}
        </InfoContainer>
      </Row>
      <Row>
        <InfoContainer>
          <InfoLabel>Mobile Number:</InfoLabel>
          {isEdit ? (
            <InfoInput value={contactInfoData && contactInfoData.mobileNumber ? contactInfoData.mobileNumber : ''} name={'mobileNumber'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
          ) : (
            <InfoData>{contactInfo && contactInfo.mobileNumber ? contactInfo.mobileNumber : ''}</InfoData>
          )}
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>Phone Number:</InfoLabel>
          {isEdit ? (
            <InfoInput value={contactInfoData && contactInfoData.phoneNumber ? contactInfoData.phoneNumber : ''} name={'phoneNumber'} onChange={(e) => handleChange(e.target.name, e.target.value)} />
          ) : (
            <InfoData>{contactInfo && contactInfo.phoneNumber ? contactInfo.phoneNumber : ''}</InfoData>
          )}
        </InfoContainer>

        {isEdit && currentUser.userId !== userId && (
          <InfoContainer>
            <BtnContainer>
              <ButtonDangerSlim onClick={HandleResetPassword}>Temporary Password</ButtonDangerSlim>
            </BtnContainer>
          </InfoContainer>
        )}
      </Row>
      <Address isEdit={isEdit} address={contactInfo && contactInfo.currentAddress ? contactInfo.currentAddress : {}} newAddress={currentAddress} setNewAddress={setCurrentAddress} SubBarLabel={'Current Address'} />
      <Address isEdit={isEdit} address={contactInfo && contactInfo.provincialAddress ? contactInfo.provincialAddress : {}} newAddress={provincialAddress} setNewAddress={setProvincialAddress} SubBarLabel={'Provincial Address'} />
    </>
  );
}

export default ContactInfo;
