import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIolList } from '../../../../../../../../../config/redux/inventory';

import { ButtonDanger, ButtonSuccess, ButtonPrimary } from '../../../../../../../../Reusables/Buttons';
import { SearchResultContainer } from '../../../ViewChart.styled';
import { AddProcedureContainer, AddProcedureHeaderContainer, AddProcedureHeader, AddProcedureSearchContainer, SearchInputField, MedicineSearchResultHeader, MedicineHeader, MedicineSearchResult, MedicineSearchResultItem } from '../BillingCard.styled';

function AddIoLenses({ setNewBillingItem, addBillingCardData }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const iolList = useSelector((state) => state.inventory && state.inventory.iolList);
  const [searchBrandValue, setSearchBrandValue] = useState('');
  const [searchPowerValue, setSearchPowerValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [added, setAdded] = useState(0);
  const ioLensesHeader = [
    { name: 'Brand Name', id: 'brandName', width: '60%', align: 'left' },
    { name: 'Power', id: 'power', width: '20%', align: 'center' },
    { name: 'On Hand', id: 'onHand', width: '10%', align: 'center' },
    { name: 'Price', id: 'price', width: '10%', align: 'center' },
  ];

  useEffect(() => {
    dispatch(getIolList({ method: 'get', url: `iol`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchResult(iolList);
    // eslint-disable-next-line
  }, [iolList]);

  useEffect(() => {
    if (searchBrandValue && !searchPowerValue) {
      setSearchResult(iolList.filter((item) => item.brandName.toLowerCase().includes(searchBrandValue.toLowerCase())));
    } else if (!searchBrandValue && searchPowerValue) {
      setSearchResult(iolList.filter((item) => item.power === searchPowerValue));
    } else if (searchBrandValue && searchPowerValue) {
      setSearchResult(iolList.filter((item) => item.brandName.toLowerCase().includes(searchBrandValue.toLowerCase()) && item.power === searchPowerValue));
    } else {
      setSearchResult(iolList);
    }
    // eslint-disable-next-line
  }, [searchBrandValue, searchPowerValue]);

  function handleAddIoLense(iolense) {
    setSearchBrandValue('');
    setSearchPowerValue('');
    addBillingCardData(`${iolense.brandName} (Power = ${iolense.power})`, iolense.price, 'iolense', iolense._id, iolense.onHand);
    setAdded(added + 1);
  }

  return (
    <>
      <AddProcedureContainer>
        <AddProcedureHeaderContainer>
          <AddProcedureHeader width={'100%'}>Add Intraocular Lenses</AddProcedureHeader>
        </AddProcedureHeaderContainer>
        <AddProcedureSearchContainer>
          <SearchInputField value={searchBrandValue} onChange={(e) => setSearchBrandValue(e.target.value)} placeholder={'Search Brand'} />
          <SearchInputField value={searchPowerValue} onChange={(e) => setSearchPowerValue(e.target.value)} placeholder={'Search Power'} />
          <div>
            <ButtonSuccess
              onClick={() => {
                setSearchBrandValue('');
                setSearchPowerValue('');
              }}
            >
              Clear
            </ButtonSuccess>
            {added !== 0 ? <ButtonPrimary onClick={() => setNewBillingItem('')}>Done</ButtonPrimary> : <ButtonDanger onClick={() => setNewBillingItem('')}>Cancel</ButtonDanger>}
          </div>
        </AddProcedureSearchContainer>
        <SearchResultContainer>
          <MedicineSearchResultHeader>
            {ioLensesHeader.map((item, index) => {
              return (
                <MedicineHeader key={index} width={item.width}>
                  {item.name}
                </MedicineHeader>
              );
            })}
          </MedicineSearchResultHeader>
          {searchResult &&
            searchResult.map((ioLense, index) => {
              return (
                <MedicineSearchResult key={index} onClick={() => handleAddIoLense(ioLense)}>
                  {ioLensesHeader.map((item, i) => {
                    return (
                      <MedicineSearchResultItem key={i} width={item.width} align={item.align} shortage={item.id === 'onHand' && ioLense.onHand < ioLense.minQty}>
                        {ioLense[item.id]}
                      </MedicineSearchResultItem>
                    );
                  })}
                </MedicineSearchResult>
              );
            })}
        </SearchResultContainer>
      </AddProcedureContainer>
    </>
  );
}

export default AddIoLenses;
