import styled from 'styled-components/macro';

export const MainPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  min-height: 85vh;
  overflow: hidden;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: z ${({ theme }) => theme.MainBackground};

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`;

export const TitlesContainer = styled.div`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : '0px')};
`;

export const MainBar = styled.div`
  padding: 6px 5px 6px 6px;
  background-color: ${({ theme }) => theme.MainNav};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  display: flex;
  flex-direction: row;
  min-height: 43px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: ${(props) => (props.noColumn ? 'row' : 'column')};
  }
`;

export const CardContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  width: ${(props) => (props.width ? props.width : '50%')};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin: 5px;
  overflow-x: ${(props) => (props.scrollable ? 'scroll' : 'hidden')};

  @media (max-width: 992px) {
    width: 98%;
  }
`;

export const CardBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border-radius: 5px 5px 0px 0px;
  min-height: 30px;
  padding: 3px 10px;
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'space-between')};
`;

export const CardHeader = styled.div`
  padding-top: 5px;
  font-size: 1rem;
`;

export const CardBody = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px;
  width: 100%;
  overflow-y: ${(props) => (props.overflowY ? props.overflowY : 'hidden')};
`;

export const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 5px;
  margin: 1px;
  width: 33%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 2px;
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 3px;
  padding-left: 6px;
  font-size: 0.9rem;
  width: 100%;
`;

export const InfoInputSelect = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 2px;
  font-size: 0.9rem;
  width: 100%;
`;

export const SubHeaderTitle = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 0.8rem;
  padding-top: 7px;
  padding-left: 10px;
`;

export const SubHeaderTitleNav = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? 'orange' : ({ theme }) => theme.CardFontLight)};
  ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 0.8rem;
  padding: 6px 10px 1px 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: ${(props) => (props.active ? 'orange' : ({ theme }) => theme.CardFontLight)};
  }

  @media (max-width: 992px) {
    display: none;
    background: red;
  }
`;

export const HamburgerNavContainer = styled.nav`
  display: block;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: ${(props) => props.top || '92px'};
  left: ${(props) => props.left || '1px'};
  max-height: ${(props) => props.maxHeight || '300px'};
  transition: max-height 0.5s ease-in-out;
`;

export const HamburgerIcon = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`;

export const HamburgerNav = styled.div`
  text-align: left;
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 8px 15px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 5px solid ${(props) => (props.active ? 'orange' : props.theme.MainNav)};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

export const LoadingContainer = styled.div`
  position: relative;
`;

export const Loading = styled.div`
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  z-index: 10;
  border: none;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
