import { configureStore } from '@reduxjs/toolkit';

import chartSlice from './chart';
import userSlice from './user';
import settingsSlice from './settings';
import dataListSlice from './dataList';
import patientSlice from './patients';
import inventorySlice from './inventory';
import filesSlice from './files';

const store = configureStore({
  reducer: {
    chart: chartSlice,
    user: userSlice,
    settings: settingsSlice,
    dataList: dataListSlice,
    patient: patientSlice,
    inventory: inventorySlice,
    files: filesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
