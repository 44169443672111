import styled from 'styled-components/macro';

export const InventoryCardBody = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px;
  width: 100%;
  overflow: ${(props) => (props.overflow ? props.overflow : 'hidden')};
`;

export const InventoryHeaderList = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  padding: 5px;
  min-width: ${(props) => props.minWidth};
  height: 30px;
  background-color: lightgrey;
`;

export const HeaderLabelContainer = styled.div`
  width: ${(props) => props.width};
  text-align: center;
  border-right: ${(props) => (props.last ? 'none' : '1px solid black')};
  margin-left: ${(props) => (props.first ? '1px' : '0px')};
`;
export const HeaderLabel = styled.div`
  font-family: Verdana;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const ProductListContainer = styled.div`
  display: flex;
  border: ${(props) => (props.isEdit ? '1px solid orange' : '1px solid white')};
  border-bottom: ${(props) => (props.isEdit ? '1px solid orange' : '1px solid #666666')};
  min-width: ${(props) => props.minWidth};
  padding: 3px 5px;
  margin-top: 2px;
  background-color: ${(props) => (props.notAdded || props.isEdit) && 'orange'};
`;

export const ItemNameContainer = styled.div`
  width: ${(props) => props.width};
  border-right: 1px solid ${(props) => (props.isEdit ? 'orange' : '#666666')};
  padding: 0px 5px;
  display: flex;
  min-height: 20px;
  justify-content: ${(props) => (props.justifyCenter ? 'center' : 'flex-start')};
`;

export const ItemName = styled.div`
  font-size: 0.8rem;
  padding-top: 2px;
  display: flex;
`;

export const ItemInput = styled.input`
  width: ${(props) => props.width};
  border: none;
  font-family: Verdana;
  padding-left: 5px;
  border-right: ${(props) => (props.isEdit ? 'none' : '1px solid orange')};
  text-align: ${(props) => (props.justifyCenter ? 'center' : 'left')};
`;

export const InlineIconContainer = styled.div`
  cursor: pointer;
  border: none;
  font-family: Verdana;
  text-align: center;
  width: 30px;
  padding-top: 2px;
  padding-left: 2px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: red;
  }
`;
