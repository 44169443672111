import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createNewUser } from '../../../../../config/redux/user';

import { MainBar, CardContainer, PageContainer, CardBar, CardBody, InfoContainer, InfoLabel, InfoInput, InfoInputSelect } from '../../../../Reusables/BodyAndBars';
import { IconsContainer, CancelIconWhite, SaveIconWhite } from '../../../../Reusables/Icons';

import { TitlesContainer, UserHeaderTitle, Row, InfoContainerCheckBox, InfoLabelCheckBox } from '../Users.styled';

function CreateUser({ handleCancel, back, refreshCount, setRefreshCount }) {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [access, setAccess] = useState('');
  const [role, setRole] = useState('');
  const [department, setDepartment] = useState('');
  const [position, setPosition] = useState('');
  const [reportsTo, setReportTo] = useState('');
  const [dateStarted, setDateStarted] = useState('');
  const [isDoctor, setIsDoctor] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const dataList = useSelector((state) => state.dataList && state.dataList.dataList);
  const allUserData = useSelector((state) => state.user && state.user.allUserData);

  let data = {
    emailAddress,
    password,
    access,
    role,
    isDoctor,
    isActive: true,
    isOwner: false,
    createdBy: currentUser.userId,
    personalInfo: {
      firstName: firstName,
      middleName: '',
      lastName: lastName,
      preferredName: '',
      dateOfBirth: '1970-01-01',
      birthPlace: '',
      sex: '',
      civilStatus: '',
      nationality: '',
      bloodType: '',
      image: '',
    },
    contactInfo: {
      phoneNumber: '',
      mobileNumber1: '',
      mobileNumber2: '',
      currentAddress: {
        houseNumberAndStreet: '',
        subdivision: '',
        barangay: '',
        cityOrMunicipality: '',
        province: '',
        zip: '',
      },
      provincialAddress: {
        houseNumberAndStreet: '',
        subdivision: '',
        barangay: '',
        cityOrMunicipality: '',
        province: '',
        zip: '',
      },
    },
    employmentRecord: {
      employeeId: employeeId,
      position: position,
      department: department,
      reportsTo: reportsTo,
      dateHired: dateStarted,
      sssNumber: '',
      tinNumber: '',
      philhealthNumber: '',
      pagibigNumber: '',
    },
    EmergencyContact: {
      name: '',
      relationship: '',
      emailAddress: '',
      mobileNumber: '',
      phoneNumber: '',
      currentAddress: {
        houseNumberAndStreet: '',
        subdivision: '',
        barangay: '',
        cityOrMunicipality: '',
        province: '',
        zip: '',
      },
    },
  };

  function handleSave() {
    dispatch(
      createNewUser({
        method: 'post',
        url: `user/add`,
        token: currentUser.userToken,
        data,
      })
    );
    setRefreshCount(refreshCount + 1);
    back();
  }

  return (
    <>
      <MainBar>
        <TitlesContainer>
          <UserHeaderTitle>Create New User</UserHeaderTitle>
        </TitlesContainer>
        <IconsContainer>
          <div
            title="cancel"
            onClick={() => {
              if (window.confirm('Are you sure you want to CANCEL user creation?') === true) {
                handleCancel();
              } else {
                console.log('User creation cancelled.');
              }
            }}
          >
            <CancelIconWhite />
          </div>
          <div title="save" onClick={() => handleSave()}>
            <SaveIconWhite />
          </div>
        </IconsContainer>
      </MainBar>
      <PageContainer>
        <CardContainer width={'70%'}>
          <CardBar> </CardBar>
          <CardBody>
            <Row>
              <InfoContainerCheckBox>
                <InfoLabelCheckBox>Is Doctor:</InfoLabelCheckBox>
                <input type="checkbox" checked={isDoctor} onChange={(e) => setIsDoctor(e.target.checked)} />
              </InfoContainerCheckBox>
            </Row>
            <Row>
              <InfoContainer>
                <InfoLabel>Email Address:</InfoLabel>
                <InfoInput required={true} autocomplete="off" type="email" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>Password:</InfoLabel>
                <InfoInput required={true} autocomplete="off" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>Employee ID:</InfoLabel>
                <InfoInput autocomplete="off" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
              </InfoContainer>
            </Row>
            <Row>
              <InfoContainer>
                <InfoLabel>First Name:</InfoLabel>
                <InfoInput required={true} autocomplete="off" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>Last Name:</InfoLabel>
                <InfoInput required={true} autocomplete="off" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </InfoContainer>
              <InfoContainer>
                <InfoLabel>Mobile Number:</InfoLabel>
                <InfoInput autocomplete="off" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
              </InfoContainer>
            </Row>
            <Row>
              <InfoContainer>
                <InfoLabel>User Access:</InfoLabel>
                <InfoInputSelect required={true} value={access} onChange={(e) => setAccess(e.target.value)}>
                  <option value="" disabled>
                    --- select user access ---
                  </option>
                  {dataList &&
                    dataList.userAccess.map((access, index) => {
                      return (
                        <option key={index} value={access}>
                          {access}
                        </option>
                      );
                    })}
                </InfoInputSelect>
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>User Role:</InfoLabel>
                <InfoInputSelect required={true} value={role} onChange={(e) => setRole(e.target.value)}>
                  <option value="" disabled>
                    --- select user role ---
                  </option>
                  {dataList &&
                    dataList.userRoles.map((role, index) => {
                      return (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      );
                    })}
                </InfoInputSelect>
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>Reports To:</InfoLabel>
                <InfoInputSelect required={true} value={reportsTo} onChange={(e) => setReportTo(e.target.value)}>
                  <option value="" disabled>
                    --- select user ---
                  </option>
                  {allUserData &&
                    allUserData.map((user, index) => {
                      return (
                        <option key={index} value={`${user.personalInfo.firstName} ${user.personalInfo.firstName}`}>
                          {user.personalInfo.firstName} {user.personalInfo.lastName}
                        </option>
                      );
                    })}
                </InfoInputSelect>
              </InfoContainer>
            </Row>
            <Row>
              <InfoContainer>
                <InfoLabel>Department:</InfoLabel>
                <InfoInputSelect required={true} value={department} onChange={(e) => setDepartment(e.target.value)}>
                  <option value="" disabled>
                    --- select user department ---
                  </option>
                  {dataList &&
                    dataList.userDepartment.map((department, index) => {
                      return (
                        <option key={index} value={department}>
                          {department}
                        </option>
                      );
                    })}
                </InfoInputSelect>
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>Position:</InfoLabel>
                <InfoInputSelect required={true} value={position} onChange={(e) => setPosition(e.target.value)}>
                  <option value="" disabled>
                    --- select user position ---
                  </option>
                  {dataList &&
                    dataList.userPosition.map((position, index) => {
                      return (
                        <option key={index} value={position}>
                          {position}
                        </option>
                      );
                    })}
                </InfoInputSelect>
              </InfoContainer>

              <InfoContainer>
                <InfoLabel>Date Started:</InfoLabel>
                <InfoInput type="date" value={dateStarted} onChange={(e) => setDateStarted(e.target.value)} />
              </InfoContainer>
            </Row>
          </CardBody>
        </CardContainer>
      </PageContainer>
    </>
  );
}

export default CreateUser;
