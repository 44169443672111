import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

import { ChartCardInputContainer, ChartCardTitle, ChartCardBar, ChartCardBody, ChartSeparator, ChartCardBodyAccordion, Owner, LabRequestViewContainer } from '../ViewChart.styled';
import { IconsContainer, EditIcon, MaximizeIcon, MinimizeIcon, PrintIcon } from '../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger } from '../../../../../../Reusables/Buttons';
import { PrintPageContainer, PrintBody, PrintTitle } from '../../../../../../Reusables/PrintTemplates/PrintTemplate.styled';

import PrintHeader from '../../../../../../Reusables/PrintTemplates/PrintHeader';
import PrintPatientsInfo from '../../../../../../Reusables/PrintTemplates/PatientsInfo';
import PhysicianDetails from '../../../../../../Reusables/PrintTemplates/PhysicianDetails';
import PrintFooter from '../../../../../../Reusables/PrintTemplates/PrintFooter';
import RichTextEditor from './richText/RichTextEditor';
import RichTextView from './richText/RichTextView';

function LabRequestCard({ isNewCard, setAddNewCard, cardId, setHideAddCardButton, cardIndex, cardData, patientInfo, date, doctorId, owner, addedDate }) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const assignedDoctor = doctorList.filter((doctor, index) => doctor._id === doctorId)[0];
  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [labRequest, setLabRequest] = useState(cardData && cardData);
  const [isMinimized, setIsMinimized] = useState(false);
  const [dateAndTime, setDateAndTime] = useState(moment());
  const [content, setContent] = useState('{"blocks":[{"key":"dlpl6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}');
  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    setLabRequest(cardData);
  }, [cardData]);

  const handleAddCard = () => {
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(
      addCardtoChart({
        method: 'post',
        url: `chart/addCard/${cardId}`,
        token: currentUser.userToken,
        data: { cardType: 'lab_request_card', cardData: content, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` },
      })
    );
  };

  const handleUpdateCard = () => {
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(updateCardInChart({ method: 'post', url: `chart/updateCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardType: 'lab_request_card', cardData: content } }));
  };

  const handleCancel = () => {
    setLabRequest(cardData);
    setCardIsEdit(false);
    setAddNewCard('');
    setHideAddCardButton(false);
  };

  const handleEdit = () => {
    setLabRequest(cardData);
    setCardIsEdit(true);
    setHideAddCardButton(true);
  };

  function handleBeforeGetContent() {
    setDateAndTime(moment());
    setIsPrint(true);
    return Promise.resolve();
  }

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `${patientInfo && patientInfo.lastName}_${patientInfo && patientInfo.firstName}_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getText = (str) => {
    if (str && isJsonString(str)) {
      return str;
    } else {
      let parsableText = `{"blocks":[{"key":"jv6k","text":"${str}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
      return parsableText;
    }
  };

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>
          Laboratory Request Card{' '}
          {!isNewCard && (
            <Owner>
              Added by: <b>{owner}</b> on {moment(addedDate).format('yyy-MM-DD')}
            </Owner>
          )}
        </ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              <div title="print" onClick={handlePrint}>
                <PrintIcon />
              </div>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['lab_request_card'] && roles[userRole]['lab_request_card']['update'] && (
                <div title={'edit'} onClick={handleEdit}>
                  <EditIcon />
                </div>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          {cardIsEdit ? (
            <ChartCardInputContainer width={'100%'}>
              <RichTextEditor storedState={JSON.parse(getText(labRequest))} setData={(data) => setContent(data)} />
            </ChartCardInputContainer>
          ) : (
            <>
              {isNewCard ? (
                <ChartCardInputContainer width={'100%'}>
                  <RichTextEditor storedState={{ blocks: [{ key: 'dlpl6', text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }], entityMap: {} }} setData={(data) => setContent(data)} />
                </ChartCardInputContainer>
              ) : (
                <ChartCardInputContainer width={'100%'}>
                  {isPrint ? (
                    <PrintPageContainer ref={componentRef}>
                      <PrintHeader />
                      <PrintTitle>LABORATORY REQUEST</PrintTitle>
                      <PrintPatientsInfo patientInfo={patientInfo} date={date} />
                      <PrintBody height={'510px'}>{isJsonString(labRequest) ? <RichTextView storedState={JSON.parse(labRequest)} readOnly={true} /> : <div>{labRequest}</div>}</PrintBody>
                      <PhysicianDetails
                        name={`${assignedDoctor.personalInfo.namePrefix ? assignedDoctor.personalInfo.namePrefix : ''} ${assignedDoctor.personalInfo.firstName} ${assignedDoctor.personalInfo.middleName.charAt(0)}. ${
                          assignedDoctor.personalInfo.lastName
                        } ${assignedDoctor.personalInfo.nameSuffix ? assignedDoctor.personalInfo.nameSuffix : ''}`}
                        prc={assignedDoctor.employmentRecord.prcNumber}
                        ptr={assignedDoctor.employmentRecord.ptrNumber}
                      />
                      <PrintFooter dateAndTime={dateAndTime} />
                    </PrintPageContainer>
                  ) : (
                    <LabRequestViewContainer width={'100%'}>{isJsonString(labRequest) ? <RichTextView storedState={JSON.parse(labRequest)} readOnly={true} /> : <div>{labRequest}</div>}</LabRequestViewContainer>
                  )}
                </ChartCardInputContainer>
              )}
            </>
          )}
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default LabRequestCard;
