import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserPassword, reLogin } from '../../../../../config/redux/user';

import { CardContainer, CardBar, CardBody, TitlesContainer } from '../../../../Reusables/BodyAndBars';
import { InfoContainer, InfoLabel, InfoInput } from '../../../../Reusables/ViewProfile/ViewProfile.styled';
import { ButtonPrimary, ButtonDanger } from '../../../../Reusables/Buttons';

function UpdatePassword() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const passwordUpdatedSuccessfully = useSelector((state) => state.user && state.user.passwordUpdate);
  const [passwordData, setPasswordData] = useState({});

  useEffect(() => {
    passwordUpdatedSuccessfully && dispatch(reLogin());
    // eslint-disable-next-line
  }, [passwordUpdatedSuccessfully]);

  const handleChange = (name, value) => {
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordData.newPassword === passwordData.confirmPassword) {
      dispatch(updateUserPassword({ method: 'post', url: `user/updatePassword/${currentUser.userId}`, token: currentUser.userToken, data: passwordData }));
      setPasswordData({});
    } else {
      window.alert('New password and confirm password did not match.');
    }
  };

  return (
    <CardContainer width={'40%'}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <CardBar>
          <TitlesContainer>Update Password</TitlesContainer>
        </CardBar>
        <CardBody>
          <InfoContainer width={'100%'}>
            <InfoLabel>Email Address: </InfoLabel>
            <InfoInput type="email" name="emailAddress" required value={passwordData.emailAddress ? passwordData.emailAddress : ''} onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </InfoContainer>
          <InfoContainer width={'100%'}>
            <InfoLabel>Password: </InfoLabel>
            <InfoInput type="password" name="password" required minLength="8" maxLength="30" value={passwordData.password ? passwordData.password : ''} onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </InfoContainer>
          <InfoContainer width={'100%'}>
            <InfoLabel>Enter New Password: </InfoLabel>
            <InfoInput type="password" name="newPassword" required minLength="8" maxLength="30" value={passwordData.newPassword ? passwordData.newPassword : ''} onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </InfoContainer>
          <InfoContainer width={'100%'}>
            <InfoLabel>Confirm Password: </InfoLabel>
            <InfoInput type="password" name="confirmPassword" required minLength="8" maxLength="30" value={passwordData.confirmPassword ? passwordData.confirmPassword : ''} onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </InfoContainer>
          <ButtonDanger onClick={() => setPasswordData({})}>Cancel</ButtonDanger>
          <ButtonPrimary type="submit">Update Password</ButtonPrimary>
        </CardBody>
      </form>
    </CardContainer>
  );
}

export default UpdatePassword;
