import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSettings } from '../../../../../config/redux/settings';

import { IconsContainer, EditIcon, SaveIcon, CancelIcon, CheckIcon, CheckIconGreen, AddFolderIcon, DeleteIcon } from '../../../../Reusables/Icons';
import { CardContainer, CardBody } from '../../../../Reusables/BodyAndBars';
import {
  CardBar,
  CompanySettingsContainer,
  CompanySettingsName,
  CompanySettingsNameInput,
  CompanySettingsSubBar,
  ColorInput,
  ThemeColorList,
  ColorDetailsContainer,
  ColorLabel,
  ColorDisplayOuterBox,
  ColorDisplayInnerBox,
  AddNewThemeContainer,
} from '../Settings.styled';
import { useEffect } from 'react';

function ThemeSettings() {
  const colorNameList = ['TopBar', 'MainNav', 'MainNavHover', 'MainBackground', 'CardHeader', 'CardBackground', 'CardBorder', 'CardFont', 'CardFontLight'];
  const initialColorState = { TopBar: '#FFFFFF', MainNav: '#FFFFFF', MainNavHover: '#FFFFFF', MainBackground: '#FFFFFF', CardHeader: '#FFFFFF', CardBackground: '#FFFFFF', CardBorder: '#000000', CardFont: '#000000', CardFontLight: '#000000' };
  const dispatch = useDispatch();
  const themeSettingsData = useSelector((state) => state.settings && state.settings.themeSettings);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const [themeSettings, setThemeSettings] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isEditIndex, setIsEditIndex] = useState(null);
  const [isAddNewTheme, setIsAddNewTheme] = useState(false);
  const [newThemeName, setNewThemeName] = useState('');
  const [newThemeColors, setNewThemeColors] = useState(initialColorState);

  useEffect(() => {
    setThemeSettings(themeSettingsData);
  }, [themeSettingsData]);

  const handleActivateTheme = (name) => {
    setThemeSettings({ ...themeSettings, activeTheme: name });
    dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { themeSettings: { ...themeSettings, activeTheme: name } } }));
  };

  const handleSave = () => {
    setIsEdit(false);
    setIsEditIndex(null);
    dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { themeSettings } }));
  };

  const handleColorChange = (index, colorName, value) => {
    let tempThemeList = themeSettings.themeList.map((item, i) => {
      if (i === index) {
        return { ...themeSettings.themeList[index], themeColors: { ...themeSettings.themeList[index].themeColors, [colorName]: value } };
      } else {
        return item;
      }
    });
    setThemeSettings({ ...themeSettings, themeList: tempThemeList });
  };

  const handleChangeName = (index, value) => {
    let tempThemeList = themeSettings.themeList.map((item, i) => {
      if (i === index) {
        return { ...themeSettings.themeList[index], name: value };
      } else {
        return item;
      }
    });
    setThemeSettings({ ...themeSettings, themeList: tempThemeList });
  };

  const handleCancel = () => {
    if (window.confirm('Are you sure you want to CANCEL?') === true) {
      setIsEdit(false);
      setIsEditIndex(null);
      setIsAddNewTheme(false);
      setNewThemeName('');
      setNewThemeColors(initialColorState);
      setThemeSettings(themeSettingsData && themeSettingsData);
    }
  };
  const handleAddNewTheme = () => {
    if (newThemeName === '') {
      window.alert('Theme name is required!');
    } else {
      let newThemeSettings = { ...themeSettings, themeList: [...themeSettings.themeList, { name: newThemeName, themeColors: newThemeColors }] };
      dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { themeSettings: newThemeSettings } }));
      setTimeout(() => {
        setIsEdit(false);
        setIsEditIndex(null);
        setIsAddNewTheme(false);
        setNewThemeName('');
        setNewThemeColors(initialColorState);
      }, 200);
    }
  };

  const handleDeleteTheme = (name) => {
    if (window.confirm('Are you sure you want to DELETE this theme?') === true) {
      setThemeSettings({ ...themeSettings, themeList: [...themeSettings.themeList.filter((theme) => theme.name !== name)] });
      dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { themeSettings: { ...themeSettings, themeList: [...themeSettings.themeList.filter((theme) => theme.name !== name)] } } }));
    }
  };

  return (
    <CardContainer width={'80%'}>
      <CardBar>
        <CompanySettingsName>Theme Settings</CompanySettingsName>
        <IconsContainer>
          {isAddNewTheme ? (
            <>
              <div title={'save new theme'} onClick={() => handleAddNewTheme()}>
                <SaveIcon />
              </div>
              <div title={'cancel'} onClick={() => handleCancel()}>
                <CancelIcon />
              </div>
            </>
          ) : (
            <div title={'add new theme'} onClick={() => setIsAddNewTheme(true)}>
              <AddFolderIcon />
            </div>
          )}
        </IconsContainer>
      </CardBar>

      <CardBody>
        <AddNewThemeContainer isMinimized={!isAddNewTheme}>
          <CompanySettingsSubBar>
            <CompanySettingsNameInput value={newThemeName} onChange={(e) => setNewThemeName(e.target.value)} />
          </CompanySettingsSubBar>
          <ThemeColorList>
            {colorNameList.map((colorName, i) => {
              return (
                <ColorDetailsContainer key={i} width={'32%'}>
                  <ColorLabel>{colorName}:</ColorLabel>
                  <ColorInput type="color" value={newThemeColors[colorName]} onChange={(e) => setNewThemeColors({ ...newThemeColors, [colorName]: e.target.value })} />
                </ColorDetailsContainer>
              );
            })}
          </ThemeColorList>
        </AddNewThemeContainer>

        {themeSettings &&
          themeSettings.themeList &&
          themeSettings.themeList.map((theme, index) => {
            return (
              <CompanySettingsContainer key={index}>
                <CompanySettingsSubBar>
                  {isEdit && isEditIndex === index && theme.name !== themeSettings.activeTheme ? (
                    <CompanySettingsNameInput value={themeSettings && themeSettings.themeList && themeSettings.themeList[index].name} onChange={(e) => handleChangeName(index, e.target.value)} />
                  ) : (
                    <CompanySettingsName>{theme.name}</CompanySettingsName>
                  )}

                  <IconsContainer>
                    {isEdit && isEditIndex === index ? (
                      <>
                        <div onClick={() => handleSave()}>
                          <SaveIcon />
                        </div>
                        <div onClick={() => handleCancel()}>
                          <CancelIcon />
                        </div>
                      </>
                    ) : (
                      <>
                        <div title="delete theme" onClick={() => handleDeleteTheme(theme.name)}>
                          <DeleteIcon />
                        </div>
                        <div
                          title="edit theme"
                          onClick={() => {
                            setIsEdit(true);
                            setIsEditIndex(index);
                          }}
                        >
                          <EditIcon />
                        </div>
                        {theme.name === themeSettings.activeTheme ? (
                          <div>
                            <CheckIconGreen />
                          </div>
                        ) : (
                          <div title="activate theme" onClick={() => handleActivateTheme(theme.name)}>
                            <CheckIcon />
                          </div>
                        )}
                      </>
                    )}
                  </IconsContainer>
                </CompanySettingsSubBar>
                <ThemeColorList>
                  {colorNameList.map((colorName, i) => {
                    return (
                      <ColorDetailsContainer key={i} width={'32%'}>
                        <ColorLabel>{colorName}:</ColorLabel>
                        {isEdit && isEditIndex === index ? (
                          <ColorInput type="color" value={theme.themeColors[colorName]} onChange={(e) => handleColorChange(index, colorName, e.target.value)} />
                        ) : (
                          <ColorDisplayOuterBox>
                            <ColorDisplayInnerBox color={theme.themeColors[colorName]}></ColorDisplayInnerBox>
                          </ColorDisplayOuterBox>
                        )}
                      </ColorDetailsContainer>
                    );
                  })}
                </ThemeColorList>
              </CompanySettingsContainer>
            );
          })}
      </CardBody>
    </CardContainer>
  );
}

export default ThemeSettings;
