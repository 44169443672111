import styled from 'styled-components/macro';

// roles settings

export const RolesSettingsHeader = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  width: ${(props) => (props.width ? props.width : '160px')};
  border-left: ${(props) => (props.noLeftBorder ? `none` : `1px solid ${props.theme.CardBorder}`)};
  background-color: ${({ theme }) => theme.CardHeader};
  text-align: center;
`;

export const CardBodyRow = styled.div`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.CardBackground};
  box-shadow: 2px 2px 3px #ccc;
  padding-left: 5px;
  display: inline-flex;
  padding: 3px 0px 3px 5px;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const RolesSettingsItems = styled.div`
  font-size: 0.8rem;
  width: ${(props) => (props.width ? props.width : '160px')};
  border-left: 1px solid ${({ theme }) => theme.TopBar};
  text-align: center;
  padding: 5px 0px;
  display: flex;
  justify-content: center;
`;

export const CardBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px 5px 0px 0px;
  min-height: 30px;
  padding: 3px 5px;
  display: flex;
  width: auto;
  justify-content: space-between;
`;

export const RolesCardBar = styled.div`
  display: inline-flex;
  padding: 8px 0px;
  background-color: ${({ theme }) => theme.CardHeader};
`;

export const CheckboxContainer = styled.div``;

export const CheckboxInput = styled.input`
  cursor: pointer;
  margin-top: 3px;
`;
export const CheckboxLabel = styled.label`
  cursor: pointer;
  margin: 0px 5px;
  font-size: 1rem;
`;

// company settings

export const CompanySettingsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.CardBackground};
  box-shadow: 2px 2px 3px #ccc;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3px 5px 3px 5px;
  margin-bottom: 5px;
`;

export const CompanySettingsName = styled.div`
  margin: 5px;
`;

export const CompanySettingsNameInput = styled.input`
  margin: 2px;
  font-size: 1rem;
  padding: 2px;
`;

export const CompanySettingsSubBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  min-height: 30px;
  padding: 3px 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const HeaderLogo = styled.img`
  width: 50px;
  height: 50px;
  margin: 15px;
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
  margin: 15px;
`;

export const Background = styled.img`
  width: 95%;
  height: auto;
  margin: 15px;
`;

export const UploadImage = styled.input`
  display: none;
`;

export const Reminder = styled.div`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  padding: ${(props) => (props.maxHeight ? '10px' : '0px')};
  border-radius: 5px;
  font-size: 0.8rem;
  color: red;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '0px')};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
`;

export const DisplayCompanyNameContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${(props) => (props.night ? '#000000' : '#ffffff')};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const DisplayCompanyName = styled.div`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.fontStyle};
  font-size: 1.4rem;
  margin-right: 15px;
`;

export const DisplayCompanyText = styled.div`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.fontStyle};
  margin-left: 10px;
  font-size: 1rem;
`;

export const EditCompanyNameContainer = styled.div`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '0px')};
  padding: ${(props) => (props.maxHeight ? '10px' : '0px')};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
`;

export const ColorInput = styled.input`
  padding: 0px;
  margin: 0px;
  width: 25px;
`;

export const DisplayGoogleFontList = styled.div`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '0px')};
  padding: ${(props) => (props.maxHeight ? '10px' : '0px')};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px;
  justify-content: center;
`;

export const GFontDisplayContainer = styled.div`
  margin: 2px;
  padding: 5px;
  background-color: ${({ theme }) => theme.CardBackground};
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

// theme settings

export const ThemeColorList = styled.div`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
`;

export const ColorDetailsContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width : '33%')};

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ColorLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.8rem;
  padding: 6px 2px 0px 2px;
`;

export const ColorDisplayOuterBox = styled.div`
  border: 1px solid grey;
  width: 25px;
  height: 27px;
  margin-top: 0px;
  border-radius: 3px;
  padding: 4px 2px 0px 2px;
`;

export const ColorDisplayInnerBox = styled.div`
  background-color: ${(props) => props.color};
  border: 1px solid grey;
  width: 19px;
  height: 17px;
`;

export const AddNewThemeContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.CardBackground};
  box-shadow: 2px 2px 3px #ccc;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  padding: ${(props) => (props.isMinimized ? '0px' : '3px 5px 3px 5px')};
  max-height: ${(props) => (props.isMinimized ? '0px' : '200px')};
`;

export const CardContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  width: ${(props) => (props.width ? props.width : '50%')};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin: 5px;
  overflow-x: ${(props) => (props.scrollable ? 'scroll' : 'hidden')};

  @media (max-width: 992px) {
    width: 98%;
  }
`;

export const CardBody = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px;
  width: auto;
`;

export const OtherSettingsItemContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const OtherSettingsItemName = styled.div`
  display: flex;
  padding-top: 5px;
  padding-left: 5px;
  justify-content: flex-start;
  font-size: 0.9rem;
`;

export const OtherSettingsItemValue = styled.div`
  display: flex;
  padding: 5px 10px;
  margin-left: 20px;
  justify-content: flex-start;
  font-size: 0.9rem;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.CardBackground};

  @media (max-width: 400px) {
    margin: 10px 5px;
  }
`;

export const OtherSettingsItemInput = styled.input`
  width: 150px;
  margin: 0px 10px;
`;
