import React, { useState, useEffect } from 'react';
import Address from './Address';

import { Row, InfoContainer, InfoLabel, InfoData, InfoInput } from '../ViewProfile.styled';

function EmergencyContact({ emergencyContact, isEdit, setEmergencyContact }) {
  const [newAddress, setNewAddress] = useState({});
  const [emergencyContactData, setEmergencyContactData] = useState({});

  const emergencyContactDataList = [
    { name: 'name', Label: 'Name:', type: 'text', placeholder: '' },
    { name: 'relationship', Label: 'Relationship:', type: 'text', placeholder: '' },
    { name: 'mobileNumber', Label: 'Mobile Number:', type: 'text', placeholder: '' },
    { name: 'phoneNumber', Label: 'Phone Number:', type: 'text', placeholder: '' },
    { name: 'emailAddress', Label: 'Email Address:', type: 'email', placeholder: '' },
  ];

  useEffect(() => {
    isEdit && setEmergencyContactData(emergencyContact ? emergencyContact : {});
    isEdit && setNewAddress(emergencyContact && emergencyContact.address ? emergencyContact.address : {});
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setEmergencyContact({ ...emergencyContactData, newAddress });
    // eslint-disable-next-line
  }, [emergencyContactData, newAddress]);

  const handleChange = (name, value) => {
    setEmergencyContactData({ ...emergencyContactData, [name]: value });
  };

  return (
    <>
      <Row>
        {emergencyContactDataList.map((item, index) => {
          return (
            <InfoContainer key={index}>
              <InfoLabel>{item.Label}</InfoLabel>
              {isEdit ? (
                <InfoInput
                  type={item.type}
                  pattern={item.pattern}
                  placeholder={item.placeholder}
                  value={emergencyContactData && emergencyContactData[item.name] ? emergencyContactData[item.name] : ''}
                  name={item.name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              ) : (
                <InfoData>{emergencyContact && emergencyContact[item.name] ? emergencyContact[item.name] : ''}</InfoData>
              )}
            </InfoContainer>
          );
        })}
      </Row>
      <Address isEdit={isEdit} address={emergencyContact && emergencyContact.address ? emergencyContact.address : {}} newAddress={newAddress} setNewAddress={setNewAddress} SubBarLabel={'Emergency Contact Address'} />
    </>
  );
}

export default EmergencyContact;
