import styled from 'styled-components/macro';

export const PrintPageContainer = styled.div`
  padding: 5px 10px;
  width: 550px;
  height: 790px;
  background-color: white;
`;

export const PrintTitle = styled.div`
  color: white;
  background-color: darkgray;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 10px;
  padding: 1px;
  border-bottom: 1px solid black;
`;

export const PrintHeaderContainer = styled.div`
  display: flex;
  padding: 5px;
  height: 90px;
  border-bottom: 1px solid black;
`;

export const PrintLogoContainer = styled.div`
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrintLogo = styled.img`
  width: auto;
  height: 60px;
`;

export const PrintCompanyNameContainer = styled.div`
  padding: 10px;
  width: 420px;
`;

export const ClinicName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
`;

export const ClinicAddress = styled.div`
  font-size: 0.7rem;
`;

//============================

export const PrintFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  border-top: 1px solid gray;
`;

export const PrintBody = styled.div`
  padding: 5px;
  height: ${(props) => (props.height ? props.height : '540px')};
  //position: relative;
`;

export const PoweredByContainer = styled.div`
  padding: 5px;
  color: gray;
  font-size: 0.6rem;
`;

export const DatePrintedContaier = styled.div`
  padding: 5px;
  font-size: 0.6rem;
  color: gray;
`;

//=====================================

export const PhysicianInfoConstainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const PhysicianInfoDetails = styled.div`
  padding: 5px;
  width: 300px;
  margin: 5px 40px;
  text-align: center;
  justify-content: end;
`;

export const PhysicianName = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  border-bottom: 1px solid black;
`;

export const PhysicianInfo = styled.div`
  font-size: 0.7rem;
`;

//========================================

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
`;

export const PatientInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PatientDetails = styled.div`
  padding: 2px;
  font-size: 0.7rem;
`;
