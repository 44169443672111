import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';
import { PrintPageContainer, PrintBody, PrintTitle } from '../../../../../../Reusables/PrintTemplates/PrintTemplate.styled';

import PrintHeader from '../../../../../../Reusables/PrintTemplates/PrintHeader';
import PrintPatientsInfo from '../../../../../../Reusables/PrintTemplates/PatientsInfo';
import PhysicianDetails from '../../../../../../Reusables/PrintTemplates/PhysicianDetails';
import PrintFooter from '../../../../../../Reusables/PrintTemplates/PrintFooter';

import {
  ChartCardTitle,
  InfoData,
  ChartCardBar,
  ChartCardBody,
  ChartSeparator,
  DiagnosisDataContainer,
  ProcedureAddDataContainer,
  DiagnosisInfoContainer,
  SearchInputField,
  SearchResultContainer,
  SearchResult,
  SearchResultButtonContainer,
  ProcedureAddOtherDataContainer,
  AddOtherProcedureInputContainer,
  EyesButtonContainer,
  ChartCardInputContainer,
  InfoLabel,
  InfoInputTextArea,
  InfoInput,
  ChartCardBodyAccordion,
  Owner,
  InlineInputContainer,
  InlineInput,
  InlineMultiLineInput,
  InlineSelect,
} from '../ViewChart.styled';
import { IconsContainer, EditIcon, DeleteIcon, MaximizeIcon, MinimizeIcon, SaveIcon, CancelIcon, PrintIcon } from '../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger, ButtonSuccess, ButtonWarning } from '../../../../../../Reusables/Buttons';

function ProcedureCard({ dataList, isNewCard, setAddNewCard, cardId, options, setHideAddCardButton, cardIndex, owner, addedDate, doctorId, patientInfo, date }) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const assignedDoctor = doctorList.filter((doctor, index) => doctor._id === doctorId)[0];
  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [otherProcedure, setOtherProcedure] = useState('');
  const [otherRVSCode, setOtherRVSCode] = useState('');
  const [addOthers, setAddOthers] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [lineIsEdit, setLineIsEdit] = useState(false);
  const [lineEditIndex, setLineEditIndex] = useState(null);
  const [lineIsEditRVSCode, setLineIsEditRVSCode] = useState('');
  const [lineIsEditProcedure, setLineIsEditProcedure] = useState('');
  const [lineIsEditEyes, setLineIsEditEyes] = useState('');
  const [dateAndTime, setDateAndTime] = useState(moment());
  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    !isNewCard && setCardData(dataList);
    // eslint-disable-next-line
  }, [dataList]);

  const handleAddCard = () => {
    setHideAddCardButton(false);
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(
      addCardtoChart({ method: 'post', url: `chart/addCard/${cardId}`, token: currentUser.userToken, data: { cardType: 'procedure_card', cardData, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` } })
    );
  };

  const handleUpdateCard = () => {
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(updateCardInChart({ method: 'post', url: `chart/updateCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardData } }));
  };

  const handleCancel = () => {
    setCardData(dataList);
    setCardIsEdit(false);
    setAddNewCard('');
    setHideAddCardButton(false);
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditRVSCode('');
    setLineIsEditProcedure('');
    setLineIsEditEyes('');
  };

  const handleEdit = () => {
    setCardData(dataList);
    setCardIsEdit(true);
    setHideAddCardButton(true);
  };

  const handleAddDiagnosis = (data, eye) => {
    eye && setCardData([...cardData, { ...data, eye }]);
    setSearchTextValue('');
    setSearchResult([]);
    setAddOthers(false);
  };

  const handleDelete = (data) => {
    setCardData(cardData.filter((cardData) => cardData !== data));
  };

  const handleSaveLine = (index) => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    let newData = cardData.map((data, i) => {
      if (i === index) {
        return { Description: lineIsEditProcedure, RVScode: lineIsEditRVSCode, eye: lineIsEditEyes, price: data.price };
      } else {
        return data;
      }
    });
    setCardData(newData);
    setLineIsEditRVSCode('');
    setLineIsEditProcedure('');
    setLineIsEditEyes('');
  };

  const handleEditLine = (index) => {
    setLineIsEdit(true);
    setLineEditIndex(index);
    setLineIsEditRVSCode(cardData[index].RVScode);
    setLineIsEditProcedure(cardData[index].Description);
    setLineIsEditEyes(cardData[index].eye);
  };

  const handleCancelLine = () => {
    setLineIsEdit(false);
    setLineEditIndex(null);
    setLineIsEditRVSCode('');
    setLineIsEditProcedure('');
    setLineIsEditEyes('');
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTextValue.length >= 1) {
        setSearchResult(options.filter((option) => option.Description.toLowerCase().includes(searchTextValue.toLowerCase())));
      } else {
        setSearchResult([]);
      }
    }, 1000);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [searchTextValue]);

  function handleBeforeGetContent() {
    setDateAndTime(moment());
    setIsPrint(true);
    return Promise.resolve();
  }

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `${patientInfo && patientInfo.lastName}_${patientInfo && patientInfo.firstName}_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>
          Procedure Card{' '}
          {!isNewCard && (
            <Owner>
              Added by: <b>{owner}</b> on {moment(addedDate).format('yyy-MM-DD')}
            </Owner>
          )}
        </ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['procedure_card'] && roles[userRole]['procedure_card']['update'] && (
                <>
                  <div title="print" onClick={handlePrint}>
                    <PrintIcon />
                  </div>
                  <div title={'edit'} onClick={handleEdit}>
                    <EditIcon />
                  </div>
                </>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          {isNewCard || cardIsEdit ? (
            <ProcedureAddDataContainer>
              {cardData &&
                cardData.map((data, index) => {
                  return (
                    <DiagnosisDataContainer key={index}>
                      <DiagnosisInfoContainer>
                        {lineIsEdit && lineEditIndex === index ? (
                          <InlineInputContainer>
                            <InlineInput value={lineIsEditRVSCode} onChange={(e) => setLineIsEditRVSCode(e.target.value)} />
                            <InlineMultiLineInput value={lineIsEditProcedure} onChange={(e) => setLineIsEditProcedure(e.target.value)} rows={4} />
                            <InlineSelect value={lineIsEditEyes} onChange={(e) => setLineIsEditEyes(e.target.value)}>
                              <option>Left Eye</option>
                              <option>Right Eye</option>
                              <option>Both Eyes</option>
                            </InlineSelect>
                          </InlineInputContainer>
                        ) : (
                          <InfoData border={'none'}>
                            {`[ ${data.RVScode} ] ${data.Description} - `} <b>{`${data.eye}`}</b>
                          </InfoData>
                        )}
                      </DiagnosisInfoContainer>
                      <IconsContainer>
                        {lineIsEdit && lineEditIndex === index ? (
                          <>
                            <div onClick={() => handleSaveLine(index)}>
                              <SaveIcon />
                            </div>
                            <div onClick={() => handleCancelLine()}>
                              <CancelIcon />
                            </div>
                          </>
                        ) : (
                          <>
                            {(isNewCard || cardIsEdit) && (
                              <>
                                <div onClick={() => handleEditLine(index)}>
                                  <EditIcon />
                                </div>
                                <div onClick={() => handleDelete(data)}>
                                  <DeleteIcon />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </IconsContainer>
                    </DiagnosisDataContainer>
                  );
                })}
              {addOthers ? (
                <>
                  <ProcedureAddOtherDataContainer>
                    <AddOtherProcedureInputContainer>
                      <ChartCardInputContainer display={'flex'} width={'95%'}>
                        <InfoLabel width={'88px'} padding={'5px 10px 0px 0px'}>
                          RVScode:
                        </InfoLabel>
                        <InfoInput width={'30%'} value={otherRVSCode} onChange={(e) => setOtherRVSCode(e.target.value)} />
                      </ChartCardInputContainer>
                      <ChartCardInputContainer display={'flex'} width={'95%'}>
                        <InfoLabel width={'100px'} padding={'5px 10px 0px 0px'}>
                          Diagnosis:
                        </InfoLabel>
                        <InfoInputTextArea width={'80%'} rows={'3'} value={otherProcedure} onChange={(e) => setOtherProcedure(e.target.value)} />
                      </ChartCardInputContainer>
                    </AddOtherProcedureInputContainer>
                    <EyesButtonContainer>
                      <div onClick={() => handleAddDiagnosis({ RVScode: otherRVSCode, Description: otherProcedure }, 'Left Eye')}>
                        <ButtonSuccess>Left</ButtonSuccess>
                      </div>
                      <div onClick={() => handleAddDiagnosis({ RVScode: otherRVSCode, Description: otherProcedure }, 'Right Eye')}>
                        <ButtonSuccess>Right</ButtonSuccess>
                      </div>
                      <div onClick={() => handleAddDiagnosis({ RVScode: otherRVSCode, Description: otherProcedure }, 'Both Eyes')}>
                        <ButtonSuccess>Both</ButtonSuccess>
                      </div>
                      <div onClick={() => setAddOthers(false)}>
                        <ButtonWarning>Cancel</ButtonWarning>
                      </div>
                    </EyesButtonContainer>
                  </ProcedureAddOtherDataContainer>
                </>
              ) : (
                <>
                  <SearchInputField value={searchTextValue} onChange={(e) => setSearchTextValue(e.target.value)} placeholder={'Type to search procedure'} />
                  <SearchResultContainer>
                    {searchResult &&
                      searchResult.map((result, index) => {
                        return (
                          <SearchResult key={index}>
                            {result.Description}
                            <SearchResultButtonContainer>
                              <div onClick={() => handleAddDiagnosis(result, 'Left Eye')}>
                                <ButtonSuccess>Left</ButtonSuccess>
                              </div>
                              <div onClick={() => handleAddDiagnosis(result, 'Right Eye')}>
                                <ButtonSuccess>Right</ButtonSuccess>
                              </div>
                              <div onClick={() => handleAddDiagnosis(result, 'Both Eyes')}>
                                <ButtonSuccess>Both</ButtonSuccess>
                              </div>
                            </SearchResultButtonContainer>
                          </SearchResult>
                        );
                      })}
                    <SearchResult onClick={() => setAddOthers(true)}>Manually Add Procedure</SearchResult>
                  </SearchResultContainer>
                </>
              )}
            </ProcedureAddDataContainer>
          ) : (
            <>
              {isPrint ? (
                <PrintPageContainer ref={componentRef}>
                  <PrintHeader />
                  <PrintTitle>PROCEDURE</PrintTitle>
                  <PrintPatientsInfo patientInfo={patientInfo} date={date} />
                  <PrintBody height={'510px'}>
                    {cardData &&
                      cardData.map((data, index) => {
                        return (
                          <DiagnosisInfoContainer key={index}>
                            <InfoData border={'none'}>
                              {`[ ${data.RVScode} ] ${data.Description} - `} <b>{`${data.eye}`}</b>
                            </InfoData>
                          </DiagnosisInfoContainer>
                        );
                      })}
                  </PrintBody>
                  <PhysicianDetails
                    name={`${assignedDoctor.personalInfo.namePrefix ? assignedDoctor.personalInfo.namePrefix : ''} ${assignedDoctor.personalInfo.firstName} ${assignedDoctor.personalInfo.middleName.charAt(0)}. ${
                      assignedDoctor.personalInfo.lastName
                    } ${assignedDoctor.personalInfo.nameSuffix ? assignedDoctor.personalInfo.nameSuffix : ''}`}
                    prc={assignedDoctor.employmentRecord.prcNumber}
                    ptr={assignedDoctor.employmentRecord.ptrNumber}
                  />
                  <PrintFooter dateAndTime={dateAndTime} />
                </PrintPageContainer>
              ) : (
                <>
                  {cardData &&
                    cardData.map((data, index) => {
                      return (
                        <DiagnosisDataContainer key={index}>
                          <DiagnosisInfoContainer>
                            <InfoData border={'none'}>
                              {`[ ${data.RVScode} ] ${data.Description} - `} <b>{`${data.eye}`}</b>
                            </InfoData>
                          </DiagnosisInfoContainer>
                        </DiagnosisDataContainer>
                      );
                    })}
                </>
              )}
            </>
          )}
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default ProcedureCard;
