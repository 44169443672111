import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserById, updateOwnProfile } from '../../../../config/redux/user';
import { RefreshIconWhite, EditIconWhite, CancelIconWhite, SaveIconWhite, IconsContainer, HamburgerIconWhite } from '../../../Reusables/Icons';
import { MainPageContainer, MainBar, TitlesContainer, PageContainer, SubHeaderTitleNav, HamburgerNavContainer, HamburgerIcon, HamburgerNav } from '../../../Reusables/BodyAndBars';
import { toast } from 'react-toastify';

import ViewProfile from '../../../Reusables/ViewProfile/ViewProfile';
import UpdatePassword from './components/UpdatePassword';

function Profile() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userData = useSelector((state) => state.user.userData && state.user.userData);
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState('userProfile');
  const [isHide, setIsHide] = useState(true);
  const profilePagesList = [
    { headerName: 'User Profile', currentPage: 'userProfile' },
    { headerName: 'Update Password', currentPage: 'updatePassword' },
  ];

  useEffect(() => {
    dispatch(getUserById({ method: 'get', url: `user/${currentUser.userId}`, token: currentUser.userToken }));
    currentUser.userFirstLogin && setIsEdit(true);
    currentUser.userFirstLogin && toast.info('Please update your profile first.');
    // eslint-disable-next-line
  }, []);

  function handleRefresh() {
    dispatch(getUserById({ method: 'get', url: `user/${currentUser.userId}`, token: currentUser.userToken }));
  }

  function handleSave() {
    setIsEdit(false);
    dispatch(
      updateOwnProfile({
        method: 'post',
        url: `user/update/${userData._id}`,
        token: currentUser.userToken,
        data: updatedUserData,
      })
    );
  }

  return (
    <MainPageContainer>
      <MainBar noColumn={true}>
        <TitlesContainer>
          {profilePagesList.map((data, index) => {
            return (
              <SubHeaderTitleNav
                key={index}
                onClick={() => {
                  setCurrentPage(data.currentPage);
                  setIsHide(true);
                  setIsEdit(false);
                }}
                active={currentPage === data.currentPage}
              >
                {data.headerName}
              </SubHeaderTitleNav>
            );
          })}
          <HamburgerIcon
            onClick={() => {
              setIsHide(!isHide);
            }}
          >
            <HamburgerIconWhite />
          </HamburgerIcon>

          <HamburgerNavContainer maxHeight={isHide ? '0px' : '300px'}>
            {profilePagesList.map((data, index) => {
              return (
                <HamburgerNav
                  key={index}
                  onClick={() => {
                    setCurrentPage(data.currentPage);
                    setIsHide(true);
                    setIsEdit(false);
                  }}
                  active={currentPage === data.currentPage}
                >
                  {data.headerName}
                </HamburgerNav>
              );
            })}
          </HamburgerNavContainer>
        </TitlesContainer>
        {!isEdit && (
          <IconsContainer>
            <div title="refresh" onClick={() => handleRefresh()}>
              <RefreshIconWhite />
            </div>
            <div title="edit" onClick={() => setIsEdit(true)}>
              <EditIconWhite />
            </div>
          </IconsContainer>
        )}
        {isEdit && (
          <IconsContainer>
            <div title="cancel" onClick={() => setIsEdit(false)}>
              <CancelIconWhite />
            </div>
            <div title="save" onClick={() => handleSave()}>
              <SaveIconWhite />
            </div>
          </IconsContainer>
        )}
      </MainBar>

      <PageContainer>
        {currentPage === 'userProfile' && <ViewProfile userData={userData} isEdit={isEdit} setUpdatedUserData={setUpdatedUserData} type={'profile'} HeaderTitle={'User Profile'} />}
        {currentPage === 'updatePassword' && <UpdatePassword setIsEdit={setIsEdit} />}
      </PageContainer>
    </MainPageContainer>
  );
}

export default Profile;
