import React from 'react';
import { useSelector } from 'react-redux';

import { PrintHeaderContainer, PrintLogoContainer, PrintCompanyNameContainer, PrintLogo, ClinicName, ClinicAddress } from './PrintTemplate.styled';

function PrintHeader() {
  const clinicSiteList = useSelector((state) => state.dataList && state.dataList.dataList.clinicSite);
  const clinicSite = useSelector((state) => state.settings && state.settings.clinicSite);
  const clinicSiteData = clinicSiteList.filter((site, index) => site.id === clinicSite)[0];

  return (
    <PrintHeaderContainer>
      <PrintLogoContainer>
        <PrintLogo src={`${process.env.REACT_APP_FILES_URL}mainLogo.png`} />
      </PrintLogoContainer>
      <PrintCompanyNameContainer>
        <ClinicName>{clinicSiteData.name}</ClinicName>
        <ClinicAddress>{clinicSiteData.address1}</ClinicAddress>
        <ClinicAddress>{clinicSiteData.address2}</ClinicAddress>
        <ClinicAddress>{clinicSiteData.contact}</ClinicAddress>
      </PrintCompanyNameContainer>
    </PrintHeaderContainer>
  );
}

export default PrintHeader;
