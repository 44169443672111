import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';

export const uploadPhoto = createAsyncThunk('files/uploadPhoto', async (action) => {
  console.log(action);
  return await apiRequest(action);
});

const initialState = {
  fileURL: '',
  status: 'idle',
  searchStatus: 'idle',
};

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    clearPhotoURL: (state, action) => {
      state.fileURL = '';
    },
  },
  extraReducers(builder) {
    builder
      // upload patient photo
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.fileURL = action.payload.data;
          console.log(action.payload);
        } else {
          toast.error(action.payload.message);
          console.log(action.payload);
        }
      })
      .addCase(uploadPhoto.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        console.log(action.payload);
      });
  },
});

export const { clearPhotoURL } = filesSlice.actions;

export default filesSlice.reducer;
