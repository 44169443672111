import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';

import RichTextEditor from './richText/RichTextEditor';
import RichTextView from './richText/RichTextView';

import { ChartCardInputContainer, ChartCardTitle, ChartCardBar, ChartCardBody, ChartSeparator, ChartCardBodyAccordion, Owner, InfoData } from '../ViewChart.styled';
import { IconsContainer, EditIcon, MaximizeIcon, MinimizeIcon } from '../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger } from '../../../../../../Reusables/Buttons';

function RemarksCard({ isNewCard, setAddNewCard, cardId, setHideAddCardButton, cardIndex, cardData, owner, addedDate }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [remarks, setRemarks] = useState(cardData && cardData);
  const [isMinimized, setIsMinimized] = useState(false);
  const [content, setContent] = useState('{"blocks":[{"key":"dlpl6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}');

  useEffect(() => {
    setRemarks(cardData);
  }, [cardData]);

  const handleAddCard = () => {
    setCardIsEdit(false);
    setAddNewCard('');
    dispatch(
      addCardtoChart({
        method: 'post',
        url: `chart/addCard/${cardId}`,
        token: currentUser.userToken,
        data: { cardType: 'remarks_card', cardData: content, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` },
      })
    );
    window.localStorage.clear('content');
  };

  const handleUpdateCard = () => {
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(
      updateCardInChart({
        method: 'post',
        url: `chart/updateCard/${cardId}?index=${cardIndex}`,
        token: currentUser.userToken,
        data: { cardType: 'remarks_card', cardData: content },
      })
    );
    window.localStorage.clear('content');
  };

  const handleCancel = () => {
    setRemarks(cardData);
    setCardIsEdit(false);
    setAddNewCard('');
    setHideAddCardButton(false);
  };

  const handleEdit = () => {
    setRemarks(cardData);
    setCardIsEdit(true);
    setHideAddCardButton(true);
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getText = (str) => {
    if (remarks && isJsonString(str)) {
      return remarks;
    } else {
      let parsableText = `{"blocks":[{"key":"jv6k","text":"${str}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
      return parsableText;
    }
  };

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>
          Remarks Card{' '}
          {!isNewCard && (
            <Owner>
              Added by: <b>{owner}</b> on {moment(addedDate).format('yyy-MM-DD')}
            </Owner>
          )}
        </ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['remarks_card'] && roles[userRole]['remarks_card']['update'] && (
                <div title={'edit'} onClick={handleEdit}>
                  <EditIcon />
                </div>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          <ChartCardInputContainer width={'100%'} background={'#fff'}>
            {cardIsEdit ? (
              <ChartCardInputContainer width={'100%'}>
                <RichTextEditor storedState={JSON.parse(getText(remarks))} setData={(data) => setContent(data)} />
              </ChartCardInputContainer>
            ) : (
              <>
                {isNewCard ? (
                  <RichTextEditor storedState={{ blocks: [{ key: 'dlpl6', text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }], entityMap: {} }} setData={(data) => setContent(data)} />
                ) : (
                  <>{remarks && isJsonString(remarks) ? <RichTextView storedState={remarks && JSON.parse(remarks)} readOnly={true} /> : <InfoData>{remarks}</InfoData>}</>
                )}
              </>
            )}
          </ChartCardInputContainer>
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
        </ChartSeparator>
      )}
    </>
  );
}

export default RemarksCard;
