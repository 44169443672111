import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url(${(props) => props.backgroundImage && props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Modal = styled.div`
  padding-bottom: 50px;
  background-color: ${({ theme }) => theme.TopBar};
  width: 400px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 2px 5px;

  @media (max-width: 768px) {
    width: 300px;
    padding-bottom: 20px;
  }

  @media (max-width: 576px) {
    width: 90%;
    padding-bottom: 20px;
  }
`;

export const LogoContainer = styled.div`
  background-color: ${(props) => props.backgroundColor} !important;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: auto;
  height: 100px;

  @media (max-width: 768px) {
    height: 60px;
    margin: 5px 0px 0px 0px;
  }
`;

export const CompanyName = styled.h1`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor} !important;
  font-family: ${(props) => props.fontStyle} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: 2.2rem;
  margin: 0px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const CompanyText = styled.p`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor} !important;
  font-family: ${(props) => props.fontStyle} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: white;
  width: 350px;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 250px;
    padding: 10px;
  }

  @media (max-width: 576px) {
    width: 300px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
`;

export const FormLabel = styled.label`
  font-size: 0.8rem;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    margin-top: 5px;
    margin-bottom: 2px;
  }
`;

export const Input = styled.input`
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.CardBackground};
  border-color: transparent;
  color: ${({ theme }) => theme.CardFont};
  padding: 5px 10px;
  height: 40px;

  @media (max-width: 768px) {
    padding: 2px 10px;
    height: 30px;
  }
`;

export const LoginBtn = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: ${({ theme }) => theme.MainNav};
  border-color: transparent;
  border-radius: 20px;
  font-size: 1rem;
  padding: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const Loading = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Select = styled.select`
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.CardBackground};
  border-color: transparent;
  color: ${({ theme }) => theme.CardFont};
  padding: 5px 10px;
  height: 40px;

  @media (max-width: 768px) {
    padding: 2px 10px;
    height: 30px;
  }
`;

export const InnerLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

export const UnableToConnect = styled.div`
  color: white;
  border: 1px solid red;
  background-color: red;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`;
