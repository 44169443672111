import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';
import moment from 'moment';

export const saveSettings = createAsyncThunk('settings/saveSettings', async (action) => {
  return await apiRequest(action);
});

export const getSettings = createAsyncThunk('settings/getSettings', async (action) => {
  return await apiRequest(action);
});

export const updateCompanyImages = createAsyncThunk('settings/updateCompanyImages', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  rolesSettings: {},
  companySettings: {},
  companyName: localStorage.getItem('clearVisionCompanyName') ? JSON.parse(localStorage.getItem('clearVisionCompanyName')) : { name: 'Clear Vision', color: '#ffffff' },
  companyText: localStorage.getItem('clearVisionCompanyText') ? JSON.parse(localStorage.getItem('clearVisionCompanyText')) : { text: 'Eye Care Clinic', color: '#ffffff' },
  backgroundColor: localStorage.getItem('clearVisionBackgroundColor') ? JSON.parse(localStorage.getItem('clearVisionBackgroundColor')) : '#ffffff',
  companyImages: localStorage.getItem('clearVisionCompanyImages')
    ? JSON.parse(localStorage.getItem('clearVisionCompanyImages'))
    : { faviconURL: 'https://dreapiserver.herokuapp.com/images/favicon.ico', logoURL: 'https://dreapiserver.herokuapp.com/images/logo.png', backgroundURL: 'https://dreapiserver.herokuapp.com/images/background.jpg' },
  activeTheme: localStorage.getItem('clearVisionCompanyTheme')
    ? JSON.parse(localStorage.getItem('clearVisionCompanyTheme'))
    : {
        TopBar: '#172b4d',
        MainNav: '#2f5596',
        MainNavHover: '#172b4d',
        MainBackground: '#ffffff',
        CardHeader: '#7da4f0',
        CardBackground: '#d9e2f3',
        CardBorder: '#bbcef2',
        CardFont: '#132440',
        CardFontLight: '#ffffff',
      },
  themeSettings: {},
  curentPage: 'Charts',
  userPage: 'userListPage',
  patientPage: 'PatientList',
  chartPage: 'ChartList',
  patientId: '',
  userId: '',
  status: 'idle',
  refreshRate: 10000,
  clinicSite: localStorage.getItem('clearVisionClinicSite') ? localStorage.getItem('clearVisionClinicSite') : '',
  disableDate: localStorage.getItem('clearVisionFilterSettingsDisableDate') ? Boolean(localStorage.getItem('clearVisionFilterSettingsDisableDate')) : true,
  dateFrom: localStorage.getItem('clearVisionFilterSettingsDateFrom')
    ? Boolean(localStorage.getItem('clearVisionFilterSettingsDisableDate'))
      ? moment().startOf('day')
      : localStorage.getItem('clearVisionFilterSettingsDateFrom')
    : moment().startOf('day'),
  dateTo: localStorage.getItem('clearVisionFilterSettingsDateTo') ? (Boolean(localStorage.getItem('clearVisionFilterSettingsDisableDate')) ? moment().endOf('day') : localStorage.getItem('clearVisionFilterSettingsDateTo')) : moment().endOf('day'),
  statusList: localStorage.getItem('clearVisionFilterSettingsStatusList') ? localStorage.getItem('clearVisionFilterSettingsStatusList').split(',') : ['Registered', 'Optometrist', 'Doctor', 'Laboratory', 'Payment', 'Completed'],
  doctorFilter: localStorage.getItem('clearVisionFilterSettingsDoctorFilter') ? localStorage.getItem('clearVisionFilterSettingsDoctorFilter') : 'all',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setClinicSite: (state, action) => {
      localStorage.setItem('clearVisionClinicSite', action.payload);
      state.clinicSite = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.curentPage = action.payload;
    },
    setUserPage: (state, action) => {
      state.userPage = action.payload;
    },
    setPatientPage: (state, action) => {
      state.patientPage = action.payload;
    },
    setChartPage: (state, action) => {
      state.chartPage = action.payload;
    },
    setPatientId: (state, action) => {
      state.patientId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setDisableDate: (state, action) => {
      state.disableDate = action.payload;
      localStorage.setItem('clearVisionFilterSettingsDisableDate', action.payload);
    },
    setDateFrom: (state, action) => {
      state.dateFrom = action.payload;
      localStorage.setItem('clearVisionFilterSettingsDateFrom', action.payload);
    },
    setDateTo: (state, action) => {
      state.dateTo = action.payload;
      localStorage.setItem('clearVisionFilterSettingsDateTo', action.payload);
    },
    setStatusList: (state, action) => {
      state.statusList = action.payload;
      localStorage.setItem('clearVisionFilterSettingsStatusList', action.payload);
    },
    setDoctorFilter: (state, action) => {
      state.doctorFilter = action.payload;
      localStorage.setItem('clearVisionFilterSettingsDoctorFilter', action.payload);
    },
  },
  extraReducers(builder) {
    builder
      // get settings
      .addCase(getSettings.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.rolesSettings = action.payload.data.rolesSettings;
          state.companySettings = action.payload.data.companySettings;
          state.themeSettings = action.payload.data.themeSettings;
          state.refreshRate = action.payload.data.refreshRate;
          state.companyName = action.payload.data.companySettings.companyName;
          state.companyText = action.payload.data.companySettings.companyText;
          state.backgroundColor = action.payload.data.companySettings.backgroundColor;
          state.companyImages = { faviconURL: action.payload.data.companySettings.faviconURL, logoURL: action.payload.data.companySettings.logoURL, backgroundURL: action.payload.data.companySettings.backgroundURL };
          state.activeTheme = action.payload.data.themeSettings.themeList.filter((theme) => theme.name === action.payload.data.themeSettings.activeTheme)[0].themeColors;
          localStorage.setItem('clearVisionBackgroundColor', JSON.stringify(action.payload.data.companySettings.backgroundColor));
          localStorage.setItem('clearVisionCompanyName', JSON.stringify(action.payload.data.companySettings.companyName));
          localStorage.setItem('clearVisionCompanyText', JSON.stringify(action.payload.data.companySettings.companyText));
          localStorage.setItem('clearVisionCompanyTheme', JSON.stringify(action.payload.data.themeSettings.themeList.filter((theme) => theme.name === action.payload.data.themeSettings.activeTheme)[0].themeColors));
          localStorage.setItem(
            'clearVisionCompanyImages',
            JSON.stringify({ faviconURL: action.payload.data.companySettings.faviconURL, logoURL: action.payload.data.companySettings.logoURL, backgroundURL: action.payload.data.companySettings.backgroundURL })
          );
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(getSettings.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })
      // update settings
      .addCase(saveSettings.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(saveSettings.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.rolesSettings = action.payload.data.rolesSettings;
          state.companySettings = action.payload.data.companySettings;
          state.themeSettings = action.payload.data.themeSettings;
          state.refreshRate = action.payload.data.refreshRate;
          state.companyName = action.payload.data.companySettings.companyName;
          state.companyText = action.payload.data.companySettings.companyText;
          state.companyImages = { faviconURL: action.payload.data.companySettings.faviconURL, logoURL: action.payload.data.companySettings.logoURL, backgroundURL: action.payload.data.companySettings.backgroundURL };
          state.activeTheme = action.payload.data.themeSettings.themeList.filter((theme) => theme.name === action.payload.data.themeSettings.activeTheme)[0].themeColors;
          localStorage.setItem('clearVisionCompanyName', JSON.stringify(action.payload.data.companySettings.companyName));
          localStorage.setItem('clearVisionCompanyText', JSON.stringify(action.payload.data.companySettings.companyText));
          localStorage.setItem('clearVisionCompanyTheme', JSON.stringify(action.payload.data.themeSettings.themeList.filter((theme) => theme.name === action.payload.data.themeSettings.activeTheme)[0].themeColors));
          localStorage.setItem(
            'clearVisionCompanyImages',
            JSON.stringify({ faviconURL: action.payload.data.companySettings.faviconURL, logoURL: action.payload.data.companySettings.logoURL, backgroundURL: action.payload.data.companySettings.backgroundURL })
          );
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(saveSettings.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })
      // update company images
      .addCase(updateCompanyImages.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateCompanyImages.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updateCompanyImages.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      });
  },
});

export const { setTheme, setCurrentPage, setUserPage, setPatientPage, setChartPage, setPatientId, setUserId, setDisableDate, setDateFrom, setDateTo, setStatusList, setDoctorFilter, setClinicSite } = settingsSlice.actions;

export default settingsSlice.reducer;
