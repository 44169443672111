import React from 'react';
import { CancelIcon, IconsContainer, EditIcon, DeleteIcon, CheckIcon } from '../../../../Reusables/Icons';

function BarIconsContainer({ index, editIndex, setEditIndex, handleCancel, setAddOrUpdate, handleDelete, hide }) {
  return (
    <IconsContainer marginLeft={'10px'} marginTop={'5px'}>
      {editIndex !== index && !hide && (
        <div title="delete" onClick={() => handleDelete(index)}>
          <DeleteIcon />
        </div>
      )}
      {editIndex === index && (
        <div title="cancel" onClick={() => handleCancel()}>
          <CancelIcon />
        </div>
      )}
      {editIndex !== index && (
        <div title="edit" onClick={() => setEditIndex(index)}>
          <EditIcon />
        </div>
      )}
      {editIndex === index && (
        <div title="add or update" onClick={() => setAddOrUpdate()}>
          <CheckIcon />
        </div>
      )}
    </IconsContainer>
  );
}

export default BarIconsContainer;
