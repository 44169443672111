import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIolList, insertManyIols, updateIol, deleteIol } from '../../../../../config/redux/inventory';

import { CardContainer } from '../../../../Reusables/BodyAndBars';

import TableFunctions from './components/TableFunctions';

function IntraOcularLenses() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const iolList = useSelector((state) => state.inventory && state.inventory.iolList);
  const reload = useSelector((state) => state.inventory && state.inventory.reload);
  const clinicSite = useSelector((state) => state.settings && state.settings.clinicSite);

  const iolFilteredList = iolList.filter((iol) => iol.clinicSiteId === clinicSite);

  const initialState = { brandName: '', power: '', cost: '', price: '', minQty: '', onHand: '', clinicSiteId: clinicSite };
  const headerList = [
    { id: 'brandName', width: '300px', type: 'text' },
    { id: 'power', width: '120px', type: 'number' },
    { id: 'cost', width: '120px', type: 'number' },
    { id: 'price', width: '120px', type: 'number' },
    { id: 'minQty', width: '120px', type: 'number' },
    { id: 'onHand', width: '120px', type: 'number' },
  ];

  useEffect(() => {
    dispatch(getIolList({ method: 'get', url: `iol`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [reload]);

  function getData() {
    dispatch(getIolList({ method: 'get', url: `iol`, token: currentUser.userToken }));
  }

  function upload(data) {
    dispatch(insertManyIols({ method: 'post', url: `iol/insert`, token: currentUser.userToken, data }));
  }

  function handleUpdate(id, data) {
    dispatch(updateIol({ method: 'post', url: `iol/${id}`, token: currentUser.userToken, data }));
  }

  function handleDelete(id) {
    dispatch(deleteIol({ method: 'delete', url: `iol/${id}`, token: currentUser.userToken }));
  }

  return (
    <CardContainer width={'100%'}>
      <TableFunctions initialState={initialState} headerName={'Intraocular Lenses'} headerList={headerList} tableWidth="1100px" itemList={iolFilteredList} getData={getData} upload={upload} handleDelete={handleDelete} handleUpdate={handleUpdate} />
    </CardContainer>
  );
}

export default IntraOcularLenses;
