import React, { useState, useEffect } from 'react';
import { parse } from 'papaparse';

import Table from './Table';

function TableFunctions({ upload, initialState, headerList, itemList, headerName, tableWidth, handleDelete, handleUpdate }) {
  const [isUpload, setIsUpload] = useState(false);
  const [editData, setEditData] = useState({});
  const [isAddList, setIsAddList] = useState(false);
  const [addNewList, setAddNewList] = useState([{}]);
  const [updatedItemList, setUpdatedItemList] = useState([]);
  const [csvFile, setCsvFile] = useState(null);

  const convertCsv = async (file) => {
    let final = [];
    let text = await file.text();
    let result = parse(text, { header: true }).data;
    result.pop();
    result.forEach((item) => {
      final.push({ ...item, expDate: new Date(item.expDate) });
    });

    file && setUpdatedItemList([...result]);
    file && setIsUpload(true);
  };

  useEffect(() => {
    csvFile && convertCsv(csvFile);
  }, [csvFile]);

  useEffect(() => {
    addNewList.length === 0 && setIsAddList(false);
  }, [addNewList]);

  useEffect(() => {
    setUpdatedItemList([]);
    setEditData({});
    setIsAddList(false);
    setAddNewList([{}]);
    setUpdatedItemList([]);
    setIsUpload(false);
    setCsvFile(null);
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setEditData({});
    setIsAddList(false);
    setAddNewList([{}]);
    setUpdatedItemList([]);
    setIsUpload(false);
    setCsvFile(null);
  };

  const handleOnChange = (name, value) => {
    if (name === 'cost') {
      setEditData({ ...editData, cost: value, price: (value * 1.3).toFixed(2) });
    } else {
      setEditData({ ...editData, [name]: value });
    }
  };

  const handleOnChangeAddNewList = (index, name, value) => {
    if (name === 'cost') {
      setAddNewList(addNewList.map((item, i) => (i === index ? { ...item, cost: value, price: (value * 1.3).toFixed(2) } : item)));
    } else {
      setAddNewList(addNewList.map((item, i) => (i === index ? { ...item, [name]: value } : item)));
    }
  };

  const handleDeleteNewList = (index) => {
    setAddNewList(addNewList.filter((item, i) => i !== index));
  };

  const handleSave = () => {
    isAddList && setUpdatedItemList(addNewList);
    isAddList && setIsAddList(false);
    setIsUpload(true);
  };

  function handleUpload() {
    upload(updatedItemList);
    setIsUpload(false);
    setUpdatedItemList([]);
  }

  function deleteItem(id) {
    handleDelete(id);
    setEditData({});
    setIsAddList(false);
    setAddNewList([{}]);
    setUpdatedItemList([]);
    setIsUpload(false);
    setCsvFile(null);
  }

  function updateItem() {
    handleUpdate(editData._id, editData);
    setEditData({});
    setIsAddList(false);
    setAddNewList([{}]);
    setUpdatedItemList([]);
    setIsUpload(false);
    setCsvFile(null);
  }

  return (
    <Table
      initialState={initialState}
      headerName={headerName}
      headerList={headerList}
      tableWidth={tableWidth}
      itemList={itemList}
      updatedItemList={updatedItemList}
      setCsvFile={setCsvFile}
      handleCancel={handleCancel}
      handleOnChange={handleOnChange}
      handleOnChangeAddNewList={handleOnChangeAddNewList}
      handleDeleteNewList={handleDeleteNewList}
      handleSave={handleSave}
      handleUpload={handleUpload}
      setIsUpload={setIsUpload}
      editData={editData}
      setEditData={setEditData}
      isUpload={isUpload}
      isAddList={isAddList}
      setIsAddList={setIsAddList}
      addNewList={addNewList}
      setAddNewList={setAddNewList}
      deleteItem={deleteItem}
      updateItem={updateItem}
    />
  );
}

export default TableFunctions;
