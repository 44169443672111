import React from 'react';

import { PhysicianInfoConstainer, PhysicianInfoDetails, PhysicianName, PhysicianInfo } from './PrintTemplate.styled';

function PhysicianDetails({ name, prc, ptr }) {
  return (
    <PhysicianInfoConstainer>
      <PhysicianInfoDetails>
        <PhysicianName>{name}</PhysicianName>
        {prc && <PhysicianInfo>{`License No. ${prc}`}</PhysicianInfo>}
        {ptr && <PhysicianInfo>{`PTR No. ${ptr}`}</PhysicianInfo>}
      </PhysicianInfoDetails>
    </PhysicianInfoConstainer>
  );
}

export default PhysicianDetails;
