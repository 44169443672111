import React from 'react';
import { useSelector } from 'react-redux';

import ChartList from './components/ChartList';
import ViewChart from './components/ViewChart/ViewChart';

function Charts() {
  const chartPage = useSelector((state) => state.settings && state.settings.chartPage);

  return (
    <>
      {chartPage === 'ChartList' && <ChartList />}
      {chartPage === 'ViewChart' && <ViewChart />}
    </>
  );
}

export default Charts;
