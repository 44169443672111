import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConsumableList, insertManyConsumableList, updateConsumable, deleteConsumable } from '../../../../../config/redux/inventory';

import { CardContainer } from '../../../../Reusables/BodyAndBars';

import TableFunctions from './components/TableFunctions';

function Consumables() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const consumableList = useSelector((state) => state.inventory && state.inventory.consumableList);
  const reload = useSelector((state) => state.inventory && state.inventory.reload);
  const clinicSite = useSelector((state) => state.settings && state.settings.clinicSite);

  const initialState = { itemName: '', cost: '', price: '', minQty: '', onHand: '', clinicSiteId: clinicSite };
  const headerList = [
    { id: 'itemName', width: '250px', type: 'text' },
    { id: 'cost', width: '100px', type: 'number' },
    { id: 'price', width: '100px', type: 'number' },
    { id: 'minQty', width: '100px', type: 'number' },
    { id: 'onHand', width: '100px', type: 'number' },
  ];

  useEffect(() => {
    dispatch(getConsumableList({ method: 'get', url: `consumables/${clinicSite}`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [reload]);

  function getData() {
    dispatch(getConsumableList({ method: 'get', url: `consumables/${clinicSite}`, token: currentUser.userToken }));
  }

  function upload(data) {
    dispatch(insertManyConsumableList({ method: 'post', url: `consumables/insert`, token: currentUser.userToken, data }));
  }

  function handleUpdate(id, data) {
    dispatch(updateConsumable({ method: 'post', url: `consumables/${id}`, token: currentUser.userToken, data }));
  }

  function handleDelete(id) {
    dispatch(deleteConsumable({ method: 'delete', url: `consumables/${id}`, token: currentUser.userToken }));
  }
  return (
    <CardContainer width={'100%'}>
      <TableFunctions initialState={initialState} headerName={'Consumables'} headerList={headerList} tableWidth="1100px" itemList={consumableList} getData={getData} upload={upload} handleDelete={handleDelete} handleUpdate={handleUpdate} />
    </CardContainer>
  );
}

export default Consumables;
