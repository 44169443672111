import styled from 'styled-components/macro';

export const AddProcedureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: ${(props) => props.theme.CardBackground};
`;

export const AddProcedureHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.TopBar};
  margin-bottom: 10px;
`;
export const AddProcedureHeader = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.CardBackground};
  padding: 5px;
`;

export const AddProcedureSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchInputField = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px 3px 5px 15px;
  font-size: 1rem;
  margin-right: 3px;
  width: 350px;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
`;

export const Table = styled.table`
  width: 100%;
  padding: 5px;
  box-shadow: 2px 2px 5px;
`;

export const TableHead = styled.thead`
  font-size: 0.8rem;
`;

export const TableRow = styled.tr`
  width: 100%;
`;

export const TableHeader = styled.th`
  border: 1px solid grey;
  width: ${(props) => props.width};
  padding: 5px;
`;

export const TableBody = styled.tbody`
  font-size: 0.8rem;
`;

export const TableCell = styled.td`
  border: 1px solid grey;
  width: ${(props) => props.width};
  padding: 5px;
  font-family: monospace;
  text-align: center;
`;

export const CellInput = styled.input`
  border: 1px solid grey;
  width: 100%;
  border: none;
  padding: 5px;
  font-family: monospace;
`;

export const CellSelect = styled.select`
  border: 1px solid grey;
  width: 100%;
  border: none;
  padding: 5px;
  font-family: monospace;
`;
export const CellTextArea = styled.textarea`
  border: 1px solid grey;
  width: 100%;
  border: none;
  padding: 5px;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.alignRight ? 'right' : 'space-between')};
`;

export const MedicineSearchResultHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.CardHeader};
  padding: 5px;
`;
export const MedicineHeader = styled.div`
  font-size: 0.8rem;
  width: ${(props) => props.width};
  border-right: 1px solid ${(props) => props.theme.TopBar};
  text-align: center;
`;

export const MedicineSearchResult = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  cursor: ${(props) => (props.sold ? 'default' : 'pointer')};

  &:hover {
    background-color: ${(props) => (props.sold ? '#ffffff' : '#ffefcf')};
  }
`;

export const MedicineSearchResultItem = styled.div`
  font-size: 0.7rem;
  width: ${(props) => props.width};
  border-right: 1px solid ${(props) => props.theme.TopBar};
  border-bottom: 1px solid ${(props) => props.theme.TopBar};
  text-align: ${(props) => props.align};
  padding: 5px;
  color: ${(props) => (props.shortage ? 'red' : 'black')};
  font-weight: ${(props) => (props.shortage ? 'bold' : 'normal')};
`;

export const TableFoot = styled.tfoot`
  font-size: 0.8rem;
`;

export const TableFooter = styled.td`
  border: 1px solid grey;
  width: ${(props) => props.width};
  padding: 5px;
  font-weight: bold;
  text-align: center;
`;

export const TableFooterTotal = styled.td`
  border: 1px solid grey;
  width: ${(props) => props.width};
  padding: 5px 10px;
  font-weight: bold;
  font-family: monospace;
  font-size: 14px;
  text-align: right;
`;

export const BillingCardHeader = styled.div`
  display: flex;
`;

export const BillingCardHeaderItem = styled.div`
  display: flex;
  width: ${(props) => props.width};
  font-size: 0.8rem;
  font-weight: bold;
  justify-content: center;
  border: 1px solid gray;
  padding: 5px;
`;

export const PrintBodyBillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 560px;
`;

export const PrintBodyBillingCardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BillingCardItemRow = styled.div`
  display: flex;
  padding: 5px 0px;
`;

export const BillingCardRowItem = styled.div`
  display: flex;
  width: ${(props) => props.width};
  font-size: 0.8rem;
  font-family: monospace;
  padding: 5px;
`;

export const BillingCardFooterTotalContainer = styled.div`
  display: flex;
  font-size: 0.9rem;
  font-family: monospace;
  font-weight: bold;
  padding: 5px 0px;
`;
export const BillingCardFooterTotal = styled.div`
  font-size: 0.9rem;
  font-family: monospace;
  font-weight: bold;
  padding: 5px 0px;
  text-align: center;
  border-top: 1px solid black;
  width: ${(props) => props.width};
`;

export const TableRowDeleteRowIconContainer = styled.div`
  cursor: pointer;
  padding: 7px 5px 5px 5px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: white;
  color: black;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: red;
    color: white;
  }
`;

export const BillingViewContainer = styled.div`
  background-color: white;
  padding: 10px;
`;

export const BillingCardItemTotal = styled.div`
  border-top: 2px solid black;
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  & span {
    width: 150px;
    padding: 5px;
  }
`;
