import React, { useEffect } from 'react';

import { CameraFeedContainer, CameraFeedViewer, CameraFeedStage, VideoContainer, CanvasContainer, CameraButtonsContainer, ButtonPrimary, ButtonSuccess, ButtonDanger } from '../ViewProfile.styled';
import audioClick from '../../../../images/mixkit-camera-shutter-click-1133.wav';

function TakePhoto({ setCameraModal, sendFile, image, setImage, handleUploadPhoto }) {
  let video = document.getElementById('video_container');
  const cameraClick = new Audio(audioClick);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: { width: 480, height: 480 } })
      .then((stream) => {
        // eslint-disable-next-line
        video = document.querySelector('video');
        video.srcObject = stream;
        video.play();
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, []);

  function handleTakePhoto() {
    cameraClick.play();
    const canvas = document.getElementById('canvas_container');
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, 480, 480);
    canvas.toBlob(sendFile, 'image/jpeg');
  }

  function handleSavePhoto() {
    setCameraModal(false);
    handleUploadPhoto();
  }

  function handleCancel() {
    setImage(null);
    setCameraModal(false);
  }

  return (
    <CameraFeedContainer>
      <CameraFeedViewer showDisplay={!image}>
        <VideoContainer id={'video_container'} />
      </CameraFeedViewer>
      <CameraFeedStage showDisplay={image}>
        <CanvasContainer id={'canvas_container'} width={'480'} height={'480'} />
      </CameraFeedStage>
      <CameraButtonsContainer>
        {!image && <ButtonPrimary onClick={() => handleTakePhoto()}>Take photo!</ButtonPrimary>}
        {image && <ButtonPrimary onClick={() => setImage(null)}>Try Again!</ButtonPrimary>}
        {image && <ButtonSuccess onClick={() => handleSavePhoto()}>Save Photo</ButtonSuccess>}
        <ButtonDanger onClick={() => handleCancel()}>Cancel</ButtonDanger>
      </CameraButtonsContainer>
    </CameraFeedContainer>
  );
}

export default TakePhoto;
