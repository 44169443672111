import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userLogInByToken } from './config/redux/user';
import { getClinicSiteList } from './config/redux/dataList';
import { ThemeProvider } from 'styled-components';

import LoggedOut from './modules/LoggedOut';
import LoggedIn from './modules/LoggedIn';

function App() {
  const dispatch = useDispatch();
  const activeTheme = useSelector((state) => state.settings && state.settings.activeTheme && state.settings.activeTheme);
  const [triggerRefreshCount, setTriggerRefreshCount] = useState(0);
  const clinicSiteList = useSelector((state) => state.dataList.clinicSiteList);

  useEffect(() => {
    const token = localStorage.getItem('clearVisionUserToken');
    const userId = localStorage.getItem('clearVisionUserId');
    if (token && userId) {
      dispatch(userLogInByToken({ method: 'post', url: `user/login/${userId}`, token: token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (!clinicSiteList || clinicSiteList.length === 0) &&
      setTimeout(() => {
        setTriggerRefreshCount(triggerRefreshCount + 1);
        dispatch(getClinicSiteList({ method: 'get', url: 'datalist/clinicList' }));
      }, 5000);
    // eslint-disable-next-line
  }, [triggerRefreshCount]);

  const userIsLoggedIn = useSelector((state) => state.user.isLoggedIn && state.user.isLoggedIn);
  return <ThemeProvider theme={activeTheme}>{userIsLoggedIn ? <LoggedIn /> : <LoggedOut clinicSiteList={clinicSiteList} />}</ThemeProvider>;
}

export default App;
