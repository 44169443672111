import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { getAllUsers } from '../../../../config/redux/user';
import { Row, InfoContainer, InfoLabel, InfoData, InfoInput, InfoInputSelect } from '../ViewProfile.styled';

function EmploymentRecord({ employmentRecord, setEmploymentRecord, access, setAccess, role, setRole, isActive, setIsActive, isEdit, currentUserAccess }) {
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state.dataList && state.dataList.dataList);
  const allUserData = useSelector((state) => state.user.userData && state.user.allUserData);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const [employmentRecordData, setEmploymentRecordData] = useState({});

  const employmentRecordDataList = [
    { name: 'department', label: 'Department:', type: 'select' },
    { name: 'position', label: 'Position:', type: 'select' },
    { name: 'employeeId', label: 'Employee ID:', type: 'text' },
    { name: 'reportsTo', label: 'Reports To:', type: 'select' },
    { name: 'dateStarted', label: 'Date Started:', type: 'date' },
    { name: 'dateEnded', label: 'Date Ended:', type: 'date' },
    { name: 'sssNumber', label: 'SSS Number:', type: 'text' },
    { name: 'philhealthNumber', label: 'Philhealth Number:', type: 'text' },
    { name: 'pagibigNumber', label: 'Pagibig Number:', type: 'text' },
    { name: 'tinNumber', label: 'TIN Number:', type: 'text' },
    { name: 'prcNumber', label: 'PRC License Number:', type: 'text' },
    { name: 'ptrNumber', label: 'PTR Number:', type: 'text' },
  ];

  useEffect(() => {
    employmentRecord && setEmploymentRecordData(employmentRecord);
  }, [employmentRecord]);

  const handleChange = (name, value) => {
    setEmploymentRecordData({ ...employmentRecordData, [name]: value });
  };

  useEffect(() => {
    setEmploymentRecord(employmentRecordData);
    // eslint-disable-next-line
  }, [employmentRecordData]);

  useEffect(() => {
    isEdit && !allUserData && dispatch(getAllUsers({ method: 'get', url: 'user/all', token: currentUser.userToken }));
    !isEdit && setEmploymentRecordData(employmentRecord);
    // eslint-disable-next-line
  }, [isEdit]);

  return (
    <>
      <Row>
        <InfoContainer>
          <InfoLabel>User Access:</InfoLabel>
          {isEdit && (currentUserAccess === 'admin' || currentUserAccess === 'supervisor') ? (
            <InfoInputSelect value={access} onChange={(e) => setAccess(e.target.value)}>
              {currentUserAccess === 'admin' && <option value="admin">Admin</option>}
              <option value="supervisor">Supervisor</option>
              <option value="user">User</option>
            </InfoInputSelect>
          ) : (
            <InfoData>{access}</InfoData>
          )}
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>User Role:</InfoLabel>
          {isEdit && (currentUserAccess === 'admin' || currentUserAccess === 'supervisor') ? (
            <InfoInputSelect value={role} onChange={(e) => setRole(e.target.value)}>
              {dataList &&
                dataList.userRoles.map((role, index) => {
                  return (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  );
                })}
            </InfoInputSelect>
          ) : (
            <InfoData>{role}</InfoData>
          )}
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>Is Active:</InfoLabel>
          {isEdit && currentUserAccess === 'admin' ? (
            <InfoInputSelect value={isActive} onChange={(e) => setIsActive(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </InfoInputSelect>
          ) : (
            <InfoData>{isActive ? 'True' : 'False'}</InfoData>
          )}
        </InfoContainer>
      </Row>
      <Row>
        {employmentRecordDataList.map((item, index) => {
          return (
            <InfoContainer key={index}>
              <InfoLabel>{item.label}</InfoLabel>
              {item.type === 'text' && (
                <>
                  {isEdit ? (
                    <InfoInput value={employmentRecordData && employmentRecordData[item.name] ? employmentRecordData[item.name] : ''} name={item.name} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                  ) : (
                    <InfoData>{employmentRecord && employmentRecord[item.name] ? employmentRecord[item.name] : ''}</InfoData>
                  )}
                </>
              )}
              {item.type === 'date' && (
                <>
                  {item.name === 'dateStarted' && (
                    <>
                      {isEdit ? (
                        <InfoInput
                          type="date"
                          value={moment(employmentRecordData && employmentRecordData[item.name] ? employmentRecordData[item.name] : '').format('yyyy-MM-DD')}
                          name={item.name}
                          onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                      ) : (
                        <InfoData>{moment(employmentRecord && employmentRecord[item.name] ? employmentRecord[item.name] : '').format('MMM DD, YYYY')}</InfoData>
                      )}
                    </>
                  )}
                  {item.name === 'dateEnded' && (
                    <>
                      {isActive === 'true' || isActive === true ? (
                        <InfoData>n/a</InfoData>
                      ) : (
                        <>
                          {isEdit ? (
                            <InfoInput
                              type="date"
                              value={moment(employmentRecordData && employmentRecordData[item.name] ? employmentRecordData[item.name] : '').format('yyyy-MM-DD')}
                              name={item.name}
                              onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                          ) : (
                            <InfoData>{moment(employmentRecord && employmentRecord[item.name] ? employmentRecord[item.name] : '').format('MMM DD, YYYY')}</InfoData>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {item.type === 'select' && (
                <>
                  {isEdit ? (
                    <InfoInputSelect value={employmentRecordData && employmentRecordData[item.name] ? employmentRecordData[item.name] : ''} name={item.name} onChange={(e) => handleChange(e.target.name, e.target.value)}>
                      {item.name === 'department' &&
                        dataList &&
                        dataList.userDepartment &&
                        dataList.userDepartment.map((department, index) => {
                          return (
                            <option key={index} value={department}>
                              {department}
                            </option>
                          );
                        })}
                      {item.name === 'position' &&
                        dataList &&
                        dataList.userPosition &&
                        dataList.userPosition.map((position, index) => {
                          return (
                            <option key={index} value={position}>
                              {position}
                            </option>
                          );
                        })}
                      {item.name === 'reportsTo' &&
                        dataList &&
                        allUserData.map((user, index) => {
                          return (
                            <option key={index} value={`${user.personalInfo.firstName} ${user.personalInfo.lastName}`}>
                              {user.personalInfo.firstName} {user.personalInfo.lastName}
                            </option>
                          );
                        })}
                    </InfoInputSelect>
                  ) : (
                    <InfoData>{employmentRecord ? employmentRecord[item.name] : ''}</InfoData>
                  )}
                </>
              )}
            </InfoContainer>
          );
        })}
      </Row>
    </>
  );
}

export default EmploymentRecord;
