import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSettings } from '../../../../../config/redux/settings';

import { IconsContainer, EditIcon, SaveIcon, CancelIcon } from '../../../../Reusables/Icons';
import { CardContainer, CardBody } from '../../../../Reusables/BodyAndBars';
import { CardBar, CompanySettingsContainer, CompanySettingsName, CompanySettingsSubBar, OtherSettingsItemContainer, OtherSettingsItemName, OtherSettingsItemInput, OtherSettingsItemValue } from '../Settings.styled';
import { useEffect } from 'react';

function OtherSettings() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const refreshRate = useSelector((state) => state.settings && state.settings.refreshRate);
  const [isEdit, setIsEdit] = useState(false);
  const [newRefreshRateValue, setNewRefreshRateValue] = useState(0);

  useEffect(() => {
    setNewRefreshRateValue(refreshRate / 1000);
    // eslint-disable-next-line
  }, [refreshRate]);

  const handleSave = () => {
    dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { refreshRate: newRefreshRateValue * 1000 } }));
    setIsEdit(false);
  };

  return (
    <CardContainer width={'80%'}>
      <CardBar>
        <CompanySettingsName>Other Settings</CompanySettingsName>
        <IconsContainer></IconsContainer>
      </CardBar>

      <CardBody>
        <CompanySettingsContainer>
          <CompanySettingsSubBar>
            {isEdit ? (
              <OtherSettingsItemContainer>
                <OtherSettingsItemName>Chart and Patient Refresh Rate:</OtherSettingsItemName>
                <OtherSettingsItemName>
                  <OtherSettingsItemInput type={'number'} value={newRefreshRateValue} onChange={(e) => setNewRefreshRateValue(e.target.value)} /> seconds
                </OtherSettingsItemName>
              </OtherSettingsItemContainer>
            ) : (
              <OtherSettingsItemContainer>
                <OtherSettingsItemName>Chart and Patient Refresh Rate: </OtherSettingsItemName>
                <OtherSettingsItemValue>{refreshRate / 1000} seconds</OtherSettingsItemValue>
              </OtherSettingsItemContainer>
            )}

            <IconsContainer>
              {isEdit ? (
                <>
                  <div onClick={() => handleSave()}>
                    <SaveIcon />
                  </div>
                  <div onClick={() => setIsEdit(false)}>
                    <CancelIcon />
                  </div>
                </>
              ) : (
                <div onClick={() => setIsEdit(true)}>
                  <EditIcon />
                </div>
              )}
            </IconsContainer>
          </CompanySettingsSubBar>
        </CompanySettingsContainer>
      </CardBody>
    </CardContainer>
  );
}

export default OtherSettings;
