import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateChartStatus, reassignChart, rescheduleChart } from '../../../../../../../config/redux/chart';
import { setCurrentPage, setPatientPage } from '../../../../../../../config/redux/settings';

import { IconsContainer, SaveIcon, CancelIcon, MaximizeIcon, MinimizeIcon } from '../../../../../../Reusables/Icons';
import {
  PatientsChartBar,
  PatientsChartBody,
  ChartPersonalInfo,
  PatientImage,
  PatientChartDetails,
  Row,
  InfoContainer,
  InfoLabel,
  InfoData,
  SelectInputStatus,
  SelectInputOption,
  EditInputContainer,
  SelectDateInput,
  ChartCardBodyAccordion,
  ChartCardTitle,
} from '../ViewChart.styled';

import { getAge } from '../../ChartList';

function ChartDetails({ selectedAppointmentIndex }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const chartStatus = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.chartStatus);
  const patientsChartList = useSelector((state) => state.chart && state.chart.patientsChartList);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [newStatus, setNewStatus] = useState(false);
  const [isEditDoctor, setIsEditDoctor] = useState(false);
  const [newDoctor, setNewDoctor] = useState(currentUser && currentUser.userId);
  const [isReschedule, setIsReschedule] = useState(false);
  const [newAppointmentDate, setNewAppointmentDate] = useState(moment().format('yyy-MM-DD'));
  const [isMinimized, setIsMinimized] = useState(false);

  const handleSaveNewStatus = () => {
    dispatch(updateChartStatus({ method: 'post', url: `chart/updatestatus/${patientsChartList[selectedAppointmentIndex]._id}`, token: currentUser.userToken, data: { status: newStatus } }));
    setIsEditStatus(false);
  };

  const handleReassignChart = () => {
    dispatch(reassignChart({ method: 'post', url: `chart/reassign/${patientsChartList[selectedAppointmentIndex]._id}`, token: currentUser.userToken, data: { doctorId: newDoctor } }));
    setIsEditDoctor(false);
  };

  const handleRescheduleAppointment = () => {
    dispatch(rescheduleChart({ method: 'post', url: `chart/reschedule/${patientsChartList[selectedAppointmentIndex]._id}`, token: currentUser.userToken, data: { appointmentDate: newAppointmentDate } }));
    setIsReschedule(false);
  };

  const handleViewPatient = () => {
    dispatch(setCurrentPage('Patients'));
    dispatch(setPatientPage('ViewPatient'));
  };

  return (
    <>
      <PatientsChartBar>
        <ChartCardTitle>Chart Details</ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <div title={'minimize'} onClick={() => setIsMinimized(true)}>
              <MinimizeIcon />
            </div>
          )}
        </IconsContainer>
      </PatientsChartBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <PatientsChartBody>
          <ChartPersonalInfo>
            <PatientImage src={patientsChartList[0] && patientsChartList[0].patientData[0] && patientsChartList[0].patientData[0].personalInfo.image} />
            <PatientChartDetails>
              <Row>
                <InfoContainer>
                  <InfoLabel>Status: </InfoLabel>
                  {isEditStatus ? (
                    <EditInputContainer>
                      <SelectInputStatus value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                        {chartStatus &&
                          chartStatus.map((a, index) => {
                            return (
                              <SelectInputOption key={index} value={`${a.status}`}>
                                {a.status}
                              </SelectInputOption>
                            );
                          })}
                      </SelectInputStatus>
                      <div onClick={() => handleSaveNewStatus()}>
                        <SaveIcon />
                      </div>
                      <div
                        onClick={() => {
                          setIsEditStatus(false);
                        }}
                      >
                        <CancelIcon />
                      </div>
                    </EditInputContainer>
                  ) : (
                    <InfoData pointer={true} onClick={() => setIsEditStatus(true)}>
                      {patientsChartList && patientsChartList[selectedAppointmentIndex] && patientsChartList[selectedAppointmentIndex].status}
                    </InfoData>
                  )}
                </InfoContainer>
                <InfoContainer>
                  <InfoLabel>Doctor: </InfoLabel>
                  {isEditDoctor ? (
                    <EditInputContainer>
                      <SelectInputStatus value={newDoctor} onChange={(e) => setNewDoctor(e.target.value)}>
                        {doctorList &&
                          doctorList.map((doctor, index) => {
                            return (
                              <SelectInputOption key={index} value={`${doctor._id}`}>
                                {doctor && doctor.personalInfo && doctor.personalInfo.firstName} {doctor && doctor.personalInfo && doctor.personalInfo.lastName}
                              </SelectInputOption>
                            );
                          })}
                      </SelectInputStatus>
                      <div onClick={() => handleReassignChart()}>
                        <SaveIcon />
                      </div>
                      <div
                        onClick={() => {
                          setIsEditDoctor(false);
                        }}
                      >
                        <CancelIcon />
                      </div>
                    </EditInputContainer>
                  ) : (
                    <InfoData
                      pointer={true}
                      onClick={() =>
                        (patientsChartList && patientsChartList[selectedAppointmentIndex] && patientsChartList[selectedAppointmentIndex].doctorId === currentUser.userId) || currentUser.userAccess === 'admin' || currentUser.userAccess === 'owner'
                          ? setIsEditDoctor(true)
                          : null
                      }
                    >
                      {doctorList &&
                        doctorList.map((doctor) => {
                          return doctor._id === (patientsChartList && patientsChartList[selectedAppointmentIndex] && patientsChartList[selectedAppointmentIndex].doctorId) && `${doctor.personalInfo.firstName} ${doctor.personalInfo.lastName}`;
                        })}
                    </InfoData>
                  )}
                </InfoContainer>
              </Row>
              <Row>
                <InfoContainer>
                  <InfoLabel>Preferred Name: </InfoLabel>
                  <InfoData>{patientsChartList[0] && patientsChartList[0].patientData[0] && patientsChartList[0].patientData[0].personalInfo.preferredName}</InfoData>
                </InfoContainer>
                <InfoContainer>
                  <InfoLabel>Appointment Date: </InfoLabel>
                  {isReschedule ? (
                    <EditInputContainer>
                      <SelectDateInput type="date" value={newAppointmentDate} onChange={(e) => setNewAppointmentDate(e.target.value)} min={moment().format('yyy-MM-DD')} />
                      <div onClick={() => handleRescheduleAppointment()}>
                        <SaveIcon />
                      </div>
                      <div
                        onClick={() => {
                          setIsReschedule(false);
                        }}
                      >
                        <CancelIcon />
                      </div>
                    </EditInputContainer>
                  ) : (
                    <InfoData pointer={true} onClick={() => setIsReschedule(true)}>
                      {moment(patientsChartList[0] && patientsChartList[0].appointmentDate).format('MMM DD, yyyy')}
                    </InfoData>
                  )}
                </InfoContainer>
              </Row>
              <Row>
                <InfoContainer>
                  <InfoLabel>First Name:</InfoLabel>
                  <InfoData pointer={true} onClick={() => handleViewPatient()}>
                    <b>{patientsChartList[0] && patientsChartList[0].patientData[0] && patientsChartList[0].patientData[0].personalInfo.firstName}</b>
                  </InfoData>
                </InfoContainer>
                <InfoContainer>
                  <InfoLabel>Last Name:</InfoLabel>
                  <InfoData pointer={true} onClick={() => handleViewPatient()}>
                    <b>{patientsChartList[0] && patientsChartList[0].patientData[0] && patientsChartList[0].patientData[0].personalInfo.lastName}</b>
                  </InfoData>
                </InfoContainer>
              </Row>
              <Row>
                <InfoContainer>
                  <InfoLabel>Age: </InfoLabel>
                  <InfoData>{getAge(patientsChartList[0] && patientsChartList[0].patientData[0] && patientsChartList[0].patientData[0].personalInfo.dateOfBirth)}</InfoData>
                </InfoContainer>
                <InfoContainer>
                  <InfoLabel>Sex: </InfoLabel>
                  <InfoData>{patientsChartList[0] && patientsChartList[0].patientData[0] && patientsChartList[0].patientData[0].personalInfo.sex}</InfoData>
                </InfoContainer>
              </Row>
            </PatientChartDetails>
          </ChartPersonalInfo>
        </PatientsChartBody>
      </ChartCardBodyAccordion>
    </>
  );
}

export default ChartDetails;
