import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';

export const getPatientById = createAsyncThunk('patient/getPatientById', async (action) => {
  return await apiRequest(action);
});

export const updatePatientById = createAsyncThunk('patient/updatePatientById', async (action) => {
  console.log(action);
  return await apiRequest(action);
});

export const createNewPatient = createAsyncThunk('patient/createNewPatient', async (action) => {
  return await apiRequest(action);
});

export const deletePatient = createAsyncThunk('patient/deletePatient', async (action) => {
  return await apiRequest(action);
});

export const searchPatient = createAsyncThunk('patient/searchPatient', async (action) => {
  return await apiRequest(action);
});

export const uploadPatientPhoto = createAsyncThunk('patient/uploadPatientPhoto', async (action) => {
  console.log(action);
  return await apiRequest(action);
});

const initialState = {
  allPatientsData: [],
  patientData: {},
  searchResult: [],
  patientPhotoURL: '',
  status: 'idle',
  searchStatus: 'idle',
  error: null,
  numberOfPages: 0,
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    updateSearchStatus: (state, action) => {
      state.searchStatus = action.payload;
    },
    clearPatientPhotoURL: (state, action) => {
      state.patientPhotoURL = '';
    },
  },
  extraReducers(builder) {
    builder
      // get patient data by Id
      .addCase(getPatientById.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.patientData = action.payload.data;
          state.searchStatus = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.searchStatus = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(getPatientById.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.searchStatus = 'failed';
        console.log(action.payload);
      })
      // update patient
      .addCase(updatePatientById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updatePatientById.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.patientData = action.payload.data;
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updatePatientById.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // create new patient
      .addCase(createNewPatient.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createNewPatient.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.patientData = action.payload.data;
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(createNewPatient.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // delete patient
      .addCase(deletePatient.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          toast.success(action.payload.message);
          state.status = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(deletePatient.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.status = 'failed';
        console.log(action.payload);
      })

      // search patient
      .addCase(searchPatient.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.searchResult = action.payload.data;
          state.numberOfPages = action.payload.numberOfPages;
          state.searchStatus = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.searchStatus = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(searchPatient.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.searchStatus = 'failed';
        console.log(action.payload);
      })

      // upload patient photo
      .addCase(uploadPatientPhoto.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.patientPhotoURL = action.payload.data;
          console.log(action.payload);
          state.searchStatus = 'succeeded';
        } else {
          toast.error(action.payload.message);
          state.searchStatus = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(uploadPatientPhoto.rejected, (state, action) => {
        toast.error('Unexpected error occured.');
        state.searchStatus = 'failed';
        console.log(action.payload);
      });
  },
});

export const { updateSearchStatus, clearPatientPhotoURL } = patientSlice.actions;

export default patientSlice.reducer;
