import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiRequest from '../axios';
import { toast } from 'react-toastify';
import moment from 'moment';

export const getMedicineList = createAsyncThunk('inventory/getMedicineList', async (action) => {
  return await apiRequest(action);
});

export const searchMedicine = createAsyncThunk('inventory/searchMedicine', async (action) => {
  return await apiRequest(action);
});

export const insertManyMedicines = createAsyncThunk('inventory/insertManyMedicines', async (action) => {
  return await apiRequest(action);
});

export const updateMedicine = createAsyncThunk('inventory/updateMedicine', async (action) => {
  return await apiRequest(action);
});

export const deleteMedicine = createAsyncThunk('inventory/deleteMedicine', async (action) => {
  return await apiRequest(action);
});

export const getIolList = createAsyncThunk('inventory/getIolList', async (action) => {
  return await apiRequest(action);
});

export const insertManyIols = createAsyncThunk('inventory/insertManyIols', async (action) => {
  return await apiRequest(action);
});

export const updateIol = createAsyncThunk('inventory/updateIol', async (action) => {
  return await apiRequest(action);
});

export const deleteIol = createAsyncThunk('inventory/deleteIol', async (action) => {
  return await apiRequest(action);
});

export const getConsumableList = createAsyncThunk('inventory/getConsumableList', async (action) => {
  return await apiRequest(action);
});

export const searchConsumable = createAsyncThunk('inventory/searchConsumable', async (action) => {
  return await apiRequest(action);
});

export const insertManyConsumableList = createAsyncThunk('inventory/insertManyConsumableList', async (action) => {
  return await apiRequest(action);
});

export const updateConsumable = createAsyncThunk('inventory/updateConsumable', async (action) => {
  return await apiRequest(action);
});

export const deleteConsumable = createAsyncThunk('inventory/deleteConsumable', async (action) => {
  return await apiRequest(action);
});

export const getFramesList = createAsyncThunk('inventory/getFramesList', async (action) => {
  return await apiRequest(action);
});

export const searchFrame = createAsyncThunk('inventory/searchFrame', async (action) => {
  return await apiRequest(action);
});

export const insertManyFramesList = createAsyncThunk('inventory/insertManyFramesList', async (action) => {
  return await apiRequest(action);
});

export const updateFrames = createAsyncThunk('inventory/updateFrames', async (action) => {
  return await apiRequest(action);
});

export const deleteFrames = createAsyncThunk('inventory/deleteFrames', async (action) => {
  return await apiRequest(action);
});

export const getFeesList = createAsyncThunk('inventory/getFeesList', async (action) => {
  return await apiRequest(action);
});

export const addFees = createAsyncThunk('inventory/addFees', async (action) => {
  return await apiRequest(action);
});

export const updateFee = createAsyncThunk('inventory/updateFee', async (action) => {
  return await apiRequest(action);
});

export const deleteFee = createAsyncThunk('inventory/deleteFee', async (action) => {
  return await apiRequest(action);
});

const initialState = {
  medicineList: [],
  iolList: [],
  consumableList: [],
  framesList: [],
  feesList: [],
  reload: 0,
  status: 'idle',
  error: null,
};


export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    clearInventory: (state, action) => {
      state.medicineList = [];
      state.iolList = [];
      state.consumableList = [];
      state.framesList = [];
    },
    clearMedicineList: (state, action) => {
      state.medicineList = [];
    },
    clearFrameList: (state, action) => {
      state.framesList = [];
    },
    clearConsumableList: (state, action) => {
      state.consumableList = [];
    },
  },
  extraReducers(builder) {
    builder
      // Get medicine list
      .addCase(getMedicineList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getMedicineList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.medicineList = action.payload.data;
      })
      .addCase(getMedicineList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // Search Medicine
      .addCase(searchMedicine.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.medicineList = action.payload.data;
      })
      .addCase(searchMedicine.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // Add multiple medicines
      .addCase(insertManyMedicines.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(insertManyMedicines.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(insertManyMedicines.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // update medicine
      .addCase(updateMedicine.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateMedicine.fulfilled, (state, action) => {
        state.status = 'succeeded';
        toast.success(action.payload.message);
        state.reload = moment();
      })
      .addCase(updateMedicine.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // delete medicine
      .addCase(deleteMedicine.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteMedicine.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(deleteMedicine.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      //====================================================================
      //====================================================================
      //====================================================================

      // get Intaocular Lenses List
      .addCase(getIolList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getIolList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.iolList = action.payload.data;
      })
      .addCase(getIolList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // insert many Intaocular Lenses List
      .addCase(insertManyIols.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(insertManyIols.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(insertManyIols.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // update Intaocular Lense
      .addCase(updateIol.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateIol.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updateIol.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // delete Intaocular Lense
      .addCase(deleteIol.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteIol.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(deleteIol.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      //====================================================================
      //====================================================================
      //====================================================================

      // get getConsumable List
      .addCase(getConsumableList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getConsumableList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.consumableList = action.payload.data;
      })
      .addCase(getConsumableList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // get getConsumable List
      .addCase(searchConsumable.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchConsumable.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.consumableList = action.payload.data;
      })
      .addCase(searchConsumable.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // insert insert Many Consumable List
      .addCase(insertManyConsumableList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(insertManyConsumableList.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(insertManyConsumableList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // update  Consumable
      .addCase(updateConsumable.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateConsumable.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updateConsumable.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // delete Consumable
      .addCase(deleteConsumable.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteConsumable.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(deleteConsumable.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      //====================================================================
      //====================================================================
      //====================================================================

      // get Frames List
      .addCase(getFramesList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getFramesList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.framesList = action.payload.data;
      })
      .addCase(getFramesList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // search Frame
      .addCase(searchFrame.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchFrame.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.framesList = action.payload.data;
      })
      .addCase(searchFrame.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // insert Many Frames List
      .addCase(insertManyFramesList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(insertManyFramesList.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(insertManyFramesList.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // update Frames
      .addCase(updateFrames.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateFrames.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(updateFrames.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

      // delete Frame
      .addCase(deleteFrames.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteFrames.fulfilled, (state, action) => {
        if (action.payload.status === 'Success') {
          state.status = 'succeeded';
          toast.success(action.payload.message);
          state.reload = moment();
        } else {
          toast.error(action.payload.error);
          state.status = 'failed';
          console.log(action.payload);
        }
      })
      .addCase(deleteFrames.rejected, (state, action) => {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      })

    //====================================================================
    //====================================================================
    //====================================================================

    // get Fees List
    .addCase(getFeesList.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(getFeesList.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.feesList = action.payload.data;
    })
    .addCase(getFeesList.rejected, (state, action) => {
      toast.error(action.payload.error);
      state.status = 'failed';
      console.log(action.payload);
    })

    // insert Fees
    .addCase(addFees.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(addFees.fulfilled, (state, action) => {
      if (action.payload.status === 'Success') {
        state.status = 'succeeded';
        toast.success(action.payload.message);
        state.reload = moment();
      } else {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      }
    })
    .addCase(addFees.rejected, (state, action) => {
      toast.error(action.payload.error);
      state.status = 'failed';
      console.log(action.payload);
    })

    // update Fee
    .addCase(updateFee.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(updateFee.fulfilled, (state, action) => {
      if (action.payload.status === 'Success') {
        state.status = 'succeeded';
        toast.success(action.payload.message);
        state.reload = moment();
      } else {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      }
    })
    .addCase(updateFee.rejected, (state, action) => {
      toast.error(action.payload.error);
      state.status = 'failed';
      console.log(action.payload);
    })

    // delete Fee
    .addCase(deleteFee.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(deleteFee.fulfilled, (state, action) => {
      if (action.payload.status === 'Success') {
        state.status = 'succeeded';
        toast.success(action.payload.message);
        state.reload = moment();
      } else {
        toast.error(action.payload.error);
        state.status = 'failed';
        console.log(action.payload);
      }
    })
    .addCase(deleteFee.rejected, (state, action) => {
      toast.error(action.payload.error);
      state.status = 'failed';
      console.log(action.payload);
    });

  //====================================================================
  //====================================================================
  //====================================================================
  },
});

export const { clearInventory, clearMedicineList, clearFrameList, clearConsumableList } = inventorySlice.actions;
export default inventorySlice.reducer;
