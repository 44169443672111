import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogIn } from '../config/redux/user';
import { setClinicSite, setCurrentPage, setChartPage } from '../config/redux/settings';
import { clearInventory } from '../config/redux/inventory';
import { clearDataList } from '../config/redux/dataList';
import { Container, Modal, LogoContainer, Logo, CompanyName, CompanyText, FormContainer, FormLabel, InputContainer, Input, LoginBtn, Select, InnerLoaderContainer, UnableToConnect } from './LoggedOut.styled';

import PageLoading from './Loading';
import loadingGif from '../images/loading-gif.gif';

function LoggedOut({ clinicSiteList }) {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.status && state.user.status);
  const backgroundColor = useSelector((state) => state.settings && state.settings.backgroundColor);
  const companyName = useSelector((state) => state.settings.companyName);
  const companyText = useSelector((state) => state.settings.companyText);
  const [emailAddress, setEmailAddress] = useState();
  const [password, setPassword] = useState();
  const [selectedClinicSite, setSelectedClinicSite] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(userLogIn({ method: 'post', url: 'user/login', data: { emailAddress, password } }));
    dispatch(setClinicSite(selectedClinicSite));
  }

  useEffect(() => {
    clinicSiteList && clinicSiteList[0] && setSelectedClinicSite(clinicSiteList[0].id);
  }, [clinicSiteList]);

  useEffect(() => {
    dispatch(clearInventory());
    dispatch(clearDataList());
    dispatch(setCurrentPage('Charts'));
    dispatch(setChartPage('ChartList'));
    // eslint-disable-next-line
  }, []);

  return (
    <Container backgroundImage={`${process.env.REACT_APP_FILES_URL}background.jpg`}>
      {status === 'loading' ? <PageLoading /> : ''}
      <Modal>
        <LogoContainer backgroundColor={backgroundColor}>
          <Logo src={`${process.env.REACT_APP_FILES_URL}mainLogo.png`} alt="logo" />

          {companyName.name && companyName.showOnLogin && (
            <CompanyName fontUrl={companyName.url} fontStyle={companyName.font} fontColor={companyName.color} fontWeight={companyName.fontWeight}>
              {companyName.name}
            </CompanyName>
          )}
          {companyText.text && companyText.showOnLogin && (
            <CompanyText fontUrl={companyText.url} fontStyle={companyText.font} fontColor={companyText.color} fontWeight={companyText.fontWeight}>
              {companyText.text}
            </CompanyText>
          )}
        </LogoContainer>

        {!clinicSiteList || clinicSiteList.length === 0 ? (
          <>
            {clinicSiteList && clinicSiteList.length === 0 ? (
              <FormContainer>
                <InnerLoaderContainer>
                  <img src={loadingGif} height="80" width="80" alt={'loading gif'} />
                </InnerLoaderContainer>
              </FormContainer>
            ) : (
              <FormContainer>
                <UnableToConnect>
                  Unable to connect to the backend server!
                  <br /> Please try again later
                </UnableToConnect>
              </FormContainer>
            )}
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <InputContainer>
                <FormLabel htmlFor="emailAddress">Email Address:</FormLabel>
                <Input onChange={(e) => setEmailAddress(e.target.value)} name="emailAddress" id="emailAddress" type="text" placeholder="Email Address" autoComplete="off" />
              </InputContainer>
              <InputContainer>
                <FormLabel htmlFor="password">Password:</FormLabel>
                <Input onChange={(e) => setPassword(e.target.value)} name="password" id="password" type="password" placeholder="Password" />
              </InputContainer>
              {clinicSiteList && clinicSiteList.length >= 2 && (
                <InputContainer>
                  <FormLabel htmlFor="clinicSite">Select Site:</FormLabel>
                  <Select onChange={(e) => setSelectedClinicSite(e.target.value)} name="clinicSite" id="clinicSite">
                    {clinicSiteList &&
                      clinicSiteList.map((site, index) => {
                        return (
                          <option key={index} value={site.id}>
                            {site.name}
                          </option>
                        );
                      })}
                  </Select>
                </InputContainer>
              )}
              <LoginBtn>Sign In</LoginBtn>
            </FormContainer>
          </form>
        )}
      </Modal>
    </Container>
  );
}

export default LoggedOut;
