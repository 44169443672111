import styled from 'styled-components/macro';

export const ProfilePicture = styled.img`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-top: none;
  padding: 5px;
  width: 200px;
  height: 200px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const LeftContainer = styled.div`
  height: auto;
  width: 35%;
  margin-top: 5px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  height: auto;
  width: 100%;
  margin-bottom: 5px;
`;

export const CardBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px 5px 0px 0px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
`;

export const CardSubBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  color: ${({ theme }) => theme.HeaderFontColor};
  padding: 0px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
`;

export const CardSubBarName = styled.div`
  padding-top: 3px;
  padding-bottom: 2px;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const CardBarName = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const RightContainer = styled.div`
  margin-left: 5px;
  height: auto;
  width: 65%;
  margin-top: 5px;

  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
`;
export const CardBody = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const MainInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: 32%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const MainInfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 2px;
`;

export const MainInfoData = styled.div`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px;
  padding-left: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  height: 27px;
`;

export const MainInfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 2px;
  padding-left: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  width: 100%;
`;

export const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: ${(props) => (props.width ? props.width : '32.5%')};
  display: ${(props) => props.none && 'none'};

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 2px;
`;

export const InfoData = styled.div`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 3px;
  height: 24px;
  padding-left: 6px;
  font-size: 0.9rem;
  overflow: hidden;
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 3px;
  padding-left: 6px;
  font-size: 0.9rem;
  width: 100%;
`;

export const InfoInputDiv = styled.div`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 3px 0px 2px 6px;
  font-size: 0.9rem;
  width: 100%;
`;

export const InfoInputLabel = styled.label`
  cursor: pointer;
  background-color: ${({ theme }) => theme.CardBackground};
  border-radius: 3px;
  padding: 0px 10px;
  &:hover {
    background-color: ${({ theme }) => theme.CardBorder};
  }
`;

export const InfoInputFile = styled.input`
  display: none;
`;

export const InfoInputSelect = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 2px;
  font-size: 0.9rem;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  padding-right: 5px;
  padding-left: 5px;
`;

export const IconActions = styled.div`
  cursor: pointer;
  margin-left: 5px;
  font-size: 1rem;
  padding: 3px 3px 0px 3px;
  transition: all 0.2s ease-in-out;
  border-radius: 1px;
  &:hover {
    color: ${({ theme }) => theme.MainBackground};
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const ActionText = styled.div`
  display: inline;
  font-size: 0.8rem;
`;

export const CardBarIcons = styled.div`
  cursor: pointer;
  margin-left: 5px;
  font-size: 1rem;
  padding: 3px 3px 0px 3px;
  transition: all 0.2s ease-in-out;
  border-radius: 1px;
  &:hover {
    color: ${({ theme }) => theme.MainBackground};
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const CardHeader = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  padding: 5px 20px;
  font-size: 1rem;
  color: ${({ theme }) => theme.HeaderFontColor};
  display: flex;
  justify-content: space-between;
`;

export const StartCamera = styled.div`
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    text-decoration: none;
  }
`;

export const ButtonPrimary = styled.div`
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 5px;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    text-decoration: none;
  }
`;

export const ButtonSuccess = styled.div`
  cursor: pointer;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 5px;

  &:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    text-decoration: none;
  }
`;

export const ButtonDanger = styled.div`
  cursor: pointer;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 5px;

  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    text-decoration: none;
  }
`;

export const CameraModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CameraModal = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px gray;
`;

export const CameraFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CameraFeedViewer = styled.div`
  display: ${(props) => (props.showDisplay ? 'flex' : 'none')};
  border: 1px solid black;
  position: relative;
`;

export const CameraFeedStage = styled.div`
  display: ${(props) => (props.showDisplay ? 'flex' : 'none')};
  border: 1px solid black;
  background-color: ${({ theme }) => theme.CardHeader};
`;

export const CameraButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VideoContainer = styled.video`
  width: 480px;
  height: 480px;

  @media (max-width: 400px) {
    width: 340px;
    height: 340px;
  }
`;

export const CanvasContainer = styled.canvas`
  width: 480px;
  height: 480px;

  @media (max-width: 400px) {
    width: 340px;
    height: 340px;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;
