import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveSettings, getSettings } from '../../../../config/redux/settings';

import { IconsContainer, HamburgerIconWhite } from '../../../Reusables/Icons';
import { ButtonDangerSlim, ButtonPrimarySlim } from '../../../Reusables/Buttons';
import { MainPageContainer, PageContainer, MainBar, TitlesContainer, SubHeaderTitleNav, HamburgerNavContainer, HamburgerIcon, HamburgerNav } from '../../../Reusables/BodyAndBars';

import CompanySettings from './components/CompanySettings';
import RolesSettings from './components/RolesSettings';
import ThemeSettings from './components/ThemeSettings';
import OtherSettings from './components/OtherSettings';

function Settings() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const [currentPage, setCurrentPage] = useState('companySettings');
  const [isHide, setIsHide] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [rolesSettings, setRolesSettings] = useState({});

  const dataListList = [
    { headerName: 'Company Settings', currentPage: 'companySettings' },
    { headerName: 'Roles Settings', currentPage: 'rolesSettings' },
    { headerName: 'Theme Settings', currentPage: 'themeSettings' },
    { headerName: 'Other Settings', currentPage: 'otherSettings' },
  ];

  useEffect(() => {
    setRolesSettings(roles);
    // eslint-disable-next-line
  }, [roles]);

  function handleRefresh() {
    setIsEdit(false);
    dispatch(getSettings({ method: 'get', url: 'settings', token: currentUser.userToken }));
  }

  function handleSave() {
    setIsEdit(false);
    dispatch(saveSettings({ method: 'post', url: 'settings/update', token: currentUser.userToken, data: { rolesSettings } }));
  }

  function handleCancel() {
    setIsEdit(false);
    setRolesSettings(roles);
  }

  return (
    <MainPageContainer>
      <MainBar noColumn={true}>
        <TitlesContainer>
          {dataListList.map((data, index) => {
            return (
              <SubHeaderTitleNav key={index} onClick={() => setCurrentPage(data.currentPage)} active={currentPage === data.currentPage}>
                {data.headerName}
              </SubHeaderTitleNav>
            );
          })}
          <HamburgerIcon
            onClick={() => {
              setIsHide(!isHide);
            }}
          >
            <HamburgerIconWhite />
          </HamburgerIcon>

          <HamburgerNavContainer maxHeight={isHide ? '0px' : '300px'}>
            {dataListList.map((data, index) => {
              return (
                <HamburgerNav
                  key={index}
                  onClick={() => {
                    setCurrentPage(data.currentPage);
                    setIsHide(true);
                  }}
                  active={currentPage === data.currentPage}
                >
                  {data.headerName}
                </HamburgerNav>
              );
            })}
          </HamburgerNavContainer>
        </TitlesContainer>
        <IconsContainer>
          {isEdit ? (
            <>
              <ButtonDangerSlim blinking={true} onClick={() => handleSave()}>
                Save
              </ButtonDangerSlim>
              <ButtonPrimarySlim onClick={() => handleCancel()}>Cancel</ButtonPrimarySlim>
            </>
          ) : (
            <ButtonPrimarySlim onClick={() => handleRefresh()}>Refresh</ButtonPrimarySlim>
          )}
        </IconsContainer>
      </MainBar>
      <PageContainer>
        {currentPage === 'companySettings' && <CompanySettings setIsEdit={setIsEdit} />}
        {currentPage === 'rolesSettings' && <RolesSettings setIsEdit={setIsEdit} rolesSettings={rolesSettings} setRolesSettings={setRolesSettings} />}
        {currentPage === 'themeSettings' && <ThemeSettings setIsEdit={setIsEdit} />}
        {currentPage === 'otherSettings' && <OtherSettings setIsEdit={setIsEdit} />}
      </PageContainer>
    </MainPageContainer>
  );
}

export default Settings;
