import styled from 'styled-components/macro';

export const MainHeader = styled.header`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.TopBar};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  height: 50px;
  margin-bottom: 5px;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
`;

export const Container = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  padding: 5px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: relative;
`;

export const SmallLogoImage = styled.img`
  margin: 0px 10px;
  height: 38px;
  padding: 2px;

  @media (max-width: 576px) {
    height: 35px;
  }
`;

export const CompanyName = styled.p`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor} !important;
  font-family: ${(props) => props.fontStyle} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: 1.5rem;
  margin: 0px 10px 0px 0px;

  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

export const CompanyText = styled.p`
  @import url(${(props) => props.fontUrl && props.fontUrl});
  color: ${(props) => props.fontColor} !important;
  font-family: ${(props) => props.fontStyle} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: 0.9rem;
  padding-top: 4px;
  margin: 0px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const UserName = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  color: ${({ theme }) => theme.HeaderFont};
  font-weight: bold;
  border-color: transparent;
  border-radius: 0px;
  font-size: 1rem;
  padding: 7px 15px 3px 15px;
  margin: 3px;
  margin-left: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const AvatarContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin: 0px 5px;
  overflow: hidden;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const NoAvatar = styled.div`
  color: ${({ theme }) => theme.FontColor};
  padding-top: 4px;
  padding-left: 0px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

export const HeaderNav = styled.nav`
  display: block;
  width: 200px;
  overflow: hidden;
  position: absolute;
  background-color: ${({ theme }) => theme.MainNav};
  z-index: 10;
  top: 45px;
  right: -5px;
  max-height: ${(props) => props.maxHeight || '300px'};
  transition: max-height 0.5s ease-in-out;
  font-size: 1rem;
`;

export const NavButtons = styled.div`
  text-align: right;
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 10px 15px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  border-right: 5px solid ${(props) => (props.active ? 'orange' : props.theme.MainNav)};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const TopNav = styled.div`
  cursor: pointer;
  padding: 10px 10px 5px 10px;
  border-bottom: 5px solid ${(props) => (props.active ? 'orange' : props.theme.TopBar)};
  margin-right: 10px;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.CardFontLight};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: orange;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

export const TopNavMobile = styled.div`
  display: none;
  cursor: pointer;
  padding: 0px 3px 5px 8px;
  border-bottom: 5px solid ${(props) => (props.active ? 'orange' : props.theme.TopBar)};
  font-size: 0.9rem;
  color: ${({ theme }) => theme.CardFontLight};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: orange;
  }

  @media (max-width: 576px) {
    display: block;
  }
`;

export const HeaderLogoContainer = styled.div`
  background-color: ${(props) => props.backgroundColor} !important;
  padding: 2px 10px 0px 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    padding: 1px 4px 1px 4px;
  }
`;
