import styled from 'styled-components/macro';

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 1rem;
  padding: 10px 10px 0px 10px;
`;

export const ChartInfoBarHeader = styled.div`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  border-left: 5px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
  transition: all 0.2s ease-in-out;
`;

export const ChartInfoBar = styled.div`
  width: auto;
  margin: 2px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.CardBackground};
  padding: 2px 5px 2px 0px;
  border: 1px solid ${({ theme }) => theme.CardBackground};
  border-left: 5px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
    border-left: 5px solid ${(props) => (props.status ? props.status : props.theme.CardHeader)};
  }
`;

export const ChartInfoContainer = styled.div`
  display: flex;
  margin: 5px 5px;
`;

export const ChartInfoLabelHeader = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 4px 0px 4px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  font-weight: bold;
  ${(props) => !props.showDoctor && props.label === 'DOCTOR' && 'display: none'};

  @media (max-width: 1100px) {
    display: ${(props) => props.label === 'PREFFEREDNAME' && 'none'};
  }

  @media (max-width: 1000px) {
    display: ${(props) => props.label === 'TIMEIN' && 'none'};
  }

  @media (max-width: 900px) {
    display: ${(props) => props.label === 'DOCTOR' && 'none'};
  }

  @media (max-width: 750px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
  }

  @media (max-width: 630px) {
    display: ${(props) => props.label === 'SEX' && 'none'};
  }

  @media (max-width: 440px) {
    width: ${(props) => props.label === 'STATUS' && '120px'};
  }
`;

export const ChartInfoLabel = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 9px 0px 4px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  ${(props) => !props.showDoctor && props.label === 'DOCTOR' && 'display: none'};

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }

  @media (max-width: 1100px) {
    display: ${(props) => props.label === 'PREFFEREDNAME' && 'none'};
  }

  @media (max-width: 1000px) {
    display: ${(props) => (props.label === 'TIMEIN' || props.label === 'PREFFEREDNAME') && 'none'};
  }

  @media (max-width: 900px) {
    display: ${(props) => props.label === 'DOCTOR' && 'none'};
  }

  @media (max-width: 750px) {
    display: ${(props) => props.label === 'AGE' && 'none'};
  }

  @media (max-width: 630px) {
    display: ${(props) => props.label === 'SEX' && 'none'};
  }
`;

export const PatientAvatarContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  width: 40px;
  padding-top: 2px;

  @media (max-width: 576px) {
    display: ${(props) => props.label === 'IMAGE' && 'none'};
  }
`;

export const PatientAvatarHeader = styled.div`
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  width: 40px;
  font-size: 0.7rem;
  padding: 4px 0px 4px 6px;
  font-weight: bold;

  @media (max-width: 576px) {
    display: ${(props) => props.label === 'IMAGE' && 'none'};
  }
`;

export const PatientAvatar = styled.img`
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px 4px 0px 4px;
`;

export const ChartInfoLabelEditable = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  font-size: 0.7rem;
  padding: 0px 0px 0px 10px;
  width: ${(props) => props.width};
  border-left: 1px solid ${({ theme }) => theme.FontColor};
  display: flex;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }

  @media (max-width: 440px) {
    width: ${(props) => props.label === 'STATUS' && '120px'};
  }
`;

export const ChartInfoLabelEditableText = styled.div`
  cursor: pointer;
  padding-top: 9px;
  width: 100px;

  &:hover {
    color: ${(props) => props.theme.MainNav};
  }
`;

export const SelectInputStatus = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 1px 3px;
  font-size: 0.8rem;
  margin-right: 3px;
  width: 97px;
`;

export const SelectInputOption = styled.option`
  color: ${({ theme }) => theme.FontColor};
  padding: 1px 3px;
  font-size: 0.8rem;
  margin-right: 3px;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
`;

export const ViewChartButtonContainer = styled.div`
  display: block;
  padding-top: 5px;

  /* @media (max-width: 1200px) {
    display: none;
  } */
`;

export const HideOnMobile = styled.div`
  display: block;

  @media (max-width: 440px) {
    display: none;
  }
`;

export const TopIconContainer = styled.div`
  margin: 5px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.hide ? '0px' : 'auto')};
  display: flex;
  justify-content: center;
  transition: height 0.5s ease-out;
`;

export const FilterBox = styled.div`
  width: 95%;
  padding: 5px 10px;
  margin: 5px;
  background-color: ${({ theme }) => theme.CardBackground};
  border-radius: 5px;
  box-shadow: 1px 1px 5px grey;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const FilterGroup = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  padding: 5px 8px;
  width: 24.6%;

  @media (max-width: 1190px) {
    width: 49.6%;
    margin-top: 5px;
  }

  @media (max-width: 605px) {
    width: 100%;
  }
`;

export const FilterGroup2 = styled.div`
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 3px;
  padding: 5px 8px;
  margin: 0px 2px;
  width: 16%;

  @media (max-width: 1190px) {
    width: 32%;
    margin-top: 5px;
  }

  @media (max-width: 605px) {
    width: 49%;
  }

  @media (max-width: 525px) {
    width: 100%;
  }
`;

export const LabelContainer = styled.div`
  display: flex;

  @media (max-width: 1190px) {
    width: 80px;
  }

  @media (max-width: 605px) {
    width: 100px;
  }

  @media (max-width: 400px) {
    width: 80px;
  }
`;

export const FilterLabel = styled.label`
  font-size: 0.7rem;
  padding: 8px 5px 0px 5px;
`;
