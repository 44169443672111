import React, { useState, useEffect } from 'react';

import { Row, InfoContainer, InfoLabel, InfoData, InfoInput } from '../ViewProfile.styled';

function OtherInfo({ otherInfo, isEdit, setOtherInfo }) {
  const [otherInfoData, setOtherInfoData] = useState({});
  const otherInfoDataList = [
    { name: 'hmo', label: 'HMO', placeholder: '' },
    { name: 'hmoNumber', label: 'HMO Number:', placeholder: '' },
    { name: 'sssNumber', label: 'SSS Number:', placeholder: '' },
    { name: 'philhealthNumber', label: 'Philhealth Number:', placeholder: '' },
    { name: 'tinNumber', label: 'TIN Number:', placeholder: '' },
  ];

  useEffect(() => {
    otherInfo && setOtherInfoData(otherInfo);
    // eslint-disable-next-line
  }, [otherInfo]);

  useEffect(() => {
    !isEdit && setOtherInfoData(otherInfo);
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setOtherInfo(otherInfoData);
    // eslint-disable-next-line
  }, [otherInfoData]);

  const handleChange = (name, value) => {
    setOtherInfoData({ ...otherInfoData, [name]: value });
  };

  return (
    <Row>
      {otherInfoDataList.map((item, index) => {
        return (
          <InfoContainer key={index}>
            <InfoLabel>{item.label}</InfoLabel>
            {isEdit ? (
              <InfoInput type={'text'} placeholder={item.placeholder} value={otherInfoData && otherInfoData[item.name] ? otherInfoData[item.name] : ''} name={item.name} onChange={(e) => handleChange(e.target.name, e.target.value)} />
            ) : (
              <InfoData>{otherInfo && otherInfo[item.name] ? otherInfo[item.name] : ''}</InfoData>
            )}
          </InfoContainer>
        );
      })}
    </Row>
  );
}

export default OtherInfo;
