import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogOut } from '../../config/redux/user';
import { setChartPage, setCurrentPage, setPatientPage } from '../../config/redux/settings';

import { ChartsIconWhite, PatientsIconWhite } from '../Reusables/Icons';
import { MainHeader, Container, LeftSide, RightSide, SmallLogoImage, CompanyName, CompanyText, Avatar, UserName, HeaderNav, NavButtons, AvatarContainer, NoAvatar, TopNav, TopNavMobile, HeaderLogoContainer } from './Header.styled';

import { RiProfileLine, RiSettings5Line, RiStackLine, RiFileUserLine, RiLogoutCircleRLine } from 'react-icons/ri';
import { MdOutlineInventory } from 'react-icons/md';

function Header() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const backgroundColor = useSelector((state) => state.settings && state.settings.backgroundColor);
  const companyName = useSelector((state) => state.settings.companyName);
  const companyText = useSelector((state) => state.settings.companyText);
  const [userActionHidden, setUserActionHidden] = useState(true);
  const currentPage = useSelector((state) => state.settings.curentPage);

  function handleLogout(e) {
    e.preventDefault();
    dispatch(userLogOut({ method: 'post', url: `user/logout/${currentUser.userId}`, token: currentUser.userToken }));
  }

  return (
    <MainHeader>
      <Container>
        <LeftSide>
          <HeaderLogoContainer backgroundColor={backgroundColor}>
            <SmallLogoImage src={`${process.env.REACT_APP_FILES_URL}headerLogo.png`} alt="company logo" />
            {companyName.name && (
              <CompanyName fontUrl={companyName.url} fontStyle={companyName.font} fontColor={companyName.color} fontWeight={companyName.fontWeight}>
                {companyName.name}
              </CompanyName>
            )}
            {companyText.text && (
              <CompanyText fontUrl={companyText.url} fontStyle={companyText.font} fontColor={companyText.color} fontWeight={companyText.fontWeight}>
                {companyText.text}
              </CompanyText>
            )}
          </HeaderLogoContainer>
        </LeftSide>
        <RightSide>
          <TopNav
            active={currentPage === 'Charts' && 'true'}
            onClick={() => {
              dispatch(setCurrentPage('Charts'));
              dispatch(setChartPage('ChartList'));
            }}
          >
            Charts
          </TopNav>
          <TopNav
            active={currentPage === 'Patients' && 'true'}
            onClick={() => {
              dispatch(setCurrentPage('Patients'));
              dispatch(setPatientPage('PatientList'));
            }}
          >
            Patients
          </TopNav>
          <TopNavMobile
            title="charts"
            active={currentPage === 'Charts'}
            onClick={() => {
              dispatch(setCurrentPage('Charts'));
            }}
          >
            <ChartsIconWhite />
          </TopNavMobile>
          <TopNavMobile
            title="patients"
            active={currentPage === 'Patients'}
            onClick={() => {
              dispatch(setCurrentPage('Patients'));
            }}
          >
            <PatientsIconWhite />
          </TopNavMobile>
          <UserName>{currentUser.userAlias}</UserName>
          <AvatarContainer
            onMouseOver={() => {
              setUserActionHidden(false);
            }}
            onMouseLeave={() => {
              setUserActionHidden(true);
            }}
          >
            {currentUser.userAvatar ? <Avatar src={currentUser.userAvatar} alt="user avatar"/> : <NoAvatar>{currentUser.userInitials}</NoAvatar>}
          </AvatarContainer>
          <HeaderNav
            maxHeight={userActionHidden ? '0px' : '300px'}
            onMouseOver={() => {
              setUserActionHidden(false);
            }}
            onMouseLeave={() => {
              setUserActionHidden(true);
            }}
          >
            <NavButtons
              active={currentPage === 'Profile'}
              onClick={() => {
                setUserActionHidden(true);
                dispatch(setCurrentPage('Profile'));
              }}
            >
              Profile <RiProfileLine />
            </NavButtons>

            {currentUser.userAccess === 'admin' && (
              <NavButtons
                active={currentPage === 'DataList'}
                onClick={() => {
                  setUserActionHidden(true);
                  dispatch(setCurrentPage('DataList'));
                }}
              >
                Data List <RiStackLine />
              </NavButtons>
            )}
            {(currentUser.userAccess === 'admin' || currentUser.userAccess === 'supervisor') && (
              <NavButtons
                active={currentPage === 'Users'}
                onClick={() => {
                  setUserActionHidden(true);
                  dispatch(setCurrentPage('Users'));
                }}
              >
                Users <RiFileUserLine />
              </NavButtons>
            )}
            {currentUser.userAccess === 'admin' && (
              <NavButtons
                active={currentPage === 'Settings'}
                onClick={() => {
                  setUserActionHidden(true);
                  dispatch(setCurrentPage('Settings'));
                }}
              >
                Settings <RiSettings5Line />
              </NavButtons>
            )}

            {currentUser.userAccess === 'admin' && (
              <NavButtons
                active={currentPage === 'Inventory'}
                onClick={() => {
                  setUserActionHidden(true);
                  dispatch(setCurrentPage('Inventory'));
                }}
              >
                Inventory <MdOutlineInventory />
              </NavButtons>
            )}

            <NavButtons onClick={handleLogout}>
              Logout <RiLogoutCircleRLine />
            </NavButtons>
          </HeaderNav>
        </RightSide>
      </Container>
    </MainHeader>
  );
}

export default Header;
