import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { searchPatient, updateSearchStatus } from '../../../../../config/redux/patients';
import { setPatientPage, setChartPage, setCurrentPage, setPatientId } from '../../../../../config/redux/settings';

import { TitlesContainer } from '../../../../Reusables/BodyAndBars';
import { IconsContainer, CreateChartIcon, ViewPatientIcon, ViewChartIcon, GirlIcon, BoyIcon } from '../../../../Reusables/Icons';
import { ButtonPrimary, ButtonSuccess } from '../../../../Reusables/Buttons';
import {
  Row,
  MainInfoContainer,
  MainInfoLabel,
  MainInfoInput,
  PatientInfoBar,
  PatientInfoContainer,
  PatientInfoLabel,
  ButtonContainer,
  PatientAvatar,
  PatientAvatarContainer,
  PatientInfoHeader,
  HideOnMobile,
  ShowOnMobile,
  LocalMainBar,
  PatientPaginatedBar,
  PaginationTextContainer,
  PaginationText,
  PaginationLimitContainer,
  PaginationClickableText,
  PaginationSelectLimit,
} from '../Patients.styled';

import SelectDoctor from './SelectDoctor';
import PageLoading from '../../../../Loading';

function PatientList({ handleView, handleCreateNewChart }) {
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.patient && state.patient.searchResult);
  const searchStatus = useSelector((state) => state.patient && state.patient.searchStatus);
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const numberOfPages = useSelector((state) => state.patient && state.patient.numberOfPages);
  const refreshRate = useSelector((state) => state.settings && state.settings.refreshRate);
  const patientId = useSelector((state) => state.settings && state.settings.patientId);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [openSelectDoctor, setOpenSelectDoctor] = useState(false);
  const [selectDoctorIndex, setSelectDoctorIndex] = useState();
  const [editLimit, setEditLimit] = useState(false);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const [triggerRefreshCount, setTriggerRefreshCount] = useState(0);

  const getAge = (dob) => {
    let dateNow = moment();
    let dateOfBirth = moment(dob);
    let years = moment.duration(dateNow.diff(dateOfBirth)).years();
    let months = moment.duration(dateNow.diff(dateOfBirth)).months();
    let days = moment.duration(dateNow.diff(dateOfBirth)).days();
    return `${years} years, ${months} months, ${days} days`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTriggerRefreshCount(triggerRefreshCount + 1);
    }, refreshRate);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [triggerRefreshCount]);

  useEffect(() => {
    dispatch(searchPatient({ method: 'get', url: `patient/search?page=${page}&limit=${limit}&firstName=${firstName}&middleName=${middleName}&lastName=${lastName}`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [triggerRefreshCount]);

  useEffect(() => {
    dispatch(updateSearchStatus('loading'));
    window.scrollTo(0, 0);
    const delay = setTimeout(() => {
      setPage(1);
      dispatch(searchPatient({ method: 'get', url: `patient/search?page=${page}&limit=${limit}&firstName=${firstName}&middleName=${middleName}&lastName=${lastName}`, token: currentUser.userToken }));
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [firstName, middleName, lastName]);

  useEffect(() => {
    dispatch(updateSearchStatus('loading'));
    window.scrollTo(0, 0);
    dispatch(searchPatient({ method: 'get', url: `patient/search?page=${page}&limit=${limit}&firstName=${firstName}&middleName=${middleName}&lastName=${lastName}`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < numberOfPages; i++) {
      array.push(i + 1);
    }
    setPageArray(array);
    // eslint-disable-next-line
  }, [numberOfPages]);

  return (
    <>
      {searchStatus === 'loading' && <PageLoading />}
      <LocalMainBar>
        <TitlesContainer>
          <Row>
            <MainInfoContainer>
              <MainInfoLabel>First Name:</MainInfoLabel>
              <MainInfoInput value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </MainInfoContainer>
            <MainInfoContainer>
              <MainInfoLabel>Middle Name:</MainInfoLabel>
              <MainInfoInput value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
            </MainInfoContainer>
            <MainInfoContainer>
              <MainInfoLabel>Last Name:</MainInfoLabel>
              <MainInfoInput value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </MainInfoContainer>
          </Row>
        </TitlesContainer>

        <IconsContainer>
          <ButtonContainer>
            <ButtonSuccess onClick={() => dispatch(setPatientPage('CreatePatient'))}>Create Patient</ButtonSuccess>
          </ButtonContainer>
        </IconsContainer>
      </LocalMainBar>
      <PatientInfoBar>
        <PatientInfoContainer>
          <PatientInfoHeader width={'40px'} label={'IMAGE'}></PatientInfoHeader>
          <PatientInfoHeader width={'200px'}>Complete Name</PatientInfoHeader>
          <PatientInfoHeader width={'125px'} label={'PREFFEREDNAME'}>
            Preferred Name
          </PatientInfoHeader>
          <PatientInfoHeader width={'100px'} label={'BIRTHDATE'}>
            Birth Date
          </PatientInfoHeader>
          <PatientInfoHeader width={'70px'} label={'SEX'}>
            Sex
          </PatientInfoHeader>
          <PatientInfoHeader width={'200px'} label={'AGE'}>
            Age
          </PatientInfoHeader>
        </PatientInfoContainer>
      </PatientInfoBar>

      {searchResult &&
        searchResult.map((patient, index) => (
          <PatientInfoBar key={index}>
            <PatientInfoContainer>
              <PatientAvatarContainer>
                {patient.personalInfo.image ? <PatientAvatar src={patient.personalInfo.image} width={'30px'} height={'30px'} /> : <>{patient.personalInfo.sex === 'Female' ? <GirlIcon /> : <BoyIcon />}</>}
              </PatientAvatarContainer>
              <PatientInfoLabel width={'200px'} pointer={true} onClick={() => handleView(patient._id)}>
                {`${patient.personalInfo.firstName} ${patient.personalInfo.middleName} ${patient.personalInfo.lastName}`}
              </PatientInfoLabel>
              <PatientInfoLabel width={'125px'} label={'PREFFEREDNAME'}>
                {patient.personalInfo.preferredName}
              </PatientInfoLabel>
              <PatientInfoLabel width={'100px'} label={'BIRTHDATE'}>
                {moment(patient.personalInfo.dateOfBirth).format('MMM DD, YYYY')}
              </PatientInfoLabel>
              <PatientInfoLabel width={'70px'} label={'SEX'}>
                {patient.personalInfo.sex}
              </PatientInfoLabel>
              <PatientInfoLabel width={'200px'} label={'AGE'}>
                {getAge(patient.personalInfo.dateOfBirth)}
              </PatientInfoLabel>
            </PatientInfoContainer>
            <IconsContainer>
              {openSelectDoctor && selectDoctorIndex === index && <SelectDoctor setOpenSelectDoctor={setOpenSelectDoctor} patientId={patientId} handleCreateNewChart={handleCreateNewChart} />}
              <HideOnMobile>
                <ButtonPrimary
                  onClick={() => {
                    dispatch(setChartPage('ViewChart'));
                    dispatch(setCurrentPage('Charts'));
                    dispatch(setPatientId(patient._id));
                  }}
                >
                  View Chart History
                </ButtonPrimary>
                <ButtonSuccess
                  onClick={() => {
                    setOpenSelectDoctor(true);
                    dispatch(setPatientId(patient._id));
                    setSelectDoctorIndex(index);
                  }}
                >
                  Create Chart
                </ButtonSuccess>
                <ButtonPrimary onClick={() => handleView(patient._id)}>View Patient</ButtonPrimary>
              </HideOnMobile>
              <ShowOnMobile
                title="view chart history"
                onClick={() => {
                  setOpenSelectDoctor(true);
                  setPatientId(patient._id);
                  setSelectDoctorIndex(index);
                }}
              >
                <ViewChartIcon />
              </ShowOnMobile>
              <ShowOnMobile
                title="create chart"
                onClick={() => {
                  setOpenSelectDoctor(true);
                  dispatch(setPatientId(patient._id));
                  setSelectDoctorIndex(index);
                }}
              >
                <CreateChartIcon />
              </ShowOnMobile>
              <ShowOnMobile title="view patient" onClick={() => handleView(patient._id)}>
                <ViewPatientIcon />
              </ShowOnMobile>
            </IconsContainer>
          </PatientInfoBar>
        ))}
      <PatientPaginatedBar>
        <PaginationTextContainer>
          <PaginationText> Page: </PaginationText>
          {pageArray &&
            pageArray.map((value, index) => {
              return (
                <PaginationClickableText key={index} onClick={() => setPage(value)} active={value === page}>
                  {value}
                </PaginationClickableText>
              );
            })}
        </PaginationTextContainer>
        <PaginationLimitContainer>
          <PaginationText>Limit:</PaginationText>
          {editLimit ? (
            <PaginationSelectLimit
              value={limit}
              onChange={(e) => {
                setEditLimit(false);
                setLimit(e.target.value);
                setPage(1);
              }}
            >
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </PaginationSelectLimit>
          ) : (
            <PaginationClickableText active={true} onClick={() => setEditLimit(true)}>
              {limit}
            </PaginationClickableText>
          )}
        </PaginationLimitContainer>
      </PatientPaginatedBar>
    </>
  );
}

export default PatientList;
