import React from 'react';
import { useSelector } from 'react-redux';

import { CardContainer, RolesCardBar, CardBody, RolesSettingsHeader, RolesSettingsItems, CardBodyRow, CheckboxContainer, CheckboxInput, CheckboxLabel } from '../Settings.styled';

function RolesSettings({ setIsEdit, rolesSettings, setRolesSettings }) {
  const userRoles = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.userRoles);
  const cardTypes = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.cardTypes);

  const handleChange = (role, card, type, value) => {
    setIsEdit(true);
    if (rolesSettings[role]) {
      if (rolesSettings[role][card]) {
        if (rolesSettings[role][card][type]) {
          setRolesSettings({ ...rolesSettings, [role]: { ...rolesSettings[role], [card]: { ...rolesSettings[role][card], [type]: value } } });
        } else {
          setRolesSettings({ ...rolesSettings, [role]: { ...rolesSettings[role], [card]: { ...rolesSettings[role][card], [type]: value } } });
        }
      } else {
        setRolesSettings({ ...rolesSettings, [role]: { ...rolesSettings[role], [card]: { [type]: value } } });
      }
    } else {
      setRolesSettings({ ...rolesSettings, [role]: { [card]: { [type]: value } } });
    }
  };

  return (
    <CardContainer width={'90%'} scrollable={'true'}>
      <RolesCardBar>
        <RolesSettingsHeader width={'11px'} noLeftBorder={true}></RolesSettingsHeader>
        <RolesSettingsHeader>Role</RolesSettingsHeader>
        {cardTypes.map((item, index) => {
          return <RolesSettingsHeader key={index}>{item.name.slice(0, -5)}</RolesSettingsHeader>;
        })}
        <RolesSettingsHeader width={'10px'}></RolesSettingsHeader>
      </RolesCardBar>
      <CardBody>
        {userRoles &&
          userRoles.map((role, index) => {
            return (
              <CardBodyRow key={index}>
                <RolesSettingsItems textAlign={'left'}>{role}</RolesSettingsItems>
                {cardTypes.map((card, index) => {
                  return (
                    <RolesSettingsItems key={index}>
                      <CheckboxContainer>
                        <CheckboxInput
                          type="checkbox"
                          id={`${role}_${card.id}_create`}
                          checked={rolesSettings && rolesSettings[role] && rolesSettings[role][card.id] && rolesSettings[role][card.id]['create']}
                          onChange={(e) => handleChange(role, card.id, 'create', e.target.checked)}
                        />
                        <CheckboxLabel htmlFor={`${role}_${card.id}_create`}>C</CheckboxLabel>
                      </CheckboxContainer>
                      <CheckboxContainer>
                        <CheckboxInput
                          type="checkbox"
                          id={`${role}_${card.id}_read`}
                          checked={rolesSettings && rolesSettings[role] && rolesSettings[role][card.id] && rolesSettings[role][card.id]['read']}
                          onChange={(e) => handleChange(role, card.id, 'read', e.target.checked)}
                        />
                        <CheckboxLabel htmlFor={`${role}_${card.id}_read`}>R</CheckboxLabel>
                      </CheckboxContainer>
                      <CheckboxContainer>
                        <CheckboxInput
                          type="checkbox"
                          id={`${role}_${card.id}_update`}
                          checked={rolesSettings && rolesSettings[role] && rolesSettings[role][card.id] && rolesSettings[role][card.id]['update']}
                          onChange={(e) => handleChange(role, card.id, 'update', e.target.checked)}
                        />
                        <CheckboxLabel htmlFor={`${role}_${card.id}_update`}>U</CheckboxLabel>
                      </CheckboxContainer>
                    </RolesSettingsItems>
                  );
                })}
                <RolesSettingsItems width={'10px'}></RolesSettingsItems>
              </CardBodyRow>
            );
          })}
      </CardBody>
    </CardContainer>
  );
}

export default RolesSettings;
