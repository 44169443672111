import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createNewPatient } from '../../../../../config/redux/patients';
import { setPatientPage } from '../../../../../config/redux/settings';

import { PageContainer, MainBar, TitlesContainer, SubHeaderTitle } from '../../../../Reusables/BodyAndBars';
import { IconsContainer } from '../../../../Reusables/Icons';
import { ButtonSuccess, ButtonDanger } from '../../../../Reusables/Buttons';
import { BottomButtonContainer } from '../Patients.styled';

import ViewProfile from '../../../../Reusables/ViewProfile/ViewProfile';

function CreatePatient() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const [updatedPatientData, setUpdatedPatientData] = useState({});

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(createNewPatient({ method: 'post', url: `patient/add`, token: currentUser.userToken, data: updatedPatientData }));
    dispatch(setPatientPage('PatientList'));
  };

  return (
    <form onSubmit={(e) => handleSave(e)}>
      <MainBar>
        <TitlesContainer>
          <SubHeaderTitle>Create New Patient</SubHeaderTitle>
        </TitlesContainer>
        <IconsContainer>
          <ButtonDanger onClick={() => dispatch(setPatientPage('PatientList'))}>Cancel</ButtonDanger>
          <ButtonSuccess type={'submit'}>Save</ButtonSuccess>
        </IconsContainer>
      </MainBar>
      <PageContainer>
        <ViewProfile isEdit={true} setUpdatedUserData={setUpdatedPatientData} type={'patient'} />
      </PageContainer>
      <BottomButtonContainer>
        <ButtonDanger onClick={() => dispatch(setPatientPage('PatientList'))}>Cancel</ButtonDanger>
        <ButtonSuccess type={'submit'}>Save</ButtonSuccess>
      </BottomButtonContainer>
    </form>
  );
}

export default CreatePatient;
