import React, { useEffect, useState } from 'react';

import BarIconsContainer from './BarIconsContainer';
import { CancelIcon, IconsContainer, AddFolderIcon } from '../../../../Reusables/Icons';
import { CardContainer, CardBar, CardBody, CardHeader } from '../../../../Reusables/BodyAndBars';
import { DisplayBar, DisplayBarText, DisplayBarInput, DisplayBarLabel, DisplayBarInputTextArea, DisplayBarMultiText, DisplayBarMultiTextContainer, ColorContainer, ColorInput, DisplayBarSelect } from '../DataList.styled';
import { ColorDisplayOuterBox, ColorDisplayInnerBox, ColorLabel } from '../../Settings/Settings.styled';

function Template({ setIsEdit, name, headerName, dataList, setDataList }) {
  const [addData, setAddData] = useState(false);
  const [newStringData, setNewStringData] = useState('');
  const [newObjectData, setNewObjectData] = useState({});
  const [editIndex, setEditIndex] = useState(null);
  const [keys, setKeys] = useState(['']);
  const [dataListData, setDataListData] = useState([]);

  useEffect(() => {
    dataList && dataList[0] && setKeys(Object.keys(dataList[0]));
    setDataListData(dataList);
    // eslint-disable-next-line
  }, [dataList]);

  const handleCancel = () => {
    setDataListData(dataList);
    setEditIndex(null);
    setIsEdit(false);
    setAddData(false);
    setNewStringData('');
    setNewObjectData({});
  };

  const setAddOrUpdate = () => {
    let empty = false;
    setIsEdit(true);
    setEditIndex(null);
    if (typeof dataListData[0] === 'string') {
      if (addData) {
        if (newStringData === '') {
          window.alert('Please fill up all the fields.');
        } else {
          setDataListData([...dataListData, newStringData].sort());
          setDataList(name, [...dataListData, newStringData].sort());
          setNewStringData('');
          setAddData(false);
        }
      } else {
        setDataList(name, dataListData);
      }
    }

    if (typeof dataListData[0] === 'object') {
      if (addData) {
        keys &&
          keys.forEach((key) => {
            if (!newObjectData[key]) {
              empty = true;
            }
          });
        if (empty) {
          window.alert('Please fill up all the fields.');
        } else {
          setDataListData([...dataListData, newObjectData]);
          setDataList(name, [...dataListData, newObjectData]);
          setNewObjectData({});
          setAddData(false);
        }
      } else {
        setDataList(name, dataListData);
      }
    }
  };

  const handleChangeString = (index, value) => {
    setDataListData(dataList.map((item, i) => (index === i ? value : item)));
  };

  const handleChangeObject = (index, name, value) => {
    setDataListData(dataListData.map((item, i) => (index === i ? { ...item, [name]: value } : item)));
  };

  const handleDelete = (index) => {
    if (window.confirm('Are you sure you want to DELETE this entry?') === true) {
      setDataList(
        name,
        dataListData.filter((data, i) => i !== index)
      );
      setDataListData(dataListData.filter((data, i) => i !== index));
      setIsEdit(true);
    }
  };

  return (
    <CardContainer width={'70%'}>
      <CardBar>
        <CardHeader>{headerName}</CardHeader>
        <IconsContainer>
          {addData ? (
            <div title="cancel" onClick={() => setAddData(false)}>
              <CancelIcon />
            </div>
          ) : (
            <div
              title="add"
              onClick={() => {
                setAddData(true);
                setNewObjectData({ ...newObjectData, id: Math.random().toString(36).substr(2, 8) });
              }}
            >
              <AddFolderIcon />
            </div>
          )}
        </IconsContainer>
      </CardBar>
      <CardBody>
        {addData && (
          <DisplayBar>
            {dataListData && dataListData[0] && typeof dataListData[0] === 'string' && <DisplayBarInput value={newStringData} onChange={(e) => setNewStringData(e.target.value)} />}
            {dataListData && dataListData[0] && typeof dataListData[0] === 'object' && (
              <DisplayBarMultiText>
                {keys &&
                  keys.map((key, index) => {
                    return (
                      <DisplayBarMultiTextContainer key={index}>
                        <DisplayBarLabel>{key}: </DisplayBarLabel>
                        {key === 'Description' ? (
                          <DisplayBarInputTextArea rows="4" value={newObjectData[key]} onChange={(e) => setNewObjectData({ ...newObjectData, [key]: e.target.value })} />
                        ) : (
                          <>
                            {key === 'color' ? (
                              <ColorInput type="color" value={newObjectData[key]} onChange={(e) => setNewObjectData({ ...newObjectData, [key]: e.target.value })} />
                            ) : (
                              <>
                                {key === 'width' ? (
                                  <DisplayBarSelect onChange={(e) => setNewObjectData({ ...newObjectData, [key]: e.target.value })}>
                                    <option value="24%">4</option>
                                    <option value="48.5%">2</option>
                                    <option value="97.5%">1</option>
                                  </DisplayBarSelect>
                                ) : (
                                  <>
                                    {headerName === 'Clinic Site' && key === 'id' ? (
                                      <DisplayBarInput defaultValue={newObjectData[key]} disabled />
                                    ) : (
                                      <DisplayBarInput value={newObjectData[key]} onChange={(e) => setNewObjectData({ ...newObjectData, [key]: e.target.value })} />
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </DisplayBarMultiTextContainer>
                    );
                  })}
              </DisplayBarMultiText>
            )}
            <BarIconsContainer handleCancel={handleCancel} setAddOrUpdate={setAddOrUpdate} />
          </DisplayBar>
        )}
        {dataListData && dataListData[0] && typeof dataListData[0] === 'string'
          ? dataListData.map((item, index) => {
              return (
                <DisplayBar key={index}>
                  {editIndex === index ? <DisplayBarInput value={item} onChange={(e) => handleChangeString(index, e.target.value)} /> : <DisplayBarText>{item}</DisplayBarText>}
                  <BarIconsContainer index={index} setEditIndex={setEditIndex} editIndex={editIndex} handleCancel={handleCancel} setAddOrUpdate={setAddOrUpdate} handleDelete={handleDelete} />
                </DisplayBar>
              );
            })
          : dataListData.map((item, index) => {
              return (
                <DisplayBar key={index}>
                  <DisplayBarMultiText>
                    {editIndex === index ? (
                      <>
                        {keys &&
                          keys.map((key, i) => {
                            return (
                              <DisplayBarMultiTextContainer key={i}>
                                <DisplayBarLabel>{key}: </DisplayBarLabel>
                                {key === 'Description' ? (
                                  <DisplayBarInputTextArea rows="4" value={item[key]} onChange={(e) => handleChangeObject(index, key, e.target.value)} />
                                ) : (
                                  <>
                                    {key === 'color' ? (
                                      <ColorInput type="color" value={item[key]} onChange={(e) => handleChangeObject(index, key, e.target.value)} />
                                    ) : (
                                      <>
                                        {key === 'width' ? (
                                          <DisplayBarSelect value={item[key]} onChange={(e) => handleChangeObject(index, key, e.target.value)}>
                                            <option value="24%">4</option>
                                            <option value="48.5%">2</option>
                                            <option value="97.5%">1</option>
                                          </DisplayBarSelect>
                                        ) : (
                                          <>{headerName === 'Clinic Site' && key === 'id' ? <DisplayBarInput value={item[key]} disabled /> : <DisplayBarInput value={item[key]} onChange={(e) => handleChangeObject(index, key, e.target.value)} />}</>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </DisplayBarMultiTextContainer>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {keys &&
                          keys.map((key, index) => {
                            return (
                              <div key={index}>
                                {key === 'color' ? (
                                  <ColorContainer>
                                    <ColorLabel>{key}:</ColorLabel>
                                    <ColorDisplayOuterBox>
                                      <ColorDisplayInnerBox color={item[key]}></ColorDisplayInnerBox>
                                    </ColorDisplayOuterBox>
                                  </ColorContainer>
                                ) : (
                                  <>
                                    {key === 'width' ? (
                                      <>
                                        {item[key] === '24%' && <DisplayBarText>{`${key}: 4`}</DisplayBarText>}
                                        {item[key] === '48.5%' && <DisplayBarText>{`${key}: 2`}</DisplayBarText>}
                                        {item[key] === '97.5%' && <DisplayBarText>{`${key}: 1`}</DisplayBarText>}
                                      </>
                                    ) : (
                                      <DisplayBarText>{`${key}: ${item[key]}`}</DisplayBarText>
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </>
                    )}
                  </DisplayBarMultiText>
                  <BarIconsContainer index={index} setEditIndex={setEditIndex} editIndex={editIndex} handleCancel={handleCancel} setAddOrUpdate={setAddOrUpdate} handleDelete={handleDelete} hide={headerName === 'Clinic Site'} />
                </DisplayBar>
              );
            })}
      </CardBody>
    </CardContainer>
  );
}

export default Template;
