import React from 'react';
import loadingGif from '../images/loading-gif.gif';
import { Loading } from './LoggedOut.styled';

function PageLoading() {
  return (
    <Loading>
      <img src={loadingGif} height="120" width="120" alt={'loading gif'} />
    </Loading>
  );
}

export default PageLoading;
