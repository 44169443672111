import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { setCurrentPage, setPatientPage, setPatientId } from '../../../../config/redux/settings';
import { createNewChart } from '../../../../config/redux/chart';

import { MainPageContainer } from '../../../Reusables/BodyAndBars';

import PatientList from './components/PatientList';
import CreatePatient from './components/CreatePatient';
import ViewPatient from './components/ViewPatient';

function Patients() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const allPatientsData = useSelector((state) => state.patient && state.patient.allPatientsData);
  const patientPage = useSelector((state) => state.settings && state.settings.patientPage);
  const patientId = useSelector((state) => state.settings && state.settings.patientId);
  const clinicSite = useSelector((state) => state.settings && state.settings.clinicSite);
  const [refreshCount, setRefreshCount] = useState(0);

  const handleView = (id) => {
    dispatch(setPatientId(id));
    dispatch(setPatientPage('ViewPatient'));
  };

  const handleCreateNewChart = (patientId, doctorId) => {
    let newChartData = {
      createdBy: currentUser.userId,
      isLocked: false,
      status: 'Registered',
      cards: [],
      appointmentDate: moment(),
      patientId,
      doctorId,
      clinicSite,
    };

    dispatch(createNewChart({ method: 'post', url: 'chart/create', token: currentUser.userToken, data: newChartData }));
    dispatch(setCurrentPage('Charts'));
  };

  return (
    <MainPageContainer>
      {patientPage === 'PatientList' && <PatientList setRefreshCount={setRefreshCount} allPatientsData={allPatientsData} handleView={handleView} handleCreateNewChart={handleCreateNewChart} />}
      {patientPage === 'CreatePatient' && <CreatePatient setRefreshCount={setRefreshCount} refreshCount={refreshCount} />}
      {patientPage === 'ViewPatient' && <ViewPatient patientId={patientId} handleCreateNewChart={handleCreateNewChart} />}
    </MainPageContainer>
  );
}

export default Patients;
