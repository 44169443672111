import styled from 'styled-components/macro';

export const ButtonPrimary = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    text-decoration: none;
  }
`;

export const ButtonSuccess = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    text-decoration: none;
  }
`;

export const ButtonDanger = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    text-decoration: none;
  }
`;

export const ButtonWarning = styled.button`
  cursor: pointer;
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    text-decoration: none;
  }
`;

export const ButtonDangerSlim = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;
  animation: ${(props) => (props.blinking ? 'blinking 700ms infinite' : 'none')};

  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    text-decoration: none;
  }

  @keyframes blinking {
    0% {
      background-color: #dc3545;
      color: #fff;
    }
    100% {
      background-color: #fff;
      color: #dc3545;
    }
  }
`;

export const ButtonPrimarySlim = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    text-decoration: none;
  }
`;

export const ButtonSuccessSlim = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  margin: 1px 5px;

  &:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    text-decoration: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
