import React from 'react';
import styled from 'styled-components/macro';
import { RiEdit2Line, RiDeleteBin4Line, RiFolderAddLine, RiCheckFill, RiSaveLine, RiMenuLine, RiUserSearchLine } from 'react-icons/ri';
import { MdOutlineCancel, MdModeNight, MdOutlineWbSunny, MdOutlinePreview } from 'react-icons/md';
import { FiFilter, FiUserPlus, FiUsers } from 'react-icons/fi';
import { HiOutlineSearch, HiOutlineDocumentAdd, HiOutlineUpload } from 'react-icons/hi';
import { IoEyeOutline, IoDocumentsOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { TbRefresh } from 'react-icons/tb';
import { BsSkipBackwardCircle, BsPrinter } from 'react-icons/bs';
import { FaWindowMinimize, FaWindowMaximize } from 'react-icons/fa';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { CgGirl, CgBoy } from 'react-icons/cg';

export const IconsContainer = styled.div`
  display: flex;

  @media (max-width: 576px) {
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 5px 0px 5px;
  color: ${(props) => (props.isWhite ? props.theme.CardFontLight : props.theme.CardFont)};
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
    color: ${({ theme }) => theme.CardFontLight};
  }
`;

const GreenIconContainer = styled.div`
  font-size: 1.2rem;
  padding: 5px 5px 0px 5px;
  color: green;
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid white;
  background-color: green;
`;

export function EditIcon() {
  return (
    <IconContainer isWhite={false}>
      <RiEdit2Line />
    </IconContainer>
  );
}

export const EditIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiEdit2Line />
    </IconContainer>
  );
};

export const CancelIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <MdOutlineCancel />
    </IconContainer>
  );
};

export const CancelIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <MdOutlineCancel />
    </IconContainer>
  );
};

export const CheckIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <RiCheckFill />
    </IconContainer>
  );
};

export const CheckIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiCheckFill />
    </IconContainer>
  );
};

export const DeleteIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <RiDeleteBin4Line />
    </IconContainer>
  );
};

export const DeleteIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiDeleteBin4Line />
    </IconContainer>
  );
};

export const AddFolderIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <RiFolderAddLine />
    </IconContainer>
  );
};

export const AddFolderIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiFolderAddLine />
    </IconContainer>
  );
};

export const FilterIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <FiFilter />
    </IconContainer>
  );
};

export const FilterIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <FiFilter />
    </IconContainer>
  );
};

export const AddUserIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <FiUserPlus />
    </IconContainer>
  );
};

export const AddUserIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <FiUserPlus />
    </IconContainer>
  );
};

export const SearchIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <HiOutlineSearch />
    </IconContainer>
  );
};

export const SearchIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <HiOutlineSearch />
    </IconContainer>
  );
};

export const ViewIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <IoEyeOutline />
    </IconContainer>
  );
};

export const ViewWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <IoEyeOutline />
    </IconContainer>
  );
};

export const RefreshIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <TbRefresh />
    </IconContainer>
  );
};

export const RefreshIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <TbRefresh />
    </IconContainer>
  );
};

export const SaveIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <RiSaveLine />
    </IconContainer>
  );
};

export const SaveIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiSaveLine />
    </IconContainer>
  );
};

export const HamburgerIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <RiSaveLine />
    </IconContainer>
  );
};

export const HamburgerIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiMenuLine />
    </IconContainer>
  );
};

export const BackIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <BsSkipBackwardCircle />
    </IconContainer>
  );
};

export const BackIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <BsSkipBackwardCircle />
    </IconContainer>
  );
};

export const ChartsIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <IoDocumentsOutline />
    </IconContainer>
  );
};

export const ChartsIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <IoDocumentsOutline />
    </IconContainer>
  );
};

export const ChartIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <IoDocumentTextOutline />
    </IconContainer>
  );
};

export const ChartIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <IoDocumentTextOutline />
    </IconContainer>
  );
};

export const PatientsIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <FiUsers />
    </IconContainer>
  );
};

export const PatientsIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <FiUsers />
    </IconContainer>
  );
};

export const CreateChartIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <HiOutlineDocumentAdd />
    </IconContainer>
  );
};

export const CreateChartIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <HiOutlineDocumentAdd />
    </IconContainer>
  );
};

export const ViewPatientIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <RiUserSearchLine />
    </IconContainer>
  );
};

export const ViewPatientIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <RiUserSearchLine />
    </IconContainer>
  );
};

export const MinimizeIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <FaWindowMinimize />
    </IconContainer>
  );
};

export const MinimizeIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <FaWindowMinimize />
    </IconContainer>
  );
};

export const MaximizeIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <FaWindowMaximize />
    </IconContainer>
  );
};

export const MaximizeIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <FaWindowMaximize />
    </IconContainer>
  );
};

export const UploadIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <HiOutlineUpload />
    </IconContainer>
  );
};

export const UploadIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <HiOutlineUpload />
    </IconContainer>
  );
};

export const NightIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <MdModeNight />
    </IconContainer>
  );
};

export const NightIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <MdModeNight />
    </IconContainer>
  );
};

export const DayIcon = () => {
  return (
    <IconContainer isWhite={false}>
      <MdOutlineWbSunny />
    </IconContainer>
  );
};

export const DayIconWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <MdOutlineWbSunny />
    </IconContainer>
  );
};

export const CheckIconGreen = () => {
  return (
    <GreenIconContainer>
      <RiCheckFill style={{ color: 'white', fontWeight: 'bold' }} />
    </GreenIconContainer>
  );
};

export const AddOneFileWhite = () => {
  return (
    <IconContainer isWhite={true}>
      <AiOutlineFileAdd />
    </IconContainer>
  );
};

export const AddOneFile = () => {
  return (
    <IconContainer>
      <AiOutlineFileAdd />
    </IconContainer>
  );
};

export const ViewChartWhiteIcon = () => {
  return (
    <IconContainer isWhite={true}>
      <MdOutlinePreview />
    </IconContainer>
  );
};

export const ViewChartIcon = () => {
  return (
    <IconContainer>
      <MdOutlinePreview />
    </IconContainer>
  );
};

export const PrintIcon = () => {
  return (
    <IconContainer>
      <BsPrinter />
    </IconContainer>
  );
};

const AvatarIconContainer = styled.div`
  font-size: 1.6rem;
  margin: 0px 2px;
  color: ${(props) => props.color};
  padding: 2px 2px 0px 3px;
  border: 1px solid #999;
`;

export const GirlIcon = () => {
  return (
    <AvatarIconContainer color="#f94d4d">
      <CgGirl />
    </AvatarIconContainer>
  );
};

export const BoyIcon = () => {
  return (
    <AvatarIconContainer color="#2f2ffe">
      <CgBoy />
    </AvatarIconContainer>
  );
};
